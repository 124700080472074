import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Card, Typography, Stack, styled } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import {
  formsLoadingStatus,
  getAllWetlandFormsAsync,
  selectWetlandForms,
  selectWetlandFormsLoadingStatus,
  setWetlandFormActionType,
  setSelectedForms,
  selectSelectedWetlandFormIds,
  getProjectWetlandFormsAsync,
} from "../../api/services/wetlandforms/wetlandFormsSlice";
import { formActionType } from "../../utils/constants";
import { colorBlueStandard, colorGreyLight } from "../../utils/colors";
// import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { formStatusColor, formStatusToString } from "../../utils/formConstants";
import CustomToolbar from "./components/CustomToolbar";
import { getProjects } from "../../api/services/projects/projectsSlice";

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "vertical",
  enableNestedDataAccess: ".",
};

const StyledDataGrid = styled(DataGrid)((theme) => ({
  "& .MuiDataGrid-sortIcon": {
    opacity: 1,
    color: colorBlueStandard,
  },
}));

export default function FormsList({ useProjectView=false, project_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wetlandFormsStatus = useSelector(selectWetlandFormsLoadingStatus);
  const wetlandForms = useSelector(selectWetlandForms);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);

  // const getSanitizedSampleName = (item) => {
  //   const sampleName = wetlandForms.find((form) => form.id === item.id).sampleName;
  //   let sanitizedSampleName = sampleName.replace(/\//g, '_'); // Remove any characters that might cause an issue with the route (most notably forward slash)
  //   if (sanitizedSampleName === '') {
  //     sanitizedSampleName = "unnamed-wetland-data-form";
  //   }
  //   return sanitizedSampleName;
  // }
  
  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "editButton",
      type: "actions",
      headerName: "Edit Form",
      width: 100,
      hideable: false,
      renderCell: (params) => (
        // TODO: enable wrapping button in link to open in new tab. Can't pass state this way, so either temporarily set in local storage or possibly pass as a url param
        // <Link
        //   to={`/wetland/${getSanitizedSampleName(params.row)}`}
        //   state={{ actionType: formActionType.EDIT, formId: params.row.id }}
        //     // href="wetland/:wetlandSampleName"
        //     target="_blank"
        //     // rel="noopener noreferrer"
        //     onClick={(e) => {
        //       e.preventDefault();
        //       console.log(e);
        //       console.log('link onclick');
        //       handleNavigateToFormPage(params.row);
        //     }}
        //   >
          <Button variant="contained" color="warning" size="small" aria-label="view or edit this data form" onClick={() => handleNavigateToFormPage(params.row)}>
            <EditIcon />
          </Button>
      ),
    },
    {
      field: "user.name",
      headerName: "User",
      width: 160,
      valueGetter: (params) => {
        if (params.row.user) {
          if (params.row.user.first_name && params.row.user.last_name) {
            return `${params.row.user.first_name} ${params.row.user.last_name}`;
          }
        }
        return "";
      },
    },
    {
      field: "project.name",
      headerName: "Project",
      width: 160,
      valueGetter: (params) => {
        if (params.row.projectInfo) {
          if (params.row.projectInfo.name) {
            return params.row.projectInfo.name;
          }
        }
        return '';
      },
    },
    {
      field: "projectSite",
      headerName: "Project/Site Name",
      width: 160,
      hide: JSON.parse(localStorage.getItem('formsTableHideProjectSite')) ?? true,
    },
    {
      field: "sampleName",
      headerName: "Sample Name",
      width: 200,
    },
    {
      field: "dateTime",
      headerName: "Sampling Date",
      width: 120,
      valueGetter: (params) => {
        // Safari has a bug/bad behavior that does not mesh well with python's datetimes, fails to parse with fractions of a second (something to do with the decimal point)
        // Solved by removing fractions of a second, as they aren't relevant anyway
        // console.log(`Raw value: ${params.row.dateTime}`);
        // console.log(typeof params.row.dateTime);
        const dateTimeNoSecondFractions = params.row.dateTime.split('.')[0];
        // const parsedTime = parseISO(dateTimeNoSecondFractions);
        // console.log(`Parsed value: ${parsedTime}`);
        // Added ' UTC' to the end of the time to properly convert to local time, since all data is stored in the db in UTC
        // const newDate = new Date(parsedTime + ' UTC');
        const newDate = new Date(dateTimeNoSecondFractions + 'Z'); // Add the 'Z' to specify to the browser that this is in UTC, not local time
        return format(newDate, "MM/dd/yyyy");
        // const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
        // const formattedTime = formatInTimeZone(parsedTime, "MM/dd/yyyy", "UTC");
        // return formattedTime;
      },
    },
    {
      field: "isWetland",
      headerName: "Wetland?",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ color: params.row.isWetland === true ? "#4caf50" : "#ff9800" }}>
            {params.row.isWetland ? "Y" : "N"}
          </Typography>
        );
      },
    },
    {
      field: "formStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Typography sx={{ color: formStatusColor(params.row.formStatus) }}>
          {formStatusToString(params.row.formStatus)}
        </Typography>
      ),
    },
    {
      field: "lastModifiedTimeStamp",
      type: "dateTime",
      headerName: "Last Modified At:",
      width: 180,
      valueGetter: (params) => {
        const lastModifiedTimeStampNoSecondFractions = params.row.lastModifiedTimeStamp.split('.')[0];
        // const parsedTime = parseISO(lastModifiedTimeStampNoSecondFractions);
        // Added ' UTC' to the end of the time to properly convert to local time, since all data is stored in the db in UTC
        // const newDate = new Date(parsedTime + ' UTC');
        const newDate = new Date(lastModifiedTimeStampNoSecondFractions + 'Z');
        return format(newDate, "MM/dd/yyyy hh:mm aaa");
        // return parsedTime;
        // return parsedTime.toUTCString();
        // console.log(parsedTime);
        // const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
        // const formattedTime = formatInTimeZone(parsedTime, "yyyy-MM-dd kk:mm aaa", "UTC");
        // console.log(params.row.lastModifiedTimeStamp);
        // console.log(formattedTime);
        // return formattedTime;
        // return format(new Date(params.row.lastModifiedTimeStamp), "MM/dd/yyyy hh:mm aaa");
      },
    },
    {
      field: "qaqcer",
      headerName: "QAQCer",
      width: 120,
    },
  ];

  // When using this FormsList in the Project detail view, slightly change the columns
  const projectViewColumns = [
    {
      field: "editButton",
      type: "actions",
      headerName: "Edit Form",
      width: 100,
      hideable: false,
      renderCell: (params) => (
        <Button variant="contained" color="warning" size="small" onClick={() => handleNavigateToFormPage(params.row)}>
          <EditIcon />
        </Button>
      ),
    },
    {
      field: "user.name",
      headerName: "User",
      width: 200,
      valueGetter: (params) => {
        if (params.row.user) {
          if (params.row.user.first_name && params.row.user.last_name) {
            return `${params.row.user.first_name} ${params.row.user.last_name}`;
          }
        }
        return "";
      },
    },
    {
      field: "aceRegion",
      headerName: "USACE Region",
      width: 160,
    },
    {
      field: "sampleName",
      headerName: "Sample Name",
      width: 200,
    },
    {
      field: "dateTime",
      headerName: "Sampling Date",
      width: 120,
      valueGetter: (params) => {
        const dateTimeNoSecondFractions = params.row.dateTime.split('.')[0];
        const newDate = new Date(dateTimeNoSecondFractions + 'Z');
        return format(newDate, "MM/dd/yyyy");
      },
    },
    {
      field: "isWetland",
      headerName: "Wetland?",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography sx={{ color: params.row.isWetland === true ? "#4caf50" : "#ff9800" }}>
            {params.row.isWetland ? "Y" : "N"}
          </Typography>
        );
      },
    },
    {
      field: "formStatus",
      headerName: "Form Status",
      width: 120,
      renderCell: (params) => (
        <Typography sx={{ color: formStatusColor(params.row.formStatus) }}>
          {formStatusToString(params.row.formStatus)}
        </Typography>
      ),
    },
    {
      field: "qaqcer",
      headerName: "QAQCer",
      width: 120,
    },
    {
      field: "lastModifiedTimeStamp",
      type: "dateTime",
      headerName: "Last Modified At:",
      width: 220,
      valueGetter: (params) => {
        const lastModifiedTimeStampNoSecondFractions = params.row.lastModifiedTimeStamp.split('.')[0];
        // const parsedTime = parseISO(lastModifiedTimeStampNoSecondFractions);
        const newDate = new Date(lastModifiedTimeStampNoSecondFractions + 'Z');
        return format(newDate, "MM/dd/yyyy hh:mm aaa");
      },
    },
  ];

  // const [columnsModel, setColumnsModel] = React.useState(JSON.parse(localStorage.getItem('datagrid_columns')) ?? { items: [] });

  // const onColumnsChange = React.useCallback((columnsModel) => {
  //   setColumnsModel(columnsModel);
  //   localStorage.setItem('datagrid_columns', JSON.stringify(columnsModel));
  // }, []);

  const [filterModel, setFilterModel] = React.useState(JSON.parse(localStorage.getItem('datagrid_filters')) ?? { items: [] });

  const onFilterChange = React.useCallback((filterModel) => {
    setFilterModel(filterModel);
    localStorage.setItem('datagrid_filters', JSON.stringify(filterModel));
  }, []);

  const [sortModel, setSortModel] = React.useState(JSON.parse(localStorage.getItem('datagrid_sort')) ?? []);

  const onSortChange = React.useCallback((sortModel) => {
    setSortModel(sortModel);
    localStorage.setItem('datagrid_sort', JSON.stringify(sortModel));
  }, []);

  const [currentPage, setCurrentPage] = React.useState(0);
  const handleChangePage = React.useCallback((gridPageChangeParams) => {
    setCurrentPage(gridPageChangeParams.page);
  }, []);

  useEffect(() => {
    // Whenever this component is rendered, fetch forms. Used to check if wetlandFormsStatus was IDLE, but that would cause forms to not re-fetch
    // when navigating between pages after integrating projects. Also used to include project_id and wetlandFormsStatus in the dependencies for this function
    if (wetlandFormsStatus === formsLoadingStatus.IDLE) {
      if (project_id) {
        dispatch(getProjectWetlandFormsAsync(project_id));
      } else {
        dispatch(getAllWetlandFormsAsync({ filterByCurrentRegion: !useProjectView }));
      }
      // Also get all projects and store in redux state, as that is used for project assignment functions
      dispatch(getProjects());
    }
    
  }, [wetlandFormsStatus]);

  const handleNavigateToFormPage = (item) => {
    dispatch(setWetlandFormActionType(formActionType.EDIT));
    // The actual GET request is done in the form page itself, so that when navigating directly to that URL data is properly loaded.
    // Because of this, form ID is passed to the form page component using router state and used to query data there.
    // MUI DataGrid only contains the id field, so have to get the sample name for the matching form (based on id) before nagivating
    const sampleName = wetlandForms.find((form) => form.id === item.id).sampleName;
    let sanitizedSampleName = sampleName.replace(/\//g, '_'); // Remove any characters that might cause an issue with the route (most notably forward slash)
    if (sanitizedSampleName === '') {
      sanitizedSampleName = "unnamed-wetland-data-form";
    } else {}
    navigate(`/wetland/${sanitizedSampleName}`, { state: { actionType: formActionType.EDIT, formId: item.id } });
  };

  const conditionalNoFormsText = (loadingStatus, inProjectView) => {
    if (loadingStatus === formsLoadingStatus.ERROR) {
      return 'Error loading data forms. Either your session timed out, or your subscription has expired.';
    }
    if (loadingStatus === formsLoadingStatus.SUCCESS && inProjectView) {
      return 'No viewable forms collected/uploaded in this project.'
    }
    if (loadingStatus === formsLoadingStatus.SUCCESS && !inProjectView) {
      return 'No forms collected/uploaded in this region.'
    }
    return 'No forms could be loaded. This likely indicates an issue with your login session or subscription.'
  }

  return (
    <Box>
      <Card>
        {wetlandFormsStatus === formsLoadingStatus.LOADING && (
          <Box sx={{ m: 4, display: "flex", justifyContent: "center" }}>
            <CircularProgress size={48} />
          </Box>
        )}
        {wetlandFormsStatus !== formsLoadingStatus.LOADING && (
          <div style={{ width: "100%", minHeight: "300px" }}>
            <StyledDataGrid
              autoHeight
              // columnVisibilityModel={{
              //   sampleName: false,
              //   dateTime: false,
              // }}
              // onColumnVisibilityModelChange={(newModel) => console.log(newModel)}
              // onColumnVisibilityChange={(col) => console.log(col)}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: () => (
                  <Stack alignItems="center" justifyContent="center" mt={24}>
                    <Typography color={colorGreyLight} sx={{ m: 4, textAlign: "center" }}>
                      {conditionalNoFormsText(wetlandFormsStatus, useProjectView)}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack alignItems="center" justifyContent="center" mt={24}>
                    <Typography color={colorGreyLight} sx={{ m: 4, textAlign: "center" }}>
                      No forms matching the given criteria.
                    </Typography>
                  </Stack>
                ),
              }}
              rows={wetlandForms ?? []}
              columns={useProjectView ? projectViewColumns : columns}
              onColumnVisibilityChange={(newModel) => {
                if (newModel.field === 'projectSite') {
                  localStorage.setItem('formsTableHideProjectSite', !newModel.isVisible);
                }
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
              options={options}
              pageSize={30}
              rowsPerPageOptions={[30]}
              page={currentPage}
              onPageChange={handleChangePage}
              checkboxSelection
              disableSelectionOnClick
              // onRowClick={(rowId, event) => {
              //   console.log(event.type);
              //   handleNavigateToFormPage(rowId);
              //   }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectedForms(newSelectionModel));
              }}
              selectionModel={selectedFormIds}
              // onRowsSelect={(currRowSelected, allRowsSelected) => console.log(currRowSelected)}
              // onRowSelectionChange={(currentRowsSelected, allRowsSelected, rowsSelected) => console.log(currentRowsSelected)}
              filterModel={filterModel}
              onFilterModelChange={onFilterChange}
            />
          </div>
        )}
      </Card>
    </Box>
  );
}
