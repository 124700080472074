import axiosInstance from "../../auth";

class FileDownloadsApiService {
  generatePdf(id, flatten_output=false) {
    let url = `/api/wetland/${id}/generate_pdf/`;
    if (flatten_output) {
      url = `/api/wetland/${id}/generate_flattened_pdf/`;
    }
    return axiosInstance({
      method: 'get',
      url: url,
      responseType: "blob",
    });
  }

  generatePdfWithParameters(id, params) {
    const url = `/api/wetland/generate_pdf_with_parameters/`;
    return axiosInstance.post(
      url,
      {
        id: id,
        ...params,
      },
      {responseType: "blob"},
    );
  }

  fetchWetlandPhotos(id) {
    return axiosInstance({
      method: 'post',
      url: `/api/wetland-photo/from_wetland_id/`,
      data: {
        id: id
      },
    });
  }

  generatePdfsFromIds(ids, params) {
    return axiosInstance.post(
      `/api/wetland/generate_multiple_pdfs/`,
      {
        ids: ids,
        ...params,
      },
      {responseType: "blob", timeout: 1000 * 300},
    );
  }

  generatePhotosFromIds(ids, useOverlay) {
    const requestData = {
      ids: ids,
    };
    if (useOverlay === true) {
      requestData.photoOverlayFields = ['sampleName', 'date'];
    }
    return axiosInstance({
      method: 'post',
      url: `/api/wetland-photo/get_photos_from_wetland_ids/`,
      data: requestData,
      responseType: "blob",
      timeout: 1000 * 300, // Want a very long timeout
    });
  }

  getAllWetlands(queryParams) {
    return axiosInstance.get(`/api/wetland/`);
  }

  generateReceiptPdf(id) {
    const url = `/api/purchases/generate_receipt/${id}/`;
    return axiosInstance.get(
      url,
      {responseType: "blob"},
    );
  }
}

export default new FileDownloadsApiService();
