import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CircularProgress, Divider, ListItemText } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  resetFormsLoadingStatus,
  selectSelectedWetlandFormIds,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import { assignWetlandFormsToProject, selectProjects } from "../../../api/services/projects/projectsSlice";

export default function AssignFormsToProjectButton() {
  const dispatch = useDispatch();
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  const projects = useSelector(selectProjects);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAssignForms = async (event, projectUuid) => {
    event.preventDefault();
    setLoading(true);
    dispatch(assignWetlandFormsToProject({ dataFormIds: selectedFormIds, projectUuid: projectUuid })).then((res) => {
      setLoading(false);
      // Trigger a refresh of the wetland form data after a success
      if (res.payload.status === 200) {
        dispatch(resetFormsLoadingStatus());
      }
    });
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color="secondary"
        disabled={loading || !selectedFormIds.length}
        edge="end"
        aria-label="change the project that the selected data forms are assigned to"
        aria-controls={open ? "assign-data-forms-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        Change Project
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <Menu
        id="assign-data-forms-options-menu"
        MenuListProps={{
          "aria-labelledby": "assign-data-forms-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box px={2}>
          <ListItemText>Assign the selected data forms to a project:</ListItemText>
        </Box>
        <Divider />
        {projects?.map((project, index) => (
          <MenuItem key={`project-menu-item-${index}`} onClick={(e) => handleAssignForms(e, project.id)} disableRipple>
            {project.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
