export const soilColors = {
  "": [],
  "2.5Y": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [65, 59, 51],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [76, 71, 62],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [81, 70, 52],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [85, 69, 42],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [102, 95, 85],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [107, 95, 73],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [111,94,62],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [115, 93, 51],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [127, 121, 110],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [133, 120, 98],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [137, 119, 87],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [142, 119, 75],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [149, 117, 48],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [153, 147, 135],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [158, 146, 22],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [163, 145, 111],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [168, 145, 99],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [175, 143, 73],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [182, 142, 45],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [179, 173, 160],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [185, 172, 147],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [190, 172, 135],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [195, 171, 123],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [203, 169, 99],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [209, 168, 72],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [205, 199, 186],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [211, 199, 172],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [216, 198, 160],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [221, 198, 148],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 6,
      "rgb": [229, 196, 123],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 8,
      "rgb": [237, 195, 97],
      "gleyHue": null
    }
  ],
  "5Y": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [64, 60, 51],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [67, 60, 41],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [75, 71, 61],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 2,
      "rgb": [79, 71, 51],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [100, 96, 84],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [104, 96, 73],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [107, 95, 60],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [111, 95, 48],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [125, 121, 109],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [129, 121, 97],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [133, 121, 85],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [136, 121, 72],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [142, 120, 44],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [151, 147, 134],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [155, 147, 121],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [159, 147, 109],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [162, 147, 96],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [168, 146, 69],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [173, 145, 36],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [178, 173, 160],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [182, 173, 146],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [185, 173, 133],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [188, 173, 120],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [194, 173, 94],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [199, 172, 65],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [204, 200, 185],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [208, 200, 171],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [211, 200, 158],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [214, 200, 145],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 6,
      "rgb": [220, 200, 118],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 8,
      "rgb": [226, 199, 89],
      "gleyHue": null
    }
  ],
  "10Y": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [61, 61, 51],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [73, 72, 62],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 2,
      "rgb": [74, 73, 52],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [75, 73, 29],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [97, 97, 85],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [98, 97, 73],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [101, 98, 48],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [123, 122, 110],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [124, 123, 98],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [126, 124, 72],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [149, 148, 134],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [150, 149, 121],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [153, 150, 96],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [175, 174, 160],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [202, 201, 185],
      "gleyHue": null
    }
  ],
  "5GY": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [58, 61, 53],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [70, 73, 64],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 2,
      "rgb": [68, 74, 56],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [63, 76, 37],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [94, 97, 87],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [92, 99, 77],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [88, 101, 56],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [120, 123, 111],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [118, 125, 101],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [114, 127, 80],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [145, 149, 136],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [143, 151, 124],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [140, 153, 103],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [172, 175, 161],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [198, 202, 186],
      "gleyHue": null
    }
  ],
  "10GY": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [56, 62, 56],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [67, 73, 67],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [91, 98, 90],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [116, 124, 115],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [141, 150, 140],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [166, 176, 165],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [192, 203, 191],
      "gleyHue": null
    }
  ],
  "5G": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [54, 62, 58],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [48, 64, 56],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [65, 74, 69],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 2,
      "rgb": [58, 76, 67],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [88, 98, 93],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [80, 101, 90],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [113, 124, 118],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [104, 127, 115],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [138, 150, 143],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [128, 153, 140],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [163, 177, 169],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [153, 180, 165],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [189, 203, 195],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [178, 207, 190],
      "gleyHue": null
    }
  ],
  "5R": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [69, 57, 58],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [78, 54, 55],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [81, 68, 68],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [90, 65, 65],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [98, 62, 62],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [105, 58, 59],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [105, 93, 93],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [114, 90, 89],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [122, 87, 86],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [130, 84, 82],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [143, 77, 76],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [130, 119, 118],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [138, 117, 115],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [147, 113, 112],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [155, 110, 108],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [170, 103, 101],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [183, 96, 94],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [156, 145, 144],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [164, 142, 141],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [172, 139, 137],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [181, 137, 134],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [196, 130, 126],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [209, 123, 119],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [181, 171, 170],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [190, 169, 167],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [198, 166, 163],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [207, 163, 159],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [223, 157, 152],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [238, 149, 144],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [207, 198, 197],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [214, 196, 194],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [224, 193, 190],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [234, 189, 185],
      "gleyHue": null
    }
  ],  
  "7.5R": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [69,57,57],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [78,54,53],
      "gleyHue": null
    },
       {
      "value": 2.5,
      "chroma": 3,
      "rgb": [84,51,50],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 4,
      "rgb": [91,48,46],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [81,68,67],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [90,65,63],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [98,62,59],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [105,59,55],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 6,
      "rgb": [116,52,47],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [105,93,92],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [115,90,88],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [122,87,83],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [130,84,79],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [143,77,70],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 8,
      "rgb": [155,70,61],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [130,119,117],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [139,117,113],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [147,113,109],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [155,110,104],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [170, 103, 101],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [183,97,84],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [156,145,143],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [165,142,139],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [173,139,134],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [181,137,129],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [196,131,120],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [210,124,110],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [182,171,169],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [190,169,165],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [199,166,160],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [208,163,155],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [224,157,145],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [240,150,133],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [207,198,196],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [214,196,192],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [225,193,186],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [236,189,180],
      "gleyHue": null
    }
  ],  
  "10R": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [69,57,56],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [78,55,51],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [81,69,66],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [90,66,61],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [97,63,56],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [104,60,51],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 6,
      "rgb": [115,53,41],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [105,93,91],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [115,91,85],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [122,88,79],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [130,85,74],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [142,79,62],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 8,
      "rgb": [154,72,50],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [130,119,116],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [139,117,111],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [147,114,105],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [155,111,98],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [170,105,85],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [182,98,72],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [156,145,142],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [165,142,136],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [174,140,130],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [182,137,123],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [196,131,111],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [209,125,98],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [182,171,168],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [191,169,162],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [200,166,156],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [208,163,149],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [224,158,136],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [239,151,121],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [207,198,195],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [215,196,190],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [226,193,182],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [236,190,174],
      "gleyHue": null
    }
  ],  
  "2.5YR": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [68,58,53],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [76,56,47],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 3,
      "rgb": [83,52,43],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 4,
      "rgb": [89,50,37],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [80,69,65],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [89,66,59],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [96,64,52],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [102,61,46],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 6,
      "rgb": [113,56,32],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [105,93,89],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [114,91,82],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [122,88,75],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [129,86,68],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [140,81,54],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 8,
      "rgb": [150,75,38],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [130,119,115],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [139,117,108],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [147,114,101],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [154,112,94],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [167,107,78],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [179,101,61],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [156,145,140],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [166,143,133],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [173,140,126],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [181,138,118],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [195,133,103],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [207,128,87],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [182,171,166],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [191,169,159],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [199,167,151],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [208,164,144],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [223,159,127],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [236,154,111],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [208,198,193],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [216,196,187],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [226,193,177],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [236,190,168],
      "gleyHue": null
    }
  ], 
  "5YR": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [68,58,53],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [76,56,47],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [80,69,64],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [88,67,56],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [94,65,49],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [99,63,42],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [105,94,88],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [113,92,79],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [120,89,71],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [127,87,63],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [136,83,47],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [130,119,113],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [138,117,105],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [145,115,97],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [152,113,88],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [164,109,70],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [174,105,49],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [156,145,139],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [165,143,130],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [172,141,122],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [179,139,113],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [192,135,94],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [202,131,75],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [182,171,165],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [191,169,156],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [198,167,147],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [206,165,138],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [219,161,119],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [230,157,100],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [208,198,191],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [217,196,182],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [225,194,172],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [233,192,162],
      "gleyHue": null
    }
  ], 
  "7.5YR": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [67,58,52],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 2,
      "rgb": [74,57,45],
      "gleyHue": null
    },
     {
      "value": 2.5,
      "chroma": 3,
      "rgb": [79,55,36],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [79,70,63],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [86,68,55],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [91,66,46],
      "gleyHue": null
    },
     {
      "value": 3,
      "chroma": 4,
      "rgb": [96,65,38],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [104,94,87],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [112,92,77],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 3,
      "rgb": [118,91,68],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 4,
      "rgb": [124,89,58],
      "gleyHue": null
    },
     {
      "value": 4,
      "chroma": 6,
      "rgb": [132,86,40],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [129,120,112],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 2,
      "rgb": [137,118,103],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 3,
      "rgb": [144,116,93],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 4,
      "rgb": [150,115,84],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 6,
      "rgb": [161,111,63],
      "gleyHue": null
    },
     {
      "value": 5,
      "chroma": 8,
      "rgb": [169,108,39],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [155,146,137],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 2,
      "rgb": [164,144,128],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 3,
      "rgb": [170,142,118],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 4,
      "rgb": [176,141,109],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 6,
      "rgb": [188,137,88],
      "gleyHue": null
    },
     {
      "value": 6,
      "chroma": 8,
      "rgb": [197,134,65],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [182,172,163],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 2,
      "rgb": [190,170,153],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 3,
      "rgb": [197,168,143],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 4,
      "rgb": [203,167,133],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 6,
      "rgb": [215,164,112],
      "gleyHue": null
    },
     {
      "value": 7,
      "chroma": 8,
      "rgb": [225,161,90],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [208,198,189],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [216,197,179],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 3,
      "rgb": [223,195,168],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 4,
      "rgb": [230,194,158],
      "gleyHue": null
    },
     {
      "value": 8,
      "chroma": 6,
      "rgb": [243,190,137],
      "gleyHue": null
    }
  ], 
  "10YR": [
    {
      "value": 2,
      "chroma": 1,
      "rgb": [54,48,41],
      "gleyHue": null
    },
    {
      "value": 2,
      "chroma": 2,
      "rgb": [60,46,33],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [78,70,62],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [84,69,53],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 3,
      "rgb": [88,68,44],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 4,
      "rgb": [93,67,34],
      "gleyHue": null
    },
    {
      "value": 3,
      "chroma": 6,
      "rgb": [99,64,8],
      "gleyHue": null
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [103,95,85],
      "gleyHue": null
    },
    {
      "value": 4,
      "chroma": 2,
      "rgb": [110,93,75],
      "gleyHue": null
    },
    {
      "value": 4,
      "chroma": 3,
      "rgb": [115,92,64],
      "gleyHue": null
    },
    {
      "value": 4,
      "chroma": 4,
      "rgb": [120,91,54],
      "gleyHue": null
    },
    {
      "value": 4,
      "chroma": 6,
      "rgb": [127,89,33],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [128,120,111],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 2,
      "rgb": [135,119,100],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 3,
      "rgb": [141,118,90],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 4,
      "rgb": [146,116,79],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 6,
      "rgb": [155,114,55],
      "gleyHue": null
    },
    {
      "value": 5,
      "chroma": 8,
      "rgb": [162,112,27],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [154,146,136],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 2,
      "rgb": [161,145,125],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 3,
      "rgb": [167,144,114],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 4,
      "rgb": [172,143,103],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 6,
      "rgb": [182,140,80],
      "gleyHue": null
    },
    {
      "value": 6,
      "chroma": 8,
      "rgb": [190,138,54],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [180,172,162],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 2,
      "rgb": [188,171,150],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 3,
      "rgb": [193,170,139],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 4,
      "rgb": [199,169,127],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 6,
      "rgb": [209,167,104],
      "gleyHue": null
    },
    {
      "value": 7,
      "chroma": 8,
      "rgb": [217,164,80],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [207,199,187],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 2,
      "rgb": [214,198,175],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 3,
      "rgb": [220,197,164],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 4,
      "rgb": [226,196,152],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 6,
      "rgb": [236,193,129],
      "gleyHue": null
    },
    {
      "value": 8,
      "chroma": 8,
      "rgb": [245,191,104],
      "gleyHue": null
    }
  ],
  "GLEY1": [
    {
      "value": 2.5,
      "chroma": 0,
      "rgb": [60,60,60],
      "gleyHue": "N"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [61,61,51],
      "gleyHue": "10Y"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [58,61,53],
      "gleyHue": "5GY"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [56,62,56],
      "gleyHue": "10GY"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [54,62,58],
      "gleyHue": "5G"
    },
    {
      "value": 2.5,
      "chroma": 2,
      "rgb": [48,64,56],
      "gleyHue": "5G"
    },
    {
      "value": 3,
      "chroma": 0,
      "rgb": [72,72,72],
      "gleyHue": "N"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [73,72,62],
      "gleyHue": "10Y"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [70,73,64],
      "gleyHue": "5GY"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [67,73,67],
      "gleyHue": "10GY"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [65,74,69],
      "gleyHue": "5G"
    },
    {
      "value": 3,
      "chroma": 2,
      "rgb": [58,76,67],
      "gleyHue": "5G"
    },
    {
      "value": 4,
      "chroma": 0,
      "rgb": [96,96,96],
      "gleyHue": "N"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [97,97,85],
      "gleyHue": "10Y"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [94,97,87],
      "gleyHue": "5GY"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [91,98,90],
      "gleyHue": "10GY"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [88,98,93],
      "gleyHue": "5G"
    },
     {
      "value": 4,
      "chroma": 2,
      "rgb": [80,101,90],
      "gleyHue": "5G"
    },
    {
      "value": 5,
      "chroma": 0,
      "rgb": [121,121,121],
      "gleyHue": "N"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [123,122,110],
      "gleyHue": "10Y"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [120,123,111],
      "gleyHue": "5GY"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [116,124,115],
      "gleyHue": "10GY"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [113,124,118],
      "gleyHue": "5G"
    },
    {
      "value": 5,
      "chroma": 2,
      "rgb": [104,127,115],
      "gleyHue": "5G"
    },
     {
      "value": 6,
      "chroma": 0,
      "rgb": [147,147,147],
      "gleyHue": "N"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [149,148,134],
      "gleyHue": "10Y"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [145,149,136],
      "gleyHue": "5GY"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [141,150,140],
      "gleyHue": "10GY"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [138,150,143],
      "gleyHue": "5G"
    },
    {
      "value": 6,
      "chroma": 2,
      "rgb": [128,153,140],
      "gleyHue": "5G"
    },
     {
      "value": 7,
      "chroma": 0,
      "rgb": [173,173,173],
      "gleyHue": "N"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [175,174,160],
      "gleyHue": "10Y"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [172,175,161],
      "gleyHue": "5GY"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [166,176,165],
      "gleyHue": "10GY"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [163,177,169],
      "gleyHue": "5G"
    },
    {
      "value": 7,
      "chroma": 2,
      "rgb": [153,180,165],
      "gleyHue": "5G"
    },
     {
      "value": 8,
      "chroma": 0,
      "rgb": [200,200,200],
      "gleyHue": "N"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [202,201,185],
      "gleyHue": "10Y"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [198,202,186],
      "gleyHue": "5GY"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [192,203,191],
      "gleyHue": "10GY"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [189,203,195],
      "gleyHue": "5G"
    },
    {
      "value": 8,
      "chroma": 2,
      "rgb": [178,207,190],
      "gleyHue": "5G"
    }
  ],
  "GLEY2": [
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [53,62,59],
      "gleyHue": "10G"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [52,62,62],
      "gleyHue": "5BG"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [51,62,64],
      "gleyHue": "10BG"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [52,62,65],
      "gleyHue": "5B"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [54,61,67],
      "gleyHue": "10B"
    },
    {
      "value": 2.5,
      "chroma": 1,
      "rgb": [57,60,67],
      "gleyHue": "5PB"
    },
     {
      "value": 3,
      "chroma": 1,
      "rgb": [63,74,71],
      "gleyHue": "10G"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [62,74,73],
      "gleyHue": "5BG"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [62,74,75],
      "gleyHue": "10BG"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [62,73,77],
      "gleyHue": "5B"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [64,73,78],
      "gleyHue": "10B"
    },
    {
      "value": 3,
      "chroma": 1,
      "rgb": [68,72,79],
      "gleyHue": "5PB"
    },
     {
      "value": 4,
      "chroma": 1,
      "rgb": [87,98,95],
      "gleyHue": "10G"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [86,98,97],
      "gleyHue": "5BG"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [86,98,99],
      "gleyHue": "10BG"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [87,98,101],
      "gleyHue": "5B"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [89,97,102],
      "gleyHue": "10B"
    },
    {
      "value": 4,
      "chroma": 1,
      "rgb": [93,96,103],
      "gleyHue": "5PB"
    },
     {
      "value": 5,
      "chroma": 1,
      "rgb": [112,124,120],
      "gleyHue": "10G"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [111,124,123],
      "gleyHue": "5BG"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [111,124,125],
      "gleyHue": "10BG"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [113,123,126],
      "gleyHue": "5B"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [115,123,128],
      "gleyHue": "10B"
    },
    {
      "value": 5,
      "chroma": 1,
      "rgb": [118,122,128],
      "gleyHue": "5PB"
    },
     {
      "value": 6,
      "chroma": 1,
      "rgb": [137,150,146],
      "gleyHue": "10G"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [136,150,148],
      "gleyHue": "5BG"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [136,150,150],
      "gleyHue": "10BG"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [138,149,152],
      "gleyHue": "5B"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [141,148,153],
      "gleyHue": "10B"
    },
    {
      "value": 6,
      "chroma": 1,
      "rgb": [144,148,153],
      "gleyHue": "5PB"
    },
     {
      "value": 7,
      "chroma": 1,
      "rgb": [162,177,172],
      "gleyHue": "10G"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [162,176,174],
      "gleyHue": "5BG"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [162,176,176],
      "gleyHue": "10BG"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [164,175,178],
      "gleyHue": "5B"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [167,175,179],
      "gleyHue": "10B"
    },
    {
      "value": 7,
      "chroma": 1,
      "rgb": [170,174,179],
      "gleyHue": "5PB"
    },
     {
      "value": 8,
      "chroma": 1,
      "rgb": [188,203,198],
      "gleyHue": "10G"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [188,203,200],
      "gleyHue": "5BG"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [188,203,203],
      "gleyHue": "10BG"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [191,202,204],
      "gleyHue": "5B"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [194,201,205],
      "gleyHue": "10B"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [197,200,205],
      "gleyHue": "5PB"
    }
  ],
  "WHITE": [
    {
      "value": 8,
      "chroma": 0,
      "rgb": [200,200,200],
      "gleyHue": "N"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [205,199,186],
      "gleyHue": "2.5Y"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [216,197,179],
      "gleyHue": "7.5YR"
    },
    {
      "value": 8,
      "chroma": 1,
      "rgb": [207,199,187],
      "gleyHue": "10YR"
    },
    {
      "value": 8,
      "chroma": 2,
      "rgb": [211,199,172],
      "gleyHue": "2.5Y"
    },
    {
      "value": 8,
      "chroma": 2,
      "rgb": [216,197,179],
      "gleyHue": "7.5YR"
    },
     {
      "value": 8,
      "chroma": 2,
      "rgb": [214,198,175],
      "gleyHue": "10YR"
    },
     {
      "value": 8.5,
      "chroma": 0,
      "rgb": [213,213,213],
      "gleyHue": "N"
    },
    {
      "value": 8.5,
      "chroma": 1,
      "rgb": [219,213,199],
      "gleyHue": "2.5Y"
    },
    {
      "value": 8.5,
      "chroma": 1,
      "rgb": [221,212,202],
      "gleyHue": "7.5YR"
    },
    {
      "value": 8.5,
      "chroma": 1,
      "rgb": [220,212,200],
      "gleyHue": "10YR"
    },
    {
      "value": 8.5,
      "chroma": 2,
      "rgb": [225,212,185],
      "gleyHue": "2.5Y"
    },
    {
      "value": 8.5,
      "chroma": 2,
      "rgb": [230,210,191],
      "gleyHue": "7.5YR"
    },
     {
      "value": 8.5,
      "chroma": 2,
      "rgb": [228,211,188],
      "gleyHue": "10YR"
    },
     {
      "value": 9,
      "chroma": 0,
      "rgb": [227,227,227],
      "gleyHue": "N"
    },
    {
      "value": 9,
      "chroma": 1,
      "rgb": [233,226,212],
      "gleyHue": "2.5Y"
    },
    {
      "value": 9,
      "chroma": 1,
      "rgb": [235,225,215],
      "gleyHue": "7.5YR"
    },
    {
      "value": 9,
      "chroma": 1,
      "rgb": [234,226,213],
      "gleyHue": "10YR"
    },
    {
      "value": 9,
      "chroma": 2,
      "rgb": [239,226,197],
      "gleyHue": "2.5Y"
    },
    {
      "value": 9,
      "chroma": 2,
      "rgb": [243,224,204],
      "gleyHue": "7.5YR"
    },
     {
      "value": 9,
      "chroma": 2,
      "rgb": [241,225,200],
      "gleyHue": "10YR"
    },
     {
      "value": 9.5,
      "chroma": 0,
      "rgb": [253,253,253],
      "gleyHue": "N"
    },
    {
      "value": 9.5,
      "chroma": 1,
      "rgb": [247,241,225],
      "gleyHue": "2.5Y"
    },
    {
      "value": 9.5,
      "chroma": 1,
      "rgb": [249,239,229],
      "gleyHue": "7.5YR"
    },
    {
      "value": 9.5,
      "chroma": 1,
      "rgb": [248,240,227],
      "gleyHue": "10YR"
    },
    {
      "value": 9.5,
      "chroma": 2,
      "rgb": [252,240,210],
      "gleyHue": "2.5Y"
    },
    {
      "value": 9.5,
      "chroma": 2,
      "rgb": [255,238,217],
      "gleyHue": "7.5YR"
    },
     {
      "value": 9.5,
      "chroma": 2,
      "rgb": [255,239,213],
      "gleyHue": "10YR"
    }
  ]
}