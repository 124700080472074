import React from "react";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentWetlandFormShowSubregionIndOverrideToggle,
  selectCurrentWetlandFormUseSubregionIndicatorIverrides,
  toggleCurrentWetlandFormUseSubregionOverrides,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";

export default function SubregionIndOverrideSwitch() {
  const showComponent = useSelector(selectCurrentWetlandFormShowSubregionIndOverrideToggle);
  const useSubregionIndicatorOverrides = useSelector(selectCurrentWetlandFormUseSubregionIndicatorIverrides);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(toggleCurrentWetlandFormUseSubregionOverrides({ value: e.target.checked }));
  };

  if (showComponent) {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="useSubregionIndicatorOverrides"
              checked={useSubregionIndicatorOverrides}
              onChange={handleChange}
            />
          }
          label="Use subregion indicator overrides?"
        />
      </FormGroup>
    );
  }
}
