import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Chip, CardActionArea, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setSelectedProject } from "../../api/services/projects/projectsSlice";
import { useDispatch } from "react-redux";
import { useContainerDimensions } from "../../utils/hooks/useDimensions";
// import { useDimensions } from "../../utils/hooks/useDimensions";

export const projectStatusColor = (status) => {
  if (status === "Active") return "text.secondary";
  if (status === "Inactive") return "text.warning";
  if (status === "Complete") return "text.success";
  if (status === "Stopped") return "text.error";
  return "text.secondary";
};

export default function ProjectCard({ projectData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const { width, height } = useContainerDimensions(ref);

  const handleNavigateToProjectPage = () => {
    // Before navigating, set the selected project to null, so that if a project was previously selected it isn't shown until the newly selected project data loads
    dispatch(setSelectedProject(null));
    // Project data is re-fetched when going to the specific project's page, GET request done on initial load there instead. Just pass the project UUID and slug and use that to query
    navigate(`/projects/${projectData.slug}-${projectData.id}`, { state: { initialProjectData: projectData } });
  };

  const sortedAndTruncatedProjectAssignments = () => {
    let sortedAssignments = [...projectData.assignment_projects];
    sortedAssignments.sort((a, b) =>
      a.permission_level > b.permission_level ? 1 : b.permission_level > a.permission_level ? -1 : 0
    );
    const numOfClippingChips = numberOfChipsLikelyToClip(sortedAssignments);
    if (numOfClippingChips > 0) {
      let clippedAssignments = sortedAssignments.slice(0, -numOfClippingChips);
      clippedAssignments.push({
        permission_level: "standard",
        user: {
          full_name: `+${numOfClippingChips}`,
        },
        isClipUIElement: true,
      });
      return clippedAssignments;
    }
    return sortedAssignments;
  };

  const numberOfChipsLikelyToClip = (assignments) => {
    // Just approximated based on number of characters. Could use useRef and DOM to check for overflow in a useEffect, but this is the simpler solution
    // Each chip start/stop has extra margin/padding, so that is also factored in, in addition to the raw number of characters in the names
    let estimatedCharactersFromCardWidth = Math.round((width / 7.1) - 4);
    let adjustedCharactersNum = 0;
    let numberLikelyClips = 0;
    assignments.forEach((assignment, i) => {
      adjustedCharactersNum += assignment.user.full_name.length + 4;
      if (adjustedCharactersNum > estimatedCharactersFromCardWidth) {
        numberLikelyClips += 1;
      }
    });
    return numberLikelyClips;
  };

  return (
    <Card
      ref={ref} // ref is used for checking the width of the card, and changing the project assignment chips overflow accordingly
      variant="outlined"
      sx={{
        minWidth: 275,
        borderColor: projectData.status === "Active" ? "secondary.main" : projectStatusColor(projectData.status),
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {projectData.name}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {projectData.project_number ? projectData.project_number : "No project number"}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color={projectStatusColor(projectData.status)}>
          {projectData.status} {projectData.completion_date && `(${projectData.completion_date})`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Created by: {projectData.created_by.full_name}
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ overflow: "clip" }}>
          {projectData.assignment_projects.length === 0 && (
            <Chip label="None" title="No surveyors" size="small" variant="outlined" style={{ color: "#9E9790" }} />
          )}
          {sortedAndTruncatedProjectAssignments().map((projectAssignment, index) =>
            projectAssignment.permission_level === "ProjectEditor" ? (
              <Chip
                key={`project-leads-${index}`}
                label={projectAssignment.user.full_name}
                title="Assigned to project as a Project Editor"
                size="small"
                color="secondary"
              />
            ) : (
              <Chip
                key={`project-standard-users-${index}`}
                label={projectAssignment.user.full_name}
                title={
                  projectAssignment.hasOwnProperty("isClipUIElement")
                    ? "Additional Users"
                    : "Assigned to project as a Standard User"
                }
                size="small"
              />
            )
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          onClick={() => handleNavigateToProjectPage()}
          aria-label="Navigate to project page and data forms"
        >
          View Project
        </Button>
      </CardActions>
    </Card>
  );
}
