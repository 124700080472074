import React, { useState } from "react";
import { Button, Snackbar, SnackbarContent, Typography } from "@mui/material";

// Shows a positioned snackbar informing the user that the site uses cookies. Once the "I understand" button has been clicked, save that acknowledgement in local storage
export default function CookiesBanner() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnderstoodClicked = (e) => {
    e.preventDefault();
    setOpen(false);
    localStorage.setItem('cookiesUnderstood', true);
  };

  if (!localStorage.getItem('cookiesUnderstood')) {return (
    <Snackbar
      severity="info"
      key="cookies-popup"
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{
          backgroundColor: "white",
        }}
        message={
          <Typography id="cookies-snackbar-message" sx={{ color: "#000000" }}>
            This website uses cookies to monitor for errors and issues and enhance user experience. None of this data is collected for advertising or marketing purposes.
          </Typography>
        }
        action={
          <Button color="info" size="small" onClick={handleUnderstoodClicked}>
            I understand
          </Button>
        }
      />
    </Snackbar>
  );} else {
    return null;
  }
}
