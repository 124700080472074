import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormSectionHeader from "../../../../components/FormSectionHeader";
import { selectCurrentWetlandSoilLayers, addCurrentWetlandSoilLayer } from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import SoilLayer from "./SoilLayer";

export default function SoilProfile() {
  const dispatch = useDispatch();
  const soilLayers = useSelector(selectCurrentWetlandSoilLayers);

  const handleAddLayer = (event) => {
    event.preventDefault();
    dispatch(addCurrentWetlandSoilLayer());
  };

  return (
    <Stack spacing={2}>
      <FormSectionHeader text={"Soil Profile"} mt={0} mx={0} />
      {soilLayers.map((layer, index) => (
        <SoilLayer key={`soil-layer-${index}`} index={index} />
      ))}
      <Box alignSelf="center">
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddLayer}>
          Add
        </Button>
      </Box>
    </Stack>
  );
}
