import React, { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Stack,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmPasswordResetRelURL } from "../../api/auth";
import axios from 'axios';
import { colorError, colorSuccess } from '../../utils/colors';

export default function PasswordReset() {
  const navigate = useNavigate();
  let params = useParams();

  const [resetData, setResetData] = useState({
    resetUid: params.resetUid,
    resetToken: params.resetToken,
    newPassword1: "",
    newPassword2: "",
  });

  const isNewPasswordValid = () => {
    if (resetData.newPassword1 !== resetData.newPassword2) return false;
    let regex = /(?=.*[A-Z])(?=.*\W)/;
    if (resetData.newPassword1.length >= 8 && regex.test(resetData.newPassword1) === true) return true;
    return false;
  };

  const passwordLengthValid = () => {
    return resetData.newPassword1.length >= 8 ? true : false;
  };

  const passwordUppercaseValid = () => {
    let regex = /(?=.*[A-Z])/;
    return regex.test(resetData.newPassword1) === true ? true : false;
  };

  const passwordSymbolValid = () => {
    let regex = /(?=.*\W)/;
    return regex.test(resetData.newPassword1) === true ? true : false;
  };

  const handleChange = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let baseURL = 'http://0.0.0.0/';
    if (window.location.origin === "http://localhost:3000") {
      baseURL = 'http://127.0.0.1:8000/';
    } else {
      baseURL = window.location.origin;
    }
    const tempAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      }, 
    });
    tempAxiosInstance
			.post(confirmPasswordResetRelURL, {
				uid: resetData.resetUid,
				token: resetData.resetToken,
				new_password: resetData.newPassword1,
			})
			.then((res) => {
        if (res.status === 204) {
          navigate("/password_reset/success");
        } else {
          navigate("/password_reset/error");
        }
			}).catch((error) => {
        // Continue to error page
        navigate("/password_reset/error");
      });
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Container maxWidth="xs" component="form" onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Stack>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword1"
              label="New Password"
              type="password"
              id="newPassword1"
              autoComplete="current-password"
              onChange={handleChange}
              error={!isNewPasswordValid()}
            />
            <Stack direction="row" spacing={1} color={passwordLengthValid() ? colorSuccess : colorError}>
              {passwordLengthValid() ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
              <Typography>
                At least 8 characters
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} color={passwordUppercaseValid() ? colorSuccess : colorError}>
              {passwordUppercaseValid() ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
              <Typography>
                At least one uppercase character
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} color={passwordSymbolValid() ? colorSuccess : colorError}>
              {passwordSymbolValid() ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
              <Typography>
                At least one symbol
              </Typography>
            </Stack>
          </Stack>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword2"
            label="New Password (confirm)"
            type="password"
            id="newPassword2"
            autoComplete="current-password"
            onChange={handleChange}
            error={!isNewPasswordValid()}
            helperText={!isNewPasswordValid() ? "Invalid Password (passwords must match)." : ""}
          />
          <Button type="submit" fullWidth variant="contained">
            Reset Password
          </Button>
        </Stack>
      </Container>
    </Container>
  )
}
