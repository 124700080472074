import axiosInstance from '../../auth';

class ProjectsApiService {

  getProjects() {
    return axiosInstance.get(`api/projects/`);
  }

  getProjectDetail(slug, id) {
    return axiosInstance.get(`/api/projects/${slug}-${id}/`);
  }

  addProject(addProjectData) {
    return axiosInstance.post(`/api/projects/`, addProjectData);
  }

  updateProject(updatedProjectData) {
    return axiosInstance.put(`/api/projects/${updatedProjectData.slug}-${updatedProjectData.id}/`, updatedProjectData);
  }

  deleteProject(slug, id) {
    return axiosInstance.delete(`/api/projects/${slug}-${id}/`);
  }

  updateProjectAssignmentRoles(project_id, userAssignments) {
    return axiosInstance.post(`/api/projects/change_user_project_assignments`, {
      project_id: project_id,
      user_assignments: userAssignments,
    });
  }

  updateWetlandFormsAssignedProject(dataFormIds, projectUuid) {
    return axiosInstance.post(`/api/wetland/update_wetland_project_assignments/`, {
      project_id: projectUuid,
      data_form_ids: dataFormIds,
    });
  }

}

export default new ProjectsApiService();