import { useSelector } from 'react-redux';
import { selectValidRefreshToken } from '../../api/services/user/userSlice';

export function useIsAuthenticated() {
  // If a valid refresh token is present in the redux state (or in localstorage, checked as a fallback), then the user is authenticated.
  // This does not make any API calls to truly verify, and should only be used to confirm if certain UI components should or should not be rendered.
  let refreshToken = useSelector(selectValidRefreshToken);
  if (!refreshToken) {
    refreshToken = localStorage.getItem('refresh_token');
  }
  if (refreshToken) {
    const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
      const now = Math.ceil(Date.now() / 1000);  // Divide by 1000 because refresh token is in milliseconds
      if (tokenParts.exp > now) {
        return true;
      }
  }
  return false;
}