import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { getUserMembership, setOrganization, setOrgMembership, userCreateOrganization } from "../../../api/services/user/userSlice";

export default function CreateOrganizationDialog({ showSnackbar }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [orgNameText, setOrgNameText] = useState("");

  const changeOrgNameText = (e) => {
    setOrgNameText(e.target.value);
  };

  const handleCreateOrganization = (event) => {
    event.preventDefault();
    if (orgNameText !== "") {
      dispatch(userCreateOrganization(orgNameText)).then((res) => {
        // If the request was successful, update the organization info itself.
        if (res.payload?.status === 201) {
          showSnackbar("success", `Created organization ${orgNameText}.`);
          dispatch(setOrganization(res.payload.data));
          dispatch(getUserMembership()).then((res) => {
            if (res.payload.status === 200) {
              dispatch(setOrgMembership(res.payload.data));
            }
          });
          handleClose();
        } else {
          showSnackbar("error", `Error creating organization.`);
        }
      });
    }
  };

  return (
    <Fragment>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Create an Organization
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create an Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new organization. You will automatically become the organization manager.
          </DialogContentText>
          <TextField
            autoFocus
            id="createOrganizationName"
            label="Organization Name"
            fullWidth
            margin="normal"
            value={orgNameText}
            onChange={changeOrgNameText}
            error={orgNameText === ""}
            helperText={orgNameText === "" ? "Organization Name cannot be empty" : null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={orgNameText === ""}
            onClick={handleCreateOrganization}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
