import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Toolbar } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateJsonFromSelection } from "../../../api/services/filedownloads/fileDownloadsSlice";
import { generateWetlandExcel, generateWetlandPointsShapefile, selectSelectedWetlandFormIds } from "../../../api/services/wetlandforms/wetlandFormsSlice";
import GeneratePhotosButton from "./GeneratePhotosButton";
import GeneratePDFsButton from "./GeneratePDFsButton";

export default function ActionButtonsToolbar() {
  const dispatch = useDispatch();
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  // const selectedWetlandForms = useSelector(selectSelectedWetlandForms);
  // const aceRegion = useSelector(state => state.wetlandForms.region);

  return (
    <Toolbar>
      <Box sx={{ position: "relative" }}>
        <GeneratePDFsButton buttonText='PDFs' />
      </Box>
      <Box sx={{ ml: 2 }}>
        <GeneratePhotosButton />
      </Box>
      <Box sx={{ ml: 2 }}>
        <Button
            disabled={!selectedFormIds.length}
            edge="end"
            aria-label="generate shapefile"
            onClick={() => dispatch(generateWetlandPointsShapefile(selectedFormIds))}
            variant="contained"
            startIcon={<FileDownloadIcon />}
          >
            Shapefile
          </Button>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Button
            disabled={!selectedFormIds.length}
            edge="end"
            aria-label="generate JSON file"
            onClick={() => dispatch(generateJsonFromSelection(selectedFormIds))}
            variant="contained"
            startIcon={<FileDownloadIcon />}
          >
            JSON
          </Button>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Button
            disabled={!selectedFormIds.length}
            edge="end"
            aria-label="generate Excel file"
            onClick={() => dispatch(generateWetlandExcel(selectedFormIds))}
            variant="contained"
            startIcon={<FileDownloadIcon />}
          >
            Excel
          </Button>
      </Box>
      {/* {(aceRegion === 'NCNE' || aceRegion === 'MW') && <Box sx={{ ml: 2 }}>
        <Button
            disabled={!selectedFormIds.length || selectedFormIds.length > 1}
            edge="end"
            aria-label="generate Excel Automated Determination Data Form file"
            onClick={() => dispatch(generateWetlandExcelADF(selectedWetlandForms[0]))}
            variant="contained"
            startIcon={<FileDownloadIcon />}
          >
            Excel ADF
          </Button>
      </Box>} */}
    </Toolbar>
  );
}
