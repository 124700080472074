import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mapZoom: 6,
  basemapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  projectFilter: JSON.parse(localStorage.getItem('map_filter_project')) ?? null,
};

export const spatialSlice = createSlice({
  name: 'spatial',
  initialState,
  reducers: {
    setMapZoom: (state, action) => {
      state.mapZoom = action.payload;
    },
    setBasemapUrl: (state, action) => {
      state.basemapUrl = action.payload;
    },
    setProjectFilter: (state, action) => {
      // Payload is a project object (but with only essential attributes, not assigned users and such)
      state.projectFilter = action.payload;
      localStorage.setItem('map_filter_project', JSON.stringify(action.payload));
    },
  },
});

export const { setMapZoom, setBasemapUrl, setProjectFilter } = spatialSlice.actions;

export const selectMapZoom = (state) => state.spatial.mapZoom;
export const selectBasemapUrl = (state) => state.spatial.basemapUrl;
export const selectProjectFilter = (state) => state.spatial.projectFilter;
export const selectWetlandFormsWithProjectFilter = (state) => {
  if (state.wetlandForms.forms) {
    if (state.spatial.projectFilter) {
      return state.wetlandForms.forms.filter((form) => form.project === state.spatial.projectFilter.id);
    }
    return state.wetlandForms.forms;
  }
};

export default spatialSlice.reducer;