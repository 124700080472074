import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/react";
import userReducer from '../api/services/user/userSlice';
import wetlandFormsReducer from '../api/services/wetlandforms/wetlandFormsSlice';
import fileDownloadsReducer from '../api/services/filedownloads/fileDownloadsSlice';
import spatialReducer from '../api/services/spatial/spatialSlice';
import projectsReducer from '../api/services/projects/projectsSlice';
import settingsReducer from '../api/services/settings/settingsSlice';
import messagesReducer from '../api/services/messages/messagesSlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    user: userReducer,
    wetlandForms: wetlandFormsReducer,
    fileDownloads: fileDownloadsReducer,
    spatial: spatialReducer,
    projects: projectsReducer,
    settings: settingsReducer,
    messages: messagesReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['wetlandForms/generateWetlandPdf/fulfilled'],
      },
    }),
});