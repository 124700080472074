import axiosInstance from '../../auth';

class WetlandFormsApiService {
  getAll(queryParams) {
    return axiosInstance.get(`/api/wetland/${queryParams}`);
  }

  getAllByProject(project_id) {
    return axiosInstance.post(
      `/api/wetland/query_by_project/`,
      {project_id: project_id}
      );
  }

  get(id) {
    return axiosInstance.get(`/api/wetland/${id}/`);
  }

  post(data) {
    return axiosInstance.post(
      `/api/wetland/`,
      data,
    );
  }

  put(data) {
    return axiosInstance.put(
      `/api/wetland/${data.id}/`,
      data,
    );
  }

  getPhotos(id) {
    return axiosInstance.post(
      `/api/wetland-photo/from_wetland_id/`,
      {
        id: id,
      },
    );
  }

  uploadPhoto(data) {
    // FormData object uses .has() to check for a key's existance instead of the typical .hasOwnProperty()
    const method = data.has('id') ? 'put' : 'post';
    const id = data.has('id') ? data.get('id') : null;
    const url = id ? `/api/wetland-photo/${id}/` : `/api/wetland-photo/`;
    delete data.id;
    return axiosInstance({
      method: method,
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
        ...axiosInstance.defaults.headers,
      },
      timeout: 1000 * 60, // Want a very long timeout, defaults to 5000ms here typically
      data: data,
    });
  }

  updatePhotoMetadata(photo) {
    return axiosInstance({
      method: 'post',
      url: `/api/wetland-photo/${photo.id}/update_photo_metadata/`,
      data: {
        direction: photo.direction,
        latitude: photo.latitude,
        longitude: photo.longitude,
        note: photo.note,
        include_in_report: photo.include_in_report,
        photo_order_in_report: photo.photo_order_in_report,
      },
    });
  }

  removePhoto(id) {
    return axiosInstance.delete(
      `/api/wetland-photo/${id}/`,
    );
  }

  deleteMultiple(ids) {
    return axiosInstance.post(
      `/api/wetland/delete_multiple/`,
      {
        ids: ids,
      },
    );
  }

  generatePdf(id) {
    return axiosInstance.get(`/api/wetland/${id}/generate_pdf/`, {
      responseType: 'blob',
    });
  }

  generateExcel(ids) {
    return axiosInstance.post(`/api/wetland/generate_excel/`, 
    {
      ids: ids,
    },
    {
      responseType: 'blob',
    });
  }

  generateExcelADF(id) {
    return axiosInstance.get(`/api/wetland/${id}/generate_excel_adf/`, {
      responseType: 'blob',
    });
  }

  generateShapefile(ids) {
    return axiosInstance.post(`/api/wetland/generate_shapefile/`, 
    {
      ids: ids,
    },
    {
      responseType: 'blob',
    });
  }

  bulkUpdateAffectedDataCheck(data) {
    return axiosInstance({
      method: 'post',
      url: `/api/wetland/bulk_update_affected_data_check/`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...axiosInstance.defaults.headers,
      },
      timeout: 1000 * 60, // Want a very long timeout, defaults to 5000ms here typically
      data: data,
    });
  }
  
  bulkUpdateFromCsv(data) {
    return axiosInstance({
      method: 'post',
      url: `/api/wetland/bulk_update_from_csv/`,
      headers: {
        "Content-Type": "multipart/form-data",
        ...axiosInstance.defaults.headers,
      },
      timeout: 1000 * 60, // Want a very long timeout, defaults to 5000ms here typically
      data: data,
    });
  }
}

export default new WetlandFormsApiService();