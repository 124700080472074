import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWetlandForm, setAddCurrentWetlandFormProjectValues } from '../../../../api/services/wetlandforms/wetlandFormsSlice';
import { getProjects, selectProjects } from '../../../../api/services/projects/projectsSlice';

export default function ProjectSelectionAutocomplete() {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const projects = useSelector(selectProjects);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const loading = open && (projects?.length === 0 || projects === null);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        dispatch(getProjects()); // This sets the projects redux slice state, so no reason to keep that in local state in this component
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, dispatch]);

  const handleChangeSelectedProject = (newValue) => {
    // The presented field in the autocomplete is the project's name. Setting the project entails setting the "project" field to the project's UUID, 
    // the "projectData" field to all of the project info, and the project/site and applicant/owner fields to pre-filled, non-editable options (potentially)
    dispatch(setAddCurrentWetlandFormProjectValues(newValue));
  };

  return (
    <Autocomplete
      id="project-autocomplete"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={projects ?? []}
      loading={loading}
      value={currentForm?.projectData ?? null}
      onChange={(event, newValue) => {
        handleChangeSelectedProject(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selected Project"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
