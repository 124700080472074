import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

InfoDialogButton.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

// A simple dialog with a questionmark icon that shows variable content. Content should be a fragment with DialogContentTexts
export default function InfoDialogButton({ title, content }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton color="info" aria-label="show information about this item in a popup dialog" onClick={handleClickOpen}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent>
          { content }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
