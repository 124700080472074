import React from 'react';
import LeafletMap from '../../features/leafletMap/LeafletMap';
import { Container, Stack } from '@mui/material';
import RegionSelectBar from '../../features/regionSelectBar/RegionSelectBar';
import MapFilterBar from './components/MapFilterBar';

export default function MapPage() {
  return (
    <React.Fragment>
      <Stack direction={{ md: 'column', lg: 'row' }} spacing={0} alignItems="center">
        <RegionSelectBar />
        <MapFilterBar />
      </Stack>
      <Container sx={{ mt: 2 }}>
        <LeafletMap />
      </Container>
    </React.Fragment>
  )
}
