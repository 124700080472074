import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Popup } from "react-leaflet";
import { Button, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { format } from "date-fns";
import { formActionType } from "../../../utils/constants";
import { setWetlandFormActionType } from "../../../api/services/wetlandforms/wetlandFormsSlice";
import { downloadWetlandPhotos } from "../../../api/services/filedownloads/fileDownloadsSlice";
import GeneratePDFsButton from "../../../pages/Home/components/GeneratePDFsButton";

export default function WetlandFormPopup({form}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateToFormPage = (item) => {
    dispatch(setWetlandFormActionType(formActionType.EDIT));
    // The actual GET request is done in the form page itself, so that when navigating directly to that URL data is properly loaded.
    // Because of this, form ID is passed to the form page component using router state and used to query data there.
    // MUI DataGrid only contains the id field, so have to get the sample name for the matching form (based on id) before nagivating
    const sampleName = form.sampleName;
    navigate(`/wetland/${sampleName}`, { state: { actionType: formActionType.EDIT, formId: item.id } });
  };

  const handleDownloadPhotos = (e) => {
    e.preventDefault();
    dispatch(downloadWetlandPhotos({ id: form.id, sampleName: form.sampleName }));
  };

  return (
    <Popup>
      <Stack>
        <Typography component="span">{form.sampleName}</Typography>
        <Typography component="span">{format(new Date(form.dateTime), "MM/dd/yyyy")}</Typography>
        <Typography component="span">{form.user?.email ?? "user"}</Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }} spacing={1}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            onClick={() => handleNavigateToFormPage({ id: form.id })}
          >
            <EditIcon />
          </Button>
          <GeneratePDFsButton buttonText='' useSmallInfoVariant={true} />
          <Button variant="contained" color="info" size="small" onClick={handleDownloadPhotos}>
            <PhotoLibraryIcon />
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
}
