import React from 'react';
import { Typography } from "@mui/material";

const FormSectionHeader = ({text = "", mt = 4, mx = 4}) => {
  return (
    <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", mx: mx, mt: mt, textTransform: "capitalize" }}>
      {text}
    </Typography>
  )
}

export default FormSectionHeader;