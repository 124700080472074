import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bioApp.us/">
        BioApp
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function PrivacyPolicy(sx) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ my: 1 }}>
      <Link color="inherit" href="https://bioapp.us/privacy-policy/">Privacy Policy</Link>
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Copyright />
        <PrivacyPolicy />
      </Box>
    </Container>
  );
}
