import React, { useState, Fragment } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert
} from "@mui/material";
import axios from 'axios';

export default function ForgotPasswordDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [email, setEmail] = useState('');

  const changeEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  const [snackbarInfo, updateSnackbarInfo] = React.useState({
    showSnackbar: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    updateSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      message: message,
    });
  };


  const handleCloseSnackbar = () => {
    updateSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    let baseURL = 'http://0.0.0.0/';
    if (window.location.origin === "http://localhost:3000") {
      baseURL = 'http://127.0.0.1:8000/';
    } else {
      baseURL = window.location.origin;
    }
    const tempAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      }, 
    });
    tempAxiosInstance
			.post(`auth/users/reset_password/`, {
				email: email,
			})
			.then((res) => {
        // Show snackbar with either success or error message. Success code is 204, otherwise 400 bad request error.
        handleOpenSnackbar('success', 'Password reset email sent!');
			}).catch((res) => {
        handleOpenSnackbar('error', 'Encountered an error sending the password reset email.');
      });
    setOpen(false);
  };

  return (
    <Fragment>
      <Button variant="text" onClick={handleClickOpen}>
      Forgot password?
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A password reset email will be sent to the corresponding email address. If you do not see an email, please check your spam folder.
          </DialogContentText>
          <TextField
            autoFocus
            autoComplete="email"
            id="resetEmail"
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={changeEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleResetPassword}>Reset</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarInfo.showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarInfo.severity} sx={{ width: '100%' }}>
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
