export const soilIndicators = {
  'Unspecified': [],
  'AGCP': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular'},
    {symbol: 'A6', name: 'Organic Bodies', category: 'Regular', applicableLRRs: ['P', 'T', 'U']},
    {symbol: 'A7', name: 'S cm Mucky Mineral', category: 'Regular', applicableLRRs: ['P', 'T', 'U']},
    {symbol: 'A8', name: 'Muck Presence', category: 'Regular', applicableLRRs: ['U']},
    {symbol: 'A9', name: '1 cm Muck', category: 'Regular', applicableLRRs: ['P', 'T']},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'A16', name: 'Coast Prairie Redox', category: 'Regular', applicableMLRAs: ['150A']},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular', applicableLRRs: ['O', 'S']},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Regular', applicableLRRs: ['P', 'S', 'T', 'U']},
    {symbol: 'S8', name: 'Polyvalue Below Surface', category: 'Regular', applicableLRRs: ['S', 'T', 'U']},
    {symbol: 'S9', name: 'Thin Dark Surface', category: 'Regular', applicableLRRs: ['S', 'T', 'U']},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular', applicableLRRs: ['O']},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'F10', name: 'Marl', category: 'Regular', applicableLRRs: ['U']},
    {symbol: 'F11', name: 'Depleted Ochric', category: 'Regular', applicableMLRAs: ['151']},
    {symbol: 'F12', name: 'Iron-Manganese Masses', category: 'Regular', applicableLRRs: ['O', 'P', 'T']},
    {symbol: 'F13', name: 'Umbric Surface', category: 'Regular', applicableLRRs: ['P', 'T', 'U']},
    {symbol: 'F17', name: 'Delta Ochric', category: 'Regular', applicableMLRAs: ['151']},
    {symbol: 'F18', name: 'Reduced Vertic', category: 'Regular', applicableMLRAs: ['150A', '150B']},
    {symbol: 'F19', name: 'Piedmont Floodplain Soils', category: 'Regular', applicableMLRAs: ['149A']},
    {symbol: 'F20', name: 'Anomalous Bright Loamy Soils', category: 'Regular', applicableMLRAs: ['149A', '153C', '153D']},
    {symbol: 'A9', name: '1 cm Muck', category: 'Problem', applicableLRRs: ['O']},
    {symbol: 'A10', name: '2 cm Muck', category: 'Problem', applicableLRRs: ['S']},
    {symbol: 'F18', name: 'Reduced Vertic', category: 'Problem', excludedMLRAs: ['150A', '150B']},
    {symbol: 'F19', name: 'Piedmont Floodplain Soils', category: 'Problem', applicableLRRs: ['P', 'S', 'T']},
    {symbol: 'F20', name: 'Anomalous Bright Loamy Soils', category: 'Problem', applicableMLRAs: ['153B']},
    {symbol: 'TF2', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'AK': [
    {symbol: 'A1', name: 'Histosol or Histel', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'A13', name: 'Alaska Gleyed', category: 'Regular'},
    {symbol: 'A14', name: 'Alaska Redox', category: 'Regular'},
    {symbol: 'A15', name: 'Alaska Gleyed Pores', category: 'Regular'},
    {symbol: 'TA4', name: 'Alaska Color Change', category: 'Problem', note: 'Give details of color change in Remarks'},
    {symbol: 'TA5', name: 'Alaska Alpine Swales', category: 'Problem'},
    {symbol: 'NONE1', name: 'Alaska Redox With 2.5Y Hue', category: 'Problem'},
    {symbol: 'NONE2', name: 'Alaska Gleyed Without Hue 5Y or Redder Underlying Layer', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'AW': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular', applicableLRRs: ['C']},
    {symbol: 'A9', name: '1 cm Muck', category: 'Regular', applicableLRRs: ['D']},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular'},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'F9', name: 'Vernal Pools', category: 'Regular'},
    {symbol: 'A9', name: '1 cm Muck', category: 'Problem', applicableLRRs: ['C']},
    {symbol: 'A10', name: '2 cm Muck', category: 'Problem', applicableLRRs: ['B']},
    {symbol: 'F18', name: 'Reduced Vertic', category: 'Problem'},
    {symbol: 'TF2', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'CB': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A6', name: 'Organic Bodies', category: 'Regular'},
    {symbol: 'A7', name: 'S cm Mucky Mineral', category: 'Regular'},
    {symbol: 'A8', name: 'Muck Presence', category: 'Regular'},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Regular'},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Problem'},
    {symbol: 'F21', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'EMP': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular'},
    {symbol: 'A10', name: '2 cm Muck', category: 'Regular', note: 'LRR N', applicableLRRs: ['N']},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular', applicableLRRs: ['N'], applicableMLRAs: ['147', '148']},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Regular'},
    {symbol: 'S8', name: 'Polyvalue Below Surface', category: 'Regular', applicableMLRAs: ['147', '148']},
    {symbol: 'S9', name: 'Thin Dark Surface', category: 'Regular', applicableMLRAs: ['147', '148']},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'F12', name: 'Iron-Manganese Masses', category: 'Regular', applicableLRRs: ['N'], applicableMLRAs: ['136']},
    {symbol: 'F13', name: 'Umbric Surface', category: 'Regular', applicableMLRAs: ['136', '122']},
    {symbol: 'F19', name: 'Piedmont Floodplain Soils', category: 'Regular', applicableMLRAs: ['148']},
    {symbol: 'F21', name: 'Red Parent Material', category: 'Regular', applicableMLRAs: ['127', '147']},
    {symbol: 'A10', name: '2 cm Muck', category: 'Problem', applicableMLRAs: ['147']},
    {symbol: 'A16', name: 'Coast Prairie Redox', category: 'Problem', applicableMLRAs: ['147', '148']},
    {symbol: 'F19', name: 'Piedmont Floodplain Soils', category: 'Problem', applicableMLRAs: ['136', '147']},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'GP': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular', applicableLRRs: ['F']},
    {symbol: 'A9', name: '1 cm Muck', category: 'Regular', applicableLRRs: ['F', 'G', 'H']},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular'},
    {symbol: 'S2', name: '2.5 cm Mucky Peat or Peat', category: 'Regular', applicableLRRs: ['G', 'H']},
    {symbol: 'S3', name: '5 cm Mucky Peat or Peat', category: 'Regular', applicableLRRs: ['F']},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular'},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'F16', name: 'High Plains Depressions', category: 'Regular', applicableLRRs: ['H'], applicableMLRAs: ['72', '73'], note: 'in both'},
    {symbol: 'A9', name: '1 cm Muck', category: 'Problem', applicableLRRs: ['I', 'J']},
    {symbol: 'A16', name: 'Coast Prairie Redox', category: 'Problem', applicableLRRs: ['F', 'G', 'H']},
    {symbol: 'S7', name: 'Dark Surface', category: 'Problem', applicableLRRs: ['G']},
    {symbol: 'F16', name: 'High Plains Depressions', category: 'Problem', applicableLRRs: ['H'], excludedMLRAs: ['72', '73']},
    {symbol: 'F18', name: 'Reduced Vertic', category: 'Problem'},
    {symbol: 'TF2', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'HI': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A8', name: 'Much Presence', category: 'Regular'},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Regular'},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Problem'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Problem'},
    {symbol: 'F21', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'NCNE': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular'},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Regular', applicableLRRs: ['R'], applicableMLRAs: ['149B']},
    {symbol: 'S8', name: 'Polyvalue Below Surface', category: 'Regular', applicableLRRs: ['R'], applicableMLRAs: ['149B']},
    {symbol: 'S9', name: 'Thin Dark Surface', category: 'Regular', applicableLRRs: ['R'], applicableMLRAs: ['149B']},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular', applicableLRRs: ['K', 'L']},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'A10', name: '2 cm Muck', category: 'Problem', applicableLRRs: ['K', 'L'], applicableMLRAs: ['149B']},
    {symbol: 'A16', name: 'Coast Prairie Redox', category: 'Problem', applicableLRRs: ['K', 'L', 'R']},
    {symbol: 'S3', name: '5 cm Mucky Peat or Peat', category: 'Problem', applicableLRRs: ['K', 'L', 'R']},
    {symbol: 'S7', name: 'Dark Surface', category: 'Problem', applicableLRRs: ['K', 'L', 'M']},
    {symbol: 'S8', name: 'Polyvalue Below Surface', category: 'Problem', applicableLRRs: ['K', 'L']},
    {symbol: 'S9', name: 'Thin Dark Surface', category: 'Problem', applicableLRRs: ['K', 'L']},
    {symbol: 'F12', name: 'Iron-Manganese Masses', category: 'Problem', applicableLRRs: ['K', 'L', 'R']},
    {symbol: 'F19', name: 'Piedmont Floodplain Soils', category: 'Problem', applicableMLRAs: ['149B']},
    {symbol: 'F21', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TA6', name: 'Mesic Spodic', category: 'Problem', applicableMLRAs: ['144A', '145', '149B']},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'MW': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A5', name: 'Stratified Layers', category: 'Regular'},
    {symbol: 'A10', name: '2 cm Muck', category: 'Regular'},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular'},
    {symbol: 'S3', name: '5 cm Mucky Peat or Peat', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular'},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'A16', name: 'Coast Prairie Redox', category: 'Problem'},
    {symbol: 'S7', name: 'Dark Surface', category: 'Problem'},
    {symbol: 'F12', name: 'Iron-Manganese Masses', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
  'WMVC': [
    {symbol: 'A1', name: 'Histosol', category: 'Regular'},
    {symbol: 'A2', name: 'Histic Epipedon', category: 'Regular'},
    {symbol: 'A3', name: 'Black Histic', category: 'Regular'},
    {symbol: 'A4', name: 'Hydrogen Sulfide', category: 'Regular'},
    {symbol: 'A11', name: 'Depleted Below Dark Surface', category: 'Regular'},
    {symbol: 'A12', name: 'Thick Dark Surface', category: 'Regular'},
    {symbol: 'S1', name: 'Sandy Mucky Mineral', category: 'Regular'},
    {symbol: 'S4', name: 'Sandy Gleyed Matrix', category: 'Regular'},
    {symbol: 'S5', name: 'Sandy Redox', category: 'Regular'},
    {symbol: 'S6', name: 'Stripped Matrix', category: 'Regular'},
    {symbol: 'F1', name: 'Loamy Mucky Mineral', category: 'Regular', excludedMLRAs: ['1']},
    {symbol: 'F2', name: 'Loamy Gleyed Matrix', category: 'Regular'},
    {symbol: 'F3', name: 'Depleted Matrix', category: 'Regular'},
    {symbol: 'F6', name: 'Redox Dark Surface', category: 'Regular'},
    {symbol: 'F7', name: 'Depleted Dark Surface', category: 'Regular'},
    {symbol: 'F8', name: 'Redox Depressions', category: 'Regular'},
    {symbol: 'A10', name: '2 cm Muck', category: 'Problem'},
    {symbol: 'TF2', name: 'Red Parent Material', category: 'Problem'},
    {symbol: 'TF12', name: 'Very Shallow Dark Surface', category: 'Problem'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Problem'},
  ],
};