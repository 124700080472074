import { Alert, Button, IconButton, Portal, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  attemptPromoteOrDemoteUser,
  getUserMembership,
  getUserOrganization,
  listOrganizationMembers,
  respondToOrganizationInvite,
  selectUser,
  setAccessibleOrgMembers,
  setOrganization,
  setOrgMembership,
  userLeaveOrganization,
} from "../../../api/services/user/userSlice";

export function refreshUserMembership(dispatch, handleOpenSnackbar) {
  dispatch(listOrganizationMembers()).then((res) => {
    if (res.payload?.status === 200) {
      dispatch(setAccessibleOrgMembers(res.payload.data));
    } else {
      handleOpenSnackbar("error", `Error loading organization members`);
    }
  });
}

export default function OrganizationMemberItemSecondaryActions({ userItem }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [snackbarInfo, updateSnackbarInfo] = useState({
    showSnackbar: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    updateSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    updateSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  const handleRespondToInvite = (event, userResponse) => {
    event.preventDefault();
    dispatch(respondToOrganizationInvite(userResponse)).then((inviteRes) => {
      if (inviteRes.payload.status === 204) {
        handleOpenSnackbar("success", `Accepted organization invite`);
      } else {
        handleOpenSnackbar("error", `Rejected organization invite`);
      }
      // No matter the result, re-fetch the user organization info and users list, as well as membership status.
      dispatch(getUserOrganization()).then((res) => {
        // console.log(res.payload);
        if (res.payload.status === 204) {
          // Response code if no organization associated with the user
          dispatch(setOrganization(null));
        } else if (res.payload.status === 200) {
          dispatch(setOrganization(res.payload.data));
        } else {
          handleOpenSnackbar("error", `Error loading user organization`);
        }
      });
      dispatch(listOrganizationMembers()).then((res) => {
        // console.log(res.payload);
        if (res.payload?.status === 200) {
          dispatch(setAccessibleOrgMembers(res.payload.data));
        } else {
          handleOpenSnackbar("error", `Error loading organization members`);
        }
      });
      dispatch(getUserMembership()).then((res) => {
        if (res.payload.status === 200) {
          dispatch(setOrgMembership(res.payload.data));
        } else if (res.payload.status !== 204) {
          // 204 no content response is not an error, but no organization membership data to display
          handleOpenSnackbar("error", `Error loading organization membership data`);
        }
      });
    });
  };

  const handlePromoteOrDemoteUser = (event, userItem) => {
    event.preventDefault();
    // If the user is a regular user, promote them. Otherwise, demote them to regular user. This option is only available to org managers.
    dispatch(attemptPromoteOrDemoteUser(userItem)).then((res) => {
      if (res.payload?.status === 200) {
        handleOpenSnackbar(
          "success",
          `Changed the status of user ${userItem.email} within the organization.`
        );
        refreshUserMembership(dispatch, handleOpenSnackbar);
      } else {
        handleOpenSnackbar("error", `Error changing user status in organization.`);
      }
    });
  };

  const handleAttemptRemoveFromOrganization = (event, email) => {
    event.preventDefault();
    // If the user is attempting to remove themself from the organization, show confirm popup and ensure they want to leave, and know that this will delete their org if they are the last remaining org manager.

    dispatch(userLeaveOrganization(email)).then((res) => {
      // If the request was successful, update the list of accessible users, as well as the organization info itself.
      if (res.payload?.status === 200) {
        dispatch(setAccessibleOrgMembers(res.payload.data));
        handleOpenSnackbar(
          "success",
          `Removed user with email address ${email} from the organization.`
        );
        refreshUserMembership(dispatch, handleOpenSnackbar);
      } else {
        handleOpenSnackbar("error", `Error removing user from organization`);
      }
    });
  };

  return (
    <Fragment>
      {/* Portal is used to allow the snackbar to display properly in the bottom left of the screen, instead of beside the button itself. */}
      <Portal>
        <Snackbar open={snackbarInfo.showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarInfo.severity} variant="filled" sx={{ width: "100%" }}>
            {snackbarInfo.message}
          </Alert>
        </Snackbar>
      </Portal>
      <Stack direction="row" spacing={1}>
        {userItem.org_invite_status === "requestedJoin" && (
          <Fragment>
            <Typography primary="Requested to join organization" />
            <Button
              variant="contained"
              color="success"
              sx={{ mx: 2 }}
              onClick={(e) => handleRespondToInvite(e, { response: "approved", email: userItem.email })}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ mx: 2 }}
              onClick={(e) => handleRespondToInvite(e, { response: "rejected", email: userItem.email })}
            >
              Reject
            </Button>
          </Fragment>
        )}
        {userItem.org_invite_status === "approved" && userItem.email !== user?.email && user?.email !== null && (
          <Fragment>
            {userItem.org_user_role === "regular" ? (
              <Tooltip title={`Promote ${userItem.full_name} to Organization Manager`}>
                <IconButton
                  aria-label="promote user to organization manager"
                  color="info"
                  onClick={(e) => handlePromoteOrDemoteUser(e, userItem)}
                >
                  <AddModeratorIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={`Demote ${userItem.full_name} to Standard User`}>
                <IconButton
                  aria-label="demote user to standard user"
                  color="warning"
                  onClick={(e) => handlePromoteOrDemoteUser(e, userItem)}
                >
                  <RemoveModeratorIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={`Remove ${userItem.full_name} from Organization`}>
              <IconButton
                aria-label="remove user from organization"
                color="error"
                onClick={(e) => handleAttemptRemoveFromOrganization(e, userItem?.email)}
              >
                <PersonRemoveIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </Stack>
    </Fragment>
  );
}
