import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#557a36',
    },
    secondary: {
      main: '#1a80b6',
    },
    background: {
      default: '#f7f2ed',
    },
    text: {
      primary: '#4A443F',
      secondary: '#9E9790',
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#D32F2F',
    },
    divider: '#9E9790',
    error: {
      main: '#D32F2F',
    },
    success: {
      main: '#2e7d32',
    },
  },
  typography: {
    fontFamily: [
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});