import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormSectionHeader from "../../../../components/FormSectionHeader";
import {
  addWetlandPlantToStratum,
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
  selectCurrentWetlandFormPlants,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import {
  VegStratum,
  vegStratumToAbbrevString,
  vegStratumToString,
  vegStratumToStringCapitalized,
} from "../../../../utils/formConstants";
import PlantEntry from "./PlantEntry";

export default function StratumSection({ stratum = VegStratum.Tree, plantsList = [] }) {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const plants = useSelector(selectCurrentWetlandFormPlants);
  const dispatch = useDispatch();
  const plotSizeName = `${vegStratumToString(stratum)}PlotSize`;

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };
  
  const handleAddPlant = (e) => {
    e.preventDefault();
    dispatch(addWetlandPlantToStratum(stratum));
  };

  return (
    <Grid container spacing={2} columnSpacing={4}>
      <Grid container item spacing={4}>
        <Grid item xs={10}>
          <FormSectionHeader text={`${vegStratumToString(stratum)} Stratum`} />
        </Grid>
        <Grid item xs={2} alignSelf="end">
          <TextField
            id={plotSizeName}
            label={`${vegStratumToStringCapitalized(stratum)} Plot Size`}
            value={(currentForm !== null) ? currentForm[plotSizeName] ?? "" : ""}
            onChange={handleStandardFormValueChange}
            size="small"
          />
        </Grid>
      </Grid>
      {plants?.map((plant, index) => {
        if (plant.strata === vegStratumToAbbrevString(stratum)) {
          return <PlantEntry key={`strata-plant-entry-${index}`} stratum={stratum} plant={plant} index={index} options={plantsList} />;
        } else {
          return null;
        }
      })}
      <Grid item justify="center">
        <Button variant="contained" color="info" onClick={handleAddPlant}>
          <AddIcon />
          <Typography mx={1}>Plant</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
