import React from "react";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Button } from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import { useDispatch } from "react-redux";
import { addCurrentWetlandPhotoItem } from "../../../../api/services/wetlandforms/wetlandFormsSlice";

const Input = styled("input")({
  display: "none",
});

export default function AddPhotoCard() {
  const dispatch = useDispatch();

  const handleSelectNewImage = (e) => {
    e.preventDefault();
    // Added photo upload will only trigger when the user saves the form
    if (e.target.files[0]) {
      dispatch(addCurrentWetlandPhotoItem({ image: URL.createObjectURL(e.target.files[0]) }));
    }
  };

  return (
    <Card key={`add-photo-card`} raised>
      <CardContent sx={{ height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <label htmlFor={`upload-new-image`}>
            <Input accept="image/*" id={`upload-new-image`} type="file" hidden onChange={handleSelectNewImage} />
            <Button
              component="span"
              aria-label={`upload new photo`}
              startIcon={<PhotoIcon />}
              size="large"
              sx={{ mt: 2, mb: 2 }}
            >
              Upload Photo
            </Button>
          </label>
        </div>
      </CardContent>
    </Card>
  );
}
