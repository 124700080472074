import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { hydroIndDescrips } from "../../../resources/hydroIndDescrips";
import { soilIndDescrips } from "../../../resources/soilIndDescrips";

function getIndicatorDescription({ indicatorType = "Hydrology", aceRegion = "NCNE", indicator = "A1" }) {
  if (indicatorType === "Hydrology") {
    return hydroIndDescrips[aceRegion][indicator];
  } else {
    return soilIndDescrips[aceRegion][indicator];
  }
}

export default function IndicatorDescripButtonAndDialog(props) {
  const { indicatorType, aceRegion, indicator, handleShow, showAsModalDialog = false } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [indDescrip, setIndDescrip] = useState(null);

  const handleOpenIndicatorDescriptionPopup = (e, indicator) => {
    const indicatorDescription = getIndicatorDescription({
      indicatorType: indicatorType,
      aceRegion: aceRegion,
      indicator: indicator.symbol,
    });
    setIndDescrip(indicatorDescription);
    setOpen(true);
  };

  return (
    <Fragment>
      <Button 
        variant="text" 
        size="small" 
        color="secondary" 
        sx={{ textTransform: "none" }} 
        onClick={showAsModalDialog ? (event) => {handleOpenIndicatorDescriptionPopup(event, indicator)} : (event) => {handleShow(indicator)}}
        // onClick={(event) => handleOpenIndicatorDescriptionPopup(event, indicator)}
      >
        <Typography display="inline">{indicator.symbol}:&nbsp;</Typography>
        <Typography display="inline">{indicator.name}</Typography>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {indicator.symbol}: {indicator.name}
        </DialogTitle>
        <DialogContent>
          {indDescrip && indDescrip?.description && (
            <Box my={2}>
              <DialogContentText color="text.primary" fontWeight="bold">Description</DialogContentText>
              <DialogContentText color="text.primary">{indDescrip?.description}</DialogContentText>
            </Box>
          )}
          {indDescrip && indDescrip?.cautionsAndNotes && (
            <Box my={2}>
              <DialogContentText color="text.primary" fontWeight="bold">Cautions and User Notes</DialogContentText>
              <DialogContentText color="text.primary">{indDescrip?.cautionsAndNotes}</DialogContentText>
            </Box>
          )}
          {indDescrip && indDescrip?.applicable && <Box my={2}>
              <DialogContentText color="text.primary" fontWeight="bold">Applicable Subregions</DialogContentText>
              <DialogContentText color="text.primary">{indDescrip?.applicable}</DialogContentText>
            </Box>}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
