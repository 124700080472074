import React from "react";
import { Divider, Stack, InputLabel, MenuItem, FormControl, Select, InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from "@mui/material/styles";
import {
  selectProjectsFilterText,
  selectProjectsSortBy,
  selectProjectsSortOrder,
  setProjectsSorting,
  setProjectsFilterText,
} from "../../api/services/projects/projectsSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateProjectDialog from "./CreateProjectDialog";
import ProjectAccessInfoPopup from "./ProjectAccessInfoPopup";


const FormControlOutlined = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "showColorOutline"
})(({ showColorOutline, theme }) => ({
  backgroundColor: 'white',
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: showColorOutline === true ? theme.palette.primary.main : undefined,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: showColorOutline === true ? theme.palette.primary.main : undefined,
    },
    '&:hover fieldset': {
      borderColor: showColorOutline === true ? theme.palette.primary.main : undefined,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function ProjectsSortAndAddBar() {
  const dispatch = useDispatch();
  const sortOrder = useSelector(selectProjectsSortOrder);
  const sortBy = useSelector(selectProjectsSortBy);
  const sortFilterText = useSelector(selectProjectsFilterText);

  const handleChangeProjectSortBy = (event) => {
    dispatch(setProjectsSorting({ sort_by: event.target.value, sort_order: sortOrder }));
    localStorage.setItem("projects_sort_by", JSON.stringify(event.target.value));
  };

  const handleChangeProjectSortOrder = (event) => {
    dispatch(setProjectsSorting({ sort_by: sortBy, sort_order: event.target.value }));
    localStorage.setItem("projects_sort_order", JSON.stringify(event.target.value));
  };

  const changeProjectFilterText = (event) => {
    if (event.target.value !== undefined) {
      dispatch(setProjectsFilterText(event.target.value));
      localStorage.setItem("projects_filter_text", JSON.stringify(event.target.value));
    }
  };

  const resetProjectFilterText = (event) => {
    dispatch(setProjectsFilterText(""));
    localStorage.setItem("projects_filter_text", JSON.stringify(""));
  };

  return (
    <Stack direction="row" spacing={2} pb={2} alignItems="center">
      <FormControl fullWidth size="small" sx={{ minWidth: "160px" }}>
        <InputLabel id="sort-select-by-label">Sort By</InputLabel>
        <Select
          labelId="sort-select-by-label"
          id="sort-select-by"
          value={sortBy}
          label="Sort By"
          onChange={handleChangeProjectSortBy}
          sx={{ backgroundColor: 'white' }}
        >
          <MenuItem value={"name"}>Name</MenuItem>
          <MenuItem value={"created_date"}>Date Created</MenuItem>
          <MenuItem value={"completion_date"}>Date Completed</MenuItem>
          <MenuItem value={"status"}>Status</MenuItem>
          <MenuItem value={"project_number"}>Project Number</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth size="small" sx={{ minWidth: "160px" }}>
        <InputLabel id="sort-select-order-label">Sort Order</InputLabel>
        <Select
          labelId="sort-select-order-label"
          id="sort-select-order"
          value={sortOrder}
          label="Sorting Order"
          onChange={handleChangeProjectSortOrder}
          sx={{ backgroundColor: 'white' }}
        >
          <MenuItem value={"ASC"}>Ascending</MenuItem>
          <MenuItem value={"DESC"}>Descending</MenuItem>
        </Select>
      </FormControl>
      <FormControlOutlined fullWidth size="small" showColorOutline={sortFilterText !== ""}>
        <InputLabel htmlFor="project-filter-text-field">Filter</InputLabel>
          <OutlinedInput
            id="project-filter-text-field"
            name="project_filter"
            label="Filter"
            value={sortFilterText}
            onChange={changeProjectFilterText}
            endAdornment={
              sortFilterText !== "" && <InputAdornment position="end">
                <IconButton
                  aria-label="clear project list filter text"
                  onClick={resetProjectFilterText}
                  edge="end"
                >
                  <ClearIcon color="error" />
                </IconButton>
              </InputAdornment>
            }
          />
      </FormControlOutlined>
      <Divider orientation="vertical" variant="middle" flexItem />
      <CreateProjectDialog />
      <ProjectAccessInfoPopup />
    </Stack>
  );
}
