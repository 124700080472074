function isMatrixColorComplete(matrixItem) {
  // If both matrix value and chroma are selected (not null), return true;
  if (matrixItem.matrixValue != null && matrixItem.matrixChroma != null) return true;
  return false;
}

function isRedoxColorComplete(redoxItem) {
  if (redoxItem.redoxValue != null && redoxItem.redoxChroma != null) return true;
  return false;
}

export function getRedoxContrast(matrixItem, redoxItem) {
  // Given two different soil color items, calculate and return the redox contrast value.
  // Definitions are found in the Glossary of the NRCS Field Indicators of Hydric Soils booklet/PDF. Note that sometimes they seem to mess up using < and <= as well as > and >=, so consult the chart
  // Moving 2.5 units in hue is a delta hue of 1 (ex. 5R to 7.5R is a delta hue of 1, 10YR to 5YR is a delta hue of 2).
  // Faint: The contrast is faint if:
  //   1. Delta hue = 0, then delta value ≤2 and delta chroma ≤1, or
  //   2. Delta hue = 1, then delta value ≤1 and delta chroma ≤1, or
  //   3. Delta hue = 2, then delta value = 0 and delta chroma = 0, or
  //   Any delta hue if both colors have value ≤3 and chroma ≤2.
  // Distinct: Readily seen but contrasting only moderately with the color to which compared. The contrast is distinct if:
  //   1. Delta hue = 0, then a) Delta value <2 and delta chroma >1 to <4, or b) Delta value >2 to <4 and delta chroma <4.
  //   2. Delta hue = 1, then a) Delta value <1 and delta chroma >1 to <3, or b) Delta value >1 to <3 and delta chroma <3.
  //   3. Delta hue = 2, then a) Delta value = 0 and delta chroma >0 to <2, or b) Delta value >0 to <2 and delta chroma <2.
  //   Regardless of the magnitude of hue difference, where both colors have value <3 and chroma <2, the contrast is faint.
  // Prominent: Any contrast stronger than distinct is prominent.
  const _matrixHue = matrixItem.matrixHue;
  const _redoxHue = redoxItem.redoxHue;
  // Indexing the list allows for a makeshift "hue wheel" as seen in the NRCS booklet.
  const huesWheel = ['5R', '7.5R', '10R', '2.5YR', '5YR', '7.5YR', '10YR', '2.5Y', '5Y',
    '', '1OY', '', '5GY', '', '10GY', '', '5G', '', '10G', '', '5BG', '', '10BG', '', '5B', '',
    '10B', '', '5PB', '', '', '', '5P', '', '', '', '5RP', '', '', ''];
  if (_matrixHue === '' || _redoxHue === '' || !isMatrixColorComplete(matrixItem) || !isRedoxColorComplete(redoxItem)) {
    return 'None';
  }
  // Past this point, we are sure that matrix and redox both have non-null values and chromas
  const deltaHue = Math.abs(huesWheel.indexOf(_matrixHue) - huesWheel.indexOf(_redoxHue));
  const matrixValue = Math.floor(matrixItem.matrixValue);
  const redoxValue = Math.floor(redoxItem.redoxValue);  // Values can sometimes be 2.5 (decimal, not int), which we want to round down to 2 for the delta calculation values so that it is still one 'step' apart
  const deltaValue = Math.abs(matrixValue - redoxValue);
  const matrixChroma = matrixItem.matrixChroma;
  const redoxChroma = redoxItem.redoxChroma;
  const deltaChroma = Math.abs(matrixChroma - redoxChroma);
  // First, do checks that are regardless of delta hue
  if (matrixValue <= 3 && redoxValue <= 3 && matrixChroma <= 2 && redoxChroma <= 2) {
    return 'Faint';
  }
  if (deltaHue === 0) {
    if (deltaValue <= 2 && deltaChroma <= 1) {
      return 'Faint';
    } else if ((deltaValue <= 2 && deltaChroma > 1 && deltaChroma < 4) || (deltaValue > 2 && deltaValue < 4 && deltaChroma < 4)) {
      return 'Distinct';
    } else {
      return 'Prominent';
    }
  } else if (deltaHue === 1) {
    if (deltaValue <= 1 && deltaChroma <= 1) {
      return 'Faint';
    } else if ((deltaValue <= 1 && deltaChroma > 1 && deltaChroma < 3) || (deltaValue > 1 && deltaValue < 3 && deltaChroma < 3)) {
      return 'Distinct';
    } else {
      return 'Prominent';
    }
  } else if (deltaHue === 2) {
    if (deltaValue === 0 && deltaChroma === 0) {
      return 'Faint';
    } else if ((deltaValue === 0 && deltaChroma > 0 && deltaChroma < 2) || (deltaValue > 0 && deltaValue < 2 && deltaChroma < 2)) {
      return 'Distinct';
    } else {
      return 'Prominent';
    }
  } else {
    return 'Prominent';
  }
}