import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, InputAdornment } from "@mui/material";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import FormSectionHeader from "../../../../components/FormSectionHeader";

export default function RestrictiveLayer() {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const dispatch = useDispatch();

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  return (
    <Grid container spacing={2} columnSpacing={4} sx={{ mt: 2 }}>
      <Grid container item spacing={4}>
        <FormSectionHeader text={`Restrictive Layer`} />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          id="restrictiveDepth"
          label="Depth"
          type="number"
          value={currentForm?.restrictiveDepth ?? ""}
          onChange={handleStandardFormValueChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">in</InputAdornment>,
          }}
          sx={{ width: '10ch' }}
        />
      </Grid>
      <Grid item xs={6} sm={9}>
        <TextField
          id="restrictiveType"
          label="Type"
          value={currentForm?.restrictiveType ?? ""}
          onChange={handleStandardFormValueChange}
        />
      </Grid>
    </Grid>
  );
}
