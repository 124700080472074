import React from "react";
import { Stack, FormGroup, FormControlLabel, Switch } from "@mui/material";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function SummaryIsWetland() {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const dispatch = useDispatch();

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.name, value: e.target.checked }));
  };

  return (
    <Stack spacing={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="wetlandVegSummary"
              name="wetlandVeg"
              checked={currentForm?.wetlandVeg ?? false}
              onChange={handleSwitchInputFormValueChange}
            />
          }
          label="Wetland Vegetation"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="wetlandHydroSummary"
              name="wetlandHydro"
              checked={currentForm?.wetlandHydro ?? false}
              onChange={handleSwitchInputFormValueChange}
            />
          }
          label="Wetland Hydrology"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="wetlandSoilsSummary"
              name="wetlandSoils"
              checked={currentForm?.wetlandSoils ?? false}
              onChange={handleSwitchInputFormValueChange}
            />
          }
          label="Wetland Soils"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="isWetland"
              name="isWetland"
              checked={currentForm?.isWetland ?? false}
              onChange={handleSwitchInputFormValueChange}
            />
          }
          label="Sample Point is a Wetland"
        />
      </FormGroup>
    </Stack>
  );
}
