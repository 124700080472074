export const soilHues = [
  '',
  'WHITE',
  'GLEY2',
  'GLEY1',
  '10YR',
  '7.5YR',
  '5YR',
  '2.5YR',
  '10R',
  '7.5R',
  '5R',
  '5G',
  '10GY',
  '5GY',
  '10Y',
  '5Y',
  '2.5Y',
];