import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Switch, useTheme, Stack } from "@mui/material";
import FormSectionHeader from "../../components/FormSectionHeader";
import {
  selectCurrentWetlandFormRegion,
  selectIndicatorListItems,
  toggleWetlandIndicator,
} from "../../api/services/wetlandforms/wetlandFormsSlice";
import { hydroIndicators } from "../../resources/hydroIndicators";
import { soilIndicators } from "../../resources/soilIndicators";
import IndicatorDescripButtonAndDialog from "./components/IndicatorDescripButtonAndDialog";

function getIndicatorsFromTypeAndCategory({
  aceRegion = "NCNE",
  indicatorType = "Hydrology",
  indicatorCategory = "Primary",
}) {
  if (indicatorType === "Hydrology") {
    return hydroIndicators[aceRegion].filter((item) => item.category === indicatorCategory);
  } else {
    return soilIndicators[aceRegion].filter((item) => item.category === indicatorCategory);
  }
}

// Used for hydrology and soil primary/secondary indicators
export default function IndicatorList({ indicatorType = "Hydrology", indicatorCategory = "Primary", handleShow, showAsModalDialog = false }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentFormRegion = useSelector(selectCurrentWetlandFormRegion);
  const selectedIndicators = useSelector(selectIndicatorListItems);
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    setIndicators(
      getIndicatorsFromTypeAndCategory({
        aceRegion: currentFormRegion,
        indicatorType: indicatorType,
        indicatorCategory: indicatorCategory,
      })
    );
  }, [currentFormRegion, indicatorCategory, indicatorType]);

  const handleToggleIndicator = (e, indicator) => {
    dispatch(toggleWetlandIndicator({ indicatorType: indicatorType, indicator: indicator }));
  };

  return (
    <Grid container spacing={2} columnSpacing={4} sx={{ mt: 2 }}>
      <Grid container item spacing={4}>
        <FormSectionHeader text={`${indicatorCategory} ${indicatorType} Indicators`} />
      </Grid>
      {currentFormRegion === 'Unspecified' && <Typography display="inline" sx={{ color: theme.palette.text.secondary, mx: 4, my: 2 }}>Select an ACE Region to load hydrology/soil indicator lists.</Typography>}
      {indicators.map((indicator) => (
        <Grid key={`${indicatorType}-indicators-${indicatorCategory}-${indicator.symbol}`} item xs={12} sm={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              checked={
                selectedIndicators.filter(
                  (item) => item.category === indicator.category && item.symbol === indicator.symbol
                ).length > 0
              }
              onChange={(event) => handleToggleIndicator(event, indicator)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <IndicatorDescripButtonAndDialog 
            indicatorType={indicatorType} 
            aceRegion={currentFormRegion} 
            indicator={indicator} 
            handleShow={handleShow} 
            showAsModalDialog={showAsModalDialog}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
