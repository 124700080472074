import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileDownloadsApiService from "./fileDownloadsAPI";
import { addWarningMessage } from "../messages/messagesSlice";
import { format } from "date-fns";


export const downloadStatus = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
};

const initialState = {
  pdfsDownloadStatus: downloadStatus.IDLE,
  shouldCancelPdfsDownload: false,
  photosDownloadStatus: downloadStatus.IDLE,
  shouldCancelPhotosDownload: false,
};


export const generateWetlandPdf = createAsyncThunk("fileDownloads/generateWetlandPdf", async ({ id, sampleName, flattenOutput=false, include_photos=false }, thunkAPI) => {
  if (id) {
    // Pull in relevant settings if necessary
    const useStampedPhotosOverlay = thunkAPI.getState().settings.useStampedOverlayInPdfPhotos;
    const response = await FileDownloadsApiService.generatePdfWithParameters(id, {flatten_output: flattenOutput, include_photos: include_photos, use_stamped_photos_overlay: useStampedPhotosOverlay}).catch((error) => {
      console.log('error generating PDF');
      return response.status;
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var sanitize = require("sanitize-filename");
    const safeSampleName = sanitize(sampleName);
    link.setAttribute("download", `${safeSampleName}.pdf`);
    document.body.appendChild(link);
    link.click();
    return response.status;
  }
});


function imageTypeFromAwsUrl(url) {
  if (url.includes('.png')) {
    return 'png';
  } else if (url.includes('.jpg')) {
    return 'jpg';
  } else if (url.includes('.jpeg')) {
    return 'jpeg';
  } else if (url.includes('.tiff')) {
    return 'tiff';
  } else if (url.includes('.PNG')) {
    return 'PNG';
  } else if (url.includes('.JPG')) {
    return 'JPG';
  } else if (url.includes('.JPEG')) {
    return 'JPEG';
  }
}

export const downloadWetlandPhotos = createAsyncThunk("fileDownloads/downloadWetlandPhotos", async ({ id, sampleName }) => {
  // id is the wetland id. Get the photo info from the wetland id using the endpoint created to do this, then download all picture links (faster than waiting for the server to zip and send everything).
  if (id) {
    const response = await FileDownloadsApiService.fetchWetlandPhotos(id).catch((error) => {
      console.log('error generating photos');
      return response.status;
    });
    if (response.status === 200) {
      if (response.data.length > 0) {
        response.data.forEach((photo, index) => {
          const url = window.URL.createObjectURL(new Blob([photo.image]));
          const link = document.createElement("a");
          link.href = url;
          const imageFileType = imageTypeFromAwsUrl(photo.image);  // The signed AWS URL can be tricky to parse the file extension from
          var sanitize = require("sanitize-filename");
          const safeSampleName = sanitize(sampleName);
          link.setAttribute("download", `${safeSampleName}_${index}_${photo.direction}.${imageFileType ?? "jpg"}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }

    return response.status;
  }
});


export const generateWetlandPdfsFromSelection = createAsyncThunk("fileDownloads/generateWetlandPdfsFromSelection", async ({ ids, flattenOutput=false, include_photos=false }, thunkAPI) => {
  if (ids.length > 0) {
    thunkAPI.dispatch(setDownloadPdfsLoading());
    // File downloads are broken up into chunks of 30 at a time, to prevent from each response taking too long
    const sectionSize = 30;
    const numBatches = Math.ceil(ids.length / sectionSize);
    let currBatchNumber = 1;
    let filename = `USACE data forms.zip`;
    for (let i = 0; i < ids.length; i += sectionSize) {
      // Each time, check if user wants to cancel the download and break if so
      const shouldCancel = thunkAPI.getState().fileDownloads.shouldCancelPdfsDownload;
      if (shouldCancel) {
        thunkAPI.dispatch(setShouldCancelPdfsDownload(false));
        break;
      }
      let subset = ids.slice(i, i + sectionSize);
      try {
        const response = await FileDownloadsApiService.generatePdfsFromIds(subset, {flatten_output: flattenOutput, include_photos: include_photos});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        if (numBatches > 1) {
          filename = `USACE data forms (part ${currBatchNumber} of ${numBatches}).zip`;
        }
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        // Since the responseType is blob, need to decode that to read the JSON error message
        const errorJson = JSON.parse(await e.response.data.text());
        thunkAPI.dispatch(addWarningMessage(errorJson));
        if (currBatchNumber === numBatches) {
          thunkAPI.dispatch(setDownloadPhotosComplete());
        }
      }
      currBatchNumber += 1;
    }
    thunkAPI.dispatch(setDownloadPdfsComplete());

    // const response = await FileDownloadsApiService.generatePdfsFromIds(ids, {flatten_output: flattenOutput, include_photos: include_photos}).catch(async (error) => {
    //   // Since the responseType is blob, need to decode that to read the JSON error message
    //   const errorJson = JSON.parse(await error.response.data.text());
    //   thunkAPI.dispatch(addWarningMessage(errorJson));
    //   thunkAPI.dispatch(setDownloadPdfsComplete());
    // });
    // if (response.status === 500) return response.status;
    // thunkAPI.dispatch(setDownloadPdfsComplete());
    // const url = window.URL.createObjectURL(new Blob([response.data]));
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `pdfs_download.zip`);
    // document.body.appendChild(link);
    // link.click();
    // return response.status;
  }
});

export const generateWetlandPhotosFromSelection = createAsyncThunk("fileDownloads/generateWetlandPhotosFromSelection", async (requestData, thunkAPI) => {
  const ids = requestData.ids;
  const useOverlay = requestData.useOverlay;
  if (ids.length > 0) {
    thunkAPI.dispatch(setDownloadPhotosLoading());
    // File downloads are broken up into chunks of 30 at a time, to prevent from each response taking too long
    const sectionSize = 30;
    const numBatches = Math.ceil(ids.length / sectionSize);
    let currBatchNumber = 1;
    let filename = `data form photos.zip`;
    for (let i = 0; i < ids.length; i += sectionSize) {
      // Each time, check if user wants to cancel the download and break if so
      const shouldCancel = thunkAPI.getState().fileDownloads.shouldCancelPhotosDownload;
      if (shouldCancel) {
        thunkAPI.dispatch(setShouldCancelPhotosDownload(false));
        break;
      }
      let subset = ids.slice(i, i + sectionSize);
      try {
        const response = await FileDownloadsApiService.generatePhotosFromIds(subset, useOverlay);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        if (numBatches > 1) {
          filename = `data form photos (part ${currBatchNumber} of ${numBatches}).zip`;
        }
      } catch (e) {
        thunkAPI.dispatch(addWarningMessage(`Failed to generate a photo download zip file`));
        if (currBatchNumber === numBatches) {
          thunkAPI.dispatch(setDownloadPhotosComplete());
        }
      }
      currBatchNumber += 1;
    }
    thunkAPI.dispatch(setDownloadPhotosComplete());
  }


  // if (ids.length > 0) {
  //   thunkAPI.dispatch(setDownloadPhotosLoading());
  //   const response = await FileDownloadsApiService.generatePhotosFromIds(ids, useOverlay).catch((error) => {
  //     thunkAPI.dispatch(setDownloadPhotosComplete());
  //   });
  //   thunkAPI.dispatch(setDownloadPhotosComplete());
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", `photos_download.zip`);
  //   document.body.appendChild(link);
  //   link.click();
  //   return response.status;
  // }
});

export const generateJsonFromSelection = createAsyncThunk("fileDownloads/generateJsonFromSelection", async (ids) => {
  if (ids.length > 0) {
    const response = await FileDownloadsApiService.getAllWetlands().catch((error) => {
      return response.status;
    });
    const selectedForms = response.data.filter(form => ids.includes(form.id));
    const selectedFormsUpdated = selectedForms.map(function(form) {
      const email = form.user?.email ?? '';
      form.user = email;
      delete form.id;
      return form;
    });
    const jsonData = JSON.stringify(selectedFormsUpdated);
    const url = window.URL.createObjectURL(new Blob([jsonData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `wetland_data.json`);
    document.body.appendChild(link);
    link.click();
    return response.status;
  }
});

export const generateReceiptPdf = createAsyncThunk("fileDownloads/generateReceiptPdf", async ({ purchaseEvent }, thunkAPI) => {
  if (purchaseEvent) {
    const response = await FileDownloadsApiService.generateReceiptPdf(purchaseEvent.id).catch((error) => {
      return {status: response.status};
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var sanitize = require("sanitize-filename");
    const fileName = `BioApp Receipt ${format(new Date(purchaseEvent.purchased_at), "MM-dd-yyyy")}`;
    const safeFileName = sanitize(fileName);
    link.setAttribute("download", `${safeFileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    return {status: response.status};
  }
});

export const fileDownloadsSlice = createSlice({
  name: "fileDownloads",
  initialState,
  reducers: {
    setDownloadPdfsLoading: (state) => {
      state.pdfsDownloadStatus = downloadStatus.LOADING;
    },
    setDownloadPdfsComplete: (state) => {
      state.pdfsDownloadStatus = downloadStatus.SUCCESS;
    },
    setShouldCancelPdfsDownload: (state, action) => {
      state.shouldCancelPdfsDownload = action.payload;
      state.pdfsDownloadStatus = downloadStatus.IDLE;
    },
    setDownloadPhotosLoading: (state) => {
      state.photosDownloadStatus = downloadStatus.LOADING;
    },
    setDownloadPhotosComplete: (state) => {
      state.photosDownloadStatus = downloadStatus.SUCCESS;
    },
    setShouldCancelPhotosDownload: (state, action) => {
      state.shouldCancelPhotosDownload = action.payload;
      state.photosDownloadStatus = downloadStatus.IDLE;
    },
    changeRegion: (state, action) => {
      state.region = action.payload;
    },
    
  },
});

export const {
  setDownloadPdfsLoading,
  setDownloadPdfsComplete,
  setShouldCancelPdfsDownload,
  setDownloadPhotosLoading,
  setDownloadPhotosComplete,
  setShouldCancelPhotosDownload,
} = fileDownloadsSlice.actions;

export const selectPdfsDownloadStatusLoading = (state) => state.fileDownloads.pdfsDownloadStatus === downloadStatus.LOADING ? true : false;
export const selectPhotosDownloadStatusLoading = (state) => state.fileDownloads.photosDownloadStatus === downloadStatus.LOADING ? true : false;

export default fileDownloadsSlice.reducer;