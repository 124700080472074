import React, { Fragment, useState } from 'react';
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from '@mui/icons-material/Info';

export default function ProjectAccessInfoPopup() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Box>
        <Tooltip title={"Popup to explain who can access project data"}>
          <span>
            <Button
              edge="end"
              aria-label="Popup to explain who can access project data"
              onClick={handleClickOpen}
              color="secondary"
              startIcon={<InfoIcon />}
              style={{ textTransform: "none", whiteSpace: "nowrap", minWidth: "auto" }}
            >
              Who can access project data?
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Who can access project data?</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>Access to a project is determined by Assigned Users. A Project Editor will have full permission to edit or delete a project, and access all of its associated 
            data. Various other view and edit permission can be set to limit the data forms that a user can view or modify.</Typography>
            <Typography>The user who created a project (the "Project Creator") is ALWAYS able to access and modify the project or its data, though they can voluntarily assign themselves a role to restrict this. If the user leaves or is removed 
            from the organization, they will retain their project assignments, and these must be removed from each project they are assigned to. To prevent unintended data access, ensure 
            that project user assignments are removed when certain users no longer need access to project data.</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
