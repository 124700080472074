import React from "react";
import { Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
  selectCurrentWetlandFormRegion,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";

const regionsShowBareGround = ["AK", "AW", "WMVC", "GP"];

const regionsShowWetlandBryophyte = ["AK"];

const regionsShowBioticCrust = ["AK", "AW"];

// Show bare ground, bryophyte, and biotic crust fields depending on the region
export default function ConditionalVegetationFields() {
  const region = useSelector(selectCurrentWetlandFormRegion);
  const currentForm = useSelector(selectCurrentWetlandForm);
  const dispatch = useDispatch();

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  return (
    <Stack direction="row" my={4} spacing={4}>
      {regionsShowBareGround.includes(region) && (
        <TextField
          id="percentBareGround"
          label="Percent Bare Ground"
          fullWidth
          value={currentForm?.percentBareGround ?? ""}
          onChange={handleStandardFormValueChange}
        />
      )}
      {regionsShowWetlandBryophyte.includes(region) && (
        <TextField
          id="percentCoverWetlandBryophytes"
          label="Percent Cover of Wetland Bryophytes"
          fullWidth
          value={currentForm?.percentCoverWetlandBryophytes ?? ""}
          onChange={handleStandardFormValueChange}
        />
      )}
      {regionsShowBioticCrust.includes(region) && (
        <TextField
          id="percentBioticCrust"
          label={region === "AK" ? "Total Cover of Bryophytes" : "Percent Bryophyte Cover"}
          fullWidth
          value={currentForm?.percentBioticCrust ?? ""}
          onChange={handleStandardFormValueChange}
        />
      )}
    </Stack>
  );
}
