import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Grid, TextField, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  changeCurrentWetlandFormSoilLayerItem,
  deleteCurrentWetlandSoilLayer,
  selectCurrentWetlandSoilLayers,
  selectCurrentWetlandMatrixColorItems,
  selectCurrentWetlandRedoxColorItems,
  selectCurrentWetlandSoilLayerMatrixColorsLength,
  selectCurrentWetlandSoilLayerRedoxColorsLength,
  addCurrentWetlandSoilColorItem,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import SoilLayerColorRow from "./SoilLayerColorRow";
import SoilLayerTextureCommentRow from "./SoilLayerTextureCommentRow";
import SoilLayerRedoxInfoRow from "./SoilLayerRedoxInfoRow";

export default function SoilLayer({ index }) {
  const soilLayers = useSelector(selectCurrentWetlandSoilLayers);
  const matrixColorItems = useSelector((state) => selectCurrentWetlandMatrixColorItems(state, index));
  const matrixColorsLength = useSelector((state) => selectCurrentWetlandSoilLayerMatrixColorsLength(state, index));
  const redoxColorItems = useSelector((state) => selectCurrentWetlandRedoxColorItems(state, index));
  const redoxColorsLength = useSelector((state) => selectCurrentWetlandSoilLayerRedoxColorsLength(state, index));
  const layer = soilLayers[index];
  const dispatch = useDispatch();

  const handleSoilLayerStandardItemChange = (e) => {
    dispatch(changeCurrentWetlandFormSoilLayerItem({ index: index, name: e.target.name, value: e.target.value }));
  };

  const handleDeleteLayer = (event) => {
    event.preventDefault();
    dispatch(deleteCurrentWetlandSoilLayer(index));
  };

  const handleAddSoilColorItem = (event, matrixOrRedox) => {
    event.preventDefault();
    dispatch(addCurrentWetlandSoilColorItem({ index: index, matrixOrRedox: matrixOrRedox }));
  };

  return (
    <Card elevation={4} sx={{ minWidth: 275, p: 2 }}>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item sm={5} md={3}>
            <TextField
              id={`soil-layer-startDepth-${index}`}
              name="startDepth"
              label="Start Depth"
              type="number"
              value={layer.startDepth ?? ""}
              onChange={handleSoilLayerStandardItemChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">in.</InputAdornment>,
              }}
              sx={{ width: "14ch" }}
            />
          </Grid>
          <Grid item sm={5} md={3}>
            <TextField
              id={`soil-layer-endDepth-${index}`}
              name="endDepth"
              label="End Depth"
              type="number"
              value={layer.endDepth ?? ""}
              onChange={handleSoilLayerStandardItemChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">in.</InputAdornment>,
              }}
              sx={{ width: "14ch" }}
            />
          </Grid>
          <Grid item sm={0} md={4}></Grid>
          <Grid item sm={1} md={1} alignSelf="center">
            <Button variant="contained" color="error" onClick={handleDeleteLayer}>
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
        {matrixColorItems.map((colorItem, colorItemIndex) => (
          <Grid key={`matrix-color-item-${colorItemIndex}`} container item spacing={2}>
            <SoilLayerColorRow index={index} colorItem={colorItem} colorItemIndex={colorItemIndex} matrixOrRedox={"matrix"} />
          </Grid>
        ))}
        {matrixColorsLength < 3 && (
          <Grid container item spacing={2}>
          <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <Button startIcon={<AddIcon />} onClick={(e) => handleAddSoilColorItem(e, 'matrix')}>Add Color</Button>
            </Grid>
          </Grid>
        )}
        {redoxColorItems.map((colorItem, colorItemIndex) => (
          <Grid key={`redox-color-item-${colorItemIndex}`} container item spacing={2}>
            <SoilLayerColorRow index={index} colorItem={colorItem} colorItemIndex={colorItemIndex} matrixOrRedox={"redox"} />
          </Grid>
        ))}
        {redoxColorsLength < 3 && (
          <Grid container item spacing={2}>
          <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <Button startIcon={<AddIcon />} onClick={(e) => handleAddSoilColorItem(e, 'redox')}>Add Color</Button>
            </Grid>
          </Grid>
        )}
        <Grid container item spacing={2}>
          <SoilLayerRedoxInfoRow index={index} />
        </Grid>
        <Grid container item spacing={2}>
          <SoilLayerTextureCommentRow index={index} />
        </Grid>
      </Grid>
    </Card>
  );
}
