import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { inviteUserToOrganization } from "../../../api/services/user/userSlice";
import { requestStatuses } from "../../../utils/constants";

function isEmailValid(email) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  } else {
    return true;
  }
}

export default function InviteDialog() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setErrorMessage(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setSendInviteLoadingStatus(requestStatuses.IDLE);
    setErrorMessage(undefined);
    setOpen(false);
  };

  const [errorMessage, setErrorMessage] = useState(undefined);

  const [sendInviteLoadingStatus, setSendInviteLoadingStatus] = useState(requestStatuses.IDLE);

  const [emailInfo, setEmailInfo] = useState({ email: "", valid: false });
  const [emailConfirmInfo, setEmailConfirmInfo] = useState({ email: "", valid: false });

  const changeEmail = (e) => {
    const newEmail = e.target.value.trim();
    setEmailInfo({ email: newEmail, valid: isEmailValid(newEmail) });
  };

  const changeEmailConfirm = (e) => {
    const newEmail = e.target.value.trim();
    const matchesEmail = newEmail === emailInfo.email ? true : false;
    setEmailConfirmInfo({ email: newEmail, valid: isEmailValid(newEmail) && matchesEmail });
  };

  const handleInviteToOrganization = (event) => {
    event.preventDefault();
    if (emailInfo.valid && emailConfirmInfo.valid) {
      setSendInviteLoadingStatus(requestStatuses.LOADING);
      dispatch(inviteUserToOrganization(emailInfo.email)).then((res) => {
        setErrorMessage({...res.payload.data});
        setSendInviteLoadingStatus(res.payload?.status === 204 ? requestStatuses.SUCCESS : requestStatuses.ERROR);
      });
    }
  };

  const confirmEmailErrorMessage = () => {
    if (!isEmailValid(emailConfirmInfo.email)) {
      return "Invalid Email Address";
    } else if (emailConfirmInfo.email !== emailInfo.email) {
      return "Email Address does not match";
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <Button variant="outlined" color="info" onClick={handleClickOpen}>
        Invite a User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite to Organization</DialogTitle>
        {sendInviteLoadingStatus === requestStatuses.IDLE && (
          <Fragment>
            <DialogContent>
              <DialogContentText>
                This will invite the user with the corresponding email address to the organization.
              </DialogContentText>
              <TextField
                autoFocus
                autoComplete="email"
                id="inviteEmail"
                label="Email Address"
                type="email"
                fullWidth
                margin="normal"
                value={emailInfo.email}
                onChange={changeEmail}
                error={!emailInfo.valid}
                helperText={!emailInfo.valid ? "Invalid Email Address" : null}
              />
              <TextField
                id="inviteEmailConfirm"
                label="Confirm Email Address"
                type="email"
                fullWidth
                margin="normal"
                value={emailConfirmInfo.email}
                onChange={changeEmailConfirm}
                error={!emailConfirmInfo.valid}
                helperText={confirmEmailErrorMessage()}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                disabled={!isEmailValid(emailInfo.email) || emailInfo.email !== emailConfirmInfo.email}
                onClick={handleInviteToOrganization}
              >
                Send/Request Invite
              </Button>
            </DialogActions>
          </Fragment>
        )}
        {sendInviteLoadingStatus === requestStatuses.LOADING && (
          <DialogContent>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={40} color="info" sx={{m: 8}} />
            </Box>
          </DialogContent>
        )}
        {sendInviteLoadingStatus === requestStatuses.SUCCESS && (
          <Fragment>
            <DialogContent sx={{ m: 4 }}>Invite sent to {emailInfo.email}!</DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Fragment>
        )}
        {sendInviteLoadingStatus === requestStatuses.ERROR && (
          <Fragment>
            {errorMessage ? <DialogContent>
              {errorMessage && errorMessage[Object.keys(errorMessage)[0]]}
            </DialogContent> : <DialogContent>
              Error sending invite! Email may be incorrect or the user may already be part of another organization.
            </DialogContent>}
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
}
