import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CssBaseline,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Container,
  Divider,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Animated from "../../resources/BioApp_Logo_Animated.svg";
import { ReactComponent as AppleStoreBadge } from "../../resources/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import ForgotPasswordDialog from "./components/ForgotPasswordDialog";
import axiosInstance from "../../api/auth";
import { loginObtainTokenPairRelURL } from "../../api/auth";
import { getUser, setAuthTokens, setUser } from "../../api/services/user/userSlice";
import { useRedirectIfAuthenticated } from "../../utils/hooks/useRedirectIfAuthenticated";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useRedirectIfAuthenticated('/');

  // On initial load, get the stored email if it is present
  const [loginData, updateLoginData] = useState(() => {
    const storeEmail = JSON.parse(localStorage.getItem("storeEmail"));
    const savedEmail = localStorage.getItem("email");
    return {
      storeEmail: storeEmail,
      email: savedEmail ?? "",
      validEmail: true,
      password: "",
    };
  });

  useEffect(() => {
    // When the email is changed, or when "remember me" is checked/unchecked, properly update the email entry is localstorage
    if (loginData.storeEmail === true) {
      localStorage.setItem("email", loginData.email);
    }
  }, [loginData.email, loginData.storeEmail]);

  const [isEmailValid, updateIsEmailValid] = useState(() => {
    return true;
  });

  const handleChange = (e) => {
    updateLoginData({
      ...loginData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const toggleShouldStoreEmail = (e) => {
    updateLoginData({
      ...loginData,
      storeEmail: e.target.checked,
    });
    localStorage.setItem("storeEmail", JSON.stringify(e.target.checked));
    if (!e.target.checked) {
      localStorage.removeItem("email");
    }
  };

  const validateEmail = (e) => {
    let email = e.target.value;
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      updateIsEmailValid(false);
    } else {
      updateIsEmailValid(true);
    }
  };

  const [errorSnackbarInfo, updateErrorSnackbarInfo] = useState({
    showSnackbar: false,
    message: '',
  });

  const handleOpenSnackbar = (message) => {
    updateErrorSnackbarInfo({
      showSnackbar: true,
      message: message,
    });
  };


  const handleCloseSnackbar = () => {
    updateErrorSnackbarInfo({
      ...errorSnackbarInfo,
      showSnackbar: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(axiosInstance.defaults.headers);
    axiosInstance
			.post(loginObtainTokenPairRelURL, {
				email: loginData.email,
				password: loginData.password,
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				axiosInstance.defaults.headers['Authorization'] = 'JWT ' + res.data.access;
        dispatch(setAuthTokens(res.data));
        // Only navigate to home page if the login was successful, otherwise stay on login page and show error
        // Redux state is not updated until next render cycle, so just check that the obtained token is in the response object
        if (res.data.refresh !== null) {
          // Set the user in the redux store here as well, so that header info re-renders
          dispatch(getUser()).then((userRes) => {
            dispatch(setUser(userRes.payload));
          });
          navigate("/");
        }
			}).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            // Password or email blank
            handleOpenSnackbar('Error: email and password cannot be blank.');
          } else if (error.response.status === 401) {
            // Invalid credentials
            handleOpenSnackbar('Error: invalid login credentials.');
          } else {
            handleOpenSnackbar('Error: server could not process request.');
          }
        } else if (error.request) {
          handleOpenSnackbar('Error: could not obtain a response from the server.');
        } else {
          handleOpenSnackbar('Error: an unknown error occurred processing the login request.');
        }
      });
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Snackbar open={errorSnackbarInfo.showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorSnackbarInfo.message}
        </Alert>
      </Snackbar>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Box sx={{ width: 300, my: 4 }}>
            <object data={Animated} type="image/svg+xml">
              BioApp logo SVG animation
            </object>
          </Box>
        </Grid>
      </Grid>
      <Grid container rowSpacing={8} columnSpacing={0}>
        <Grid item md={6} sm={12}>
          <Container maxWidth="xs">
            <Card raised>
              <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center", padding: 4 }}>
                <Typography component="h1" variant="h5">
                  Sign In
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={loginData.email}
                    onChange={handleChange}
                    onBlur={validateEmail}
                    error={!isEmailValid}
                    helperText={!isEmailValid === "" ? "Invalid Email Address" : ""}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={loginData.storeEmail} onChange={toggleShouldStoreEmail} />
                    }
                    label="Remember me"
                  />
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Sign In
                  </Button>
                  <ForgotPasswordDialog />
                </Box>
              </Box>
            </Card>
          </Container>
        </Grid>
        <Grid item md={6} sm={12}>
          <Container maxWidth="xs">
            <Card raised>
              <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center", padding: 4 }}>
                <Typography component="h1" variant="h5">
                  No Subscription?
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                  No problem! Download the app and purchase a subscription from an iOS or Android device, then return to
                  this site to manage your data.
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingX: 4 }}>
                <a
                    href="https://apps.apple.com/us/app/bioapp-wetland-delineation/id1579117594"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppleStoreBadge style={{ width: 215, height: "100%", paddingTop: 12, paddingBottom: 12 }} alt="Get on the Apple Store" />
                  </a>
                  <Divider style={{ width: "75%" }} sx={{ my: 1 }}>OR</Divider>
                  <a
                    href="https://play.google.com/store/apps/details?id=us.bioapp.bioapp_wetland_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                </Box>
              </Box>
            </Card>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}
