export const colorOffWhite = '#F7F2ED';
export const colorGreenStandard = '#6AAB35';
export const colorGreenDark = '#557A36';
export const colorBlueStandard = '#1A80B6';
export const colorVegetation = '#4CAF50';
export const colorHydrology = '#448AFF';
export const colorSoils = '#795548';
export const colorGreyLight = '#9E9790';
export const colorGreyMidtone = '#686058';
export const colorGreyDarktone = '#4A443F';

export const colorSuccess = '#4caf50';
export const colorError = '#f44336';