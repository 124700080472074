import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  removePreviousMessage,
  selectActiveMessage,
  selectAllMessages,
} from "../../api/services/messages/messagesSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MessageSnackbars() {
  const dispatch = useDispatch();
  const messages = useSelector(selectAllMessages);
  const activeMessage = useSelector(selectActiveMessage);

  const [open, setOpen] = useState(false);
  const [activeMessageInfo, setActiveMessageInfo] = React.useState(undefined);

  useEffect(() => {
    // Set new snack if one isn't already active (showing), otherwise close the active one when a new snack is added
    if (messages.length && !activeMessageInfo) {
      setActiveMessageInfo({ ...activeMessage });
      dispatch(removePreviousMessage());
      setOpen(true);
    } else if (messages.length && activeMessageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [dispatch, messages, activeMessage, activeMessageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setActiveMessageInfo(undefined);
  };

  return (
    <Snackbar
      key={activeMessageInfo ? activeMessageInfo.key : undefined}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert onClose={handleClose} severity={activeMessageInfo?.severity || "error"} sx={{ width: "100%" }}>
        {activeMessageInfo ? activeMessageInfo.messageText : ""}
      </Alert>
    </Snackbar>
  );
}
