import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { requestToJoinOrganization, getUserMembership, setOrgMembership, getUserOrganization, setOrganization } from "../../../api/services/user/userSlice";
import { requestStatuses } from "../../../utils/constants";

export default function JoinOrganizationDialog() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRequestJoinLoadingStatus(requestStatuses.IDLE);
    setOpen(false);
  };

  const [requestJoinLoadingStatus, setRequestJoinLoadingStatus] = useState(requestStatuses.IDLE);

  const [organizationInfo, setOrganizationName] = useState({ orgName: "", orgUuid: "" });

  const changeOrganizationName = (e) => {
    setOrganizationName({ orgName: e.target.value, orgUuid: organizationInfo.orgUuid });
  };

  const changeOrganizationUuid = (e) => {
    setOrganizationName({ orgUuid: e.target.value, orgName: organizationInfo.orgName });
  };

  const handleRequestToJoinOrganization = (event) => {
    event.preventDefault();
    if (organizationInfo.orgName !== "" && organizationInfo.orgUuid !== "") {
      setRequestJoinLoadingStatus(requestStatuses.LOADING);
      dispatch(requestToJoinOrganization(organizationInfo)).then((res) => {
        setRequestJoinLoadingStatus(res.payload?.status === 204 ? requestStatuses.SUCCESS : requestStatuses.ERROR);
        dispatch(getUserOrganization()).then((res) => {
          if (res.payload.status === 204) {
            dispatch(setOrganization(null));
          } else if (res.payload.status === 200) {
            dispatch(setOrganization(res.payload.data));
          }
        });
        dispatch(getUserMembership()).then((res) => {
          if (res.payload.status === 200) {
            dispatch(setOrgMembership(res.payload.data));
          }
        });
      });
    }
  };

  return (
    <Fragment>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Request to Join Organization
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Request to Join Organization</DialogTitle>
        {requestJoinLoadingStatus === requestStatuses.IDLE && (
          <Fragment>
            <DialogContent>
              <DialogContentText>
                This will request to join the organization with the following name and unique ID.
              </DialogContentText>
              <TextField
                autoFocus
                id="requestOrganizationName"
                label="Organization Name"
                fullWidth
                margin="normal"
                value={organizationInfo.orgName}
                onChange={changeOrganizationName}
                error={organizationInfo.orgName === ""}
                helperText={organizationInfo.orgName === "" ? "Organization Name cannot be empty" : null}
              />
              <TextField
                id="requestOrganizationUuid"
                label="Unique Organization Identifier"
                fullWidth
                margin="normal"
                value={organizationInfo.orgUuid}
                onChange={changeOrganizationUuid}
                error={organizationInfo.orgUuid === ""}
                helperText={organizationInfo.orgUuid === "" ? "Unique Organization ID cannot be empty" : null}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                disabled={organizationInfo.orgName === "" || organizationInfo.orgUuid === ""}
                onClick={handleRequestToJoinOrganization}
              >
                Send Invite
              </Button>
            </DialogActions>
          </Fragment>
        )}
        {requestJoinLoadingStatus === requestStatuses.LOADING && (
          <DialogContent>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={40} color="info" sx={{ m: 8 }} />
            </Box>
          </DialogContent>
        )}
        {requestJoinLoadingStatus === requestStatuses.SUCCESS && (
          <Fragment>
            <DialogContent sx={{ m: 4 }}>Invite sent to {organizationInfo.orgName}!</DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Fragment>
        )}
        {requestJoinLoadingStatus === requestStatuses.ERROR && (
          <Fragment>
            <DialogContent>
              Error requesting to join organization! The organization name or ID may be incorrect.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
}
