import React from 'react';
import { ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material';
import { styled } from "@mui/material/styles";
import { colorBlueStandard, colorGreyLight, colorOffWhite } from '../../utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { changeRegion } from '../../api/services/wetlandforms/wetlandFormsSlice';
import { aceRegions } from '../../utils/constants';

const hexTorgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    backgroundColor: colorGreyLight,
    color: colorOffWhite,
    "&.Mui-selected": {
      backgroundColor: colorBlueStandard,
      color: colorOffWhite,
      "&:hover": {
        backgroundColor: hexTorgba(colorBlueStandard, 0.8),
      },
    },
    "&:hover": {
      backgroundColor: hexTorgba(colorGreyLight, 0.8),
    },
  },
}));

export default function RegionSelectBar() {
  const dispatch = useDispatch();
  const region = useSelector((state) => state.wetlandForms.region);

  return (
    <Toolbar>
      <StyledToggleButtonGroup
        color="secondary"
        value={region}
        onChange={(event, newRegion) => {
          dispatch(changeRegion(newRegion));
        }}
        exclusive
        aria-label="Selected Form Region"
      >
        {aceRegions.map((item) => (
          <ToggleButton key={item} value={item} aria-label={item}>
            {item}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Toolbar>
  )
}
