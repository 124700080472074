import axios from 'axios';

// const baseURL = 'http://0.0.0.0/';
let baseURL = 'http://0.0.0.0/'; // Use 'http://127.0.0.1:8000/' when testing directly
if (window.location.origin === "http://localhost:3000") { // Add " || window.location.origin === "https://localhost:3000"" for testing map location with HTTPS=true
	// // For staging testing on server, or testing with docker-compose
  // axios.defaults.baseURL = 'http://0.0.0.0/';
  // baseURL = 'http://0.0.0.0/';
	// For local dev testing without docker
  axios.defaults.baseURL = 'http://127.0.0.1:8000/';
  baseURL = 'http://127.0.0.1:8000/';
} else {
	baseURL = window.location.origin;
  axios.defaults.baseURL = window.location.origin;
}
export const loginObtainTokenPairRelURL = 'auth/jwt/create/';
const refreshTokenFullURL = baseURL + 'auth/jwt/refresh/';
const refreshTokenRelURL = 'auth/jwt/refresh/';
export const logoutBlacklistTokenRelURL = 'api/user/logout/blacklist/';
export const requestPasswordResetRelURL = 'auth/users/reset_password/';
export const requestPasswordResetFullURL = baseURL + 'auth/users/reset_password/';
export const confirmPasswordResetRelURL = 'auth/users/reset_password_confirm/';

const axiosInstance = axios.create({
	baseURL: baseURL,
	// timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			console.log('undefined response in request interceptor:');
			console.log(error);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === refreshTokenFullURL
		) {
			return Promise.reject(error);
		}

		if (
			// If attempting to download files (response data is Blob), token_not_valid message will not be present
			(error.response.data.code === 'token_not_valid' || error.response.data instanceof Blob) &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');
			if (refreshToken === undefined) {
				localStorage.removeItem('refresh_token');
			}

			if (refreshToken && refreshToken !== undefined) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, and now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post(refreshTokenRelURL, { refresh: refreshToken })
						.then((response) => {
							if (response.data.refresh) {
								localStorage.setItem('access_token', response.data.access);
								localStorage.setItem('refresh_token', response.data.refresh);
							}
							axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					// console.log('Refresh token is expired', tokenParts.exp, now);
				}
			} else {
				// console.log('Refresh token not available.');
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;