import axiosInstance from '../../auth';

class UserApiService {

  getUser() {
    return axiosInstance.get(`auth/users/me/`);
  }

  getUserMembership() {
    return axiosInstance.get(`api/user/organization-membership/`);
  }

  getUserOrganization() {
    return axiosInstance.get(`api/user/organization/`);
  }

  createOrganization(orgName) {
    return axiosInstance.post(
      `api/user/organization/`,
      {
        name: orgName,
      }
    );
  }

  listOrganizationMembers() {
    return axiosInstance.get(`api/user/organization-members/`);
  }

  inviteUserToOrganization(data) {
    // Invite endpoint has options of /send/, /respond/, or /request/
    return axiosInstance.post(
      `api/user/invite/send/`,
      data,
    );
  }

  requestToJoinOrganization(data) {
    return axiosInstance.post(
      `api/user/invite/request/`,
      data,
    );
  }

  respondToOrganizationInvite(data) {
    return axiosInstance.post(
      `api/user/invite/respond/`,
      data,
    );
  }

  promoteOrDemoteUser(data) {
    return axiosInstance.post(
      `api/user/promote-or-demote-user/`,
      data,
    );
  }

  userLeaveOrganization(data) {
    return axiosInstance.post(
      `api/user/leave-organization/`,
      data,
    );
  }

  getUserSubscriptionEvents() {
    return axiosInstance.get(`api/purchases/`);
  }
}

export default new UserApiService();