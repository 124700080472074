import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// MUI Snackbars are used to manage messages. This includes successes, errors, etc. 
// A snackbar component is created, potentially replacing an old one, in the main homepage, above the route components.
// This is done so that no matter the page the user is on, the error snackbar will show successfully.

const initialState = {
  messages: [], // Messenges are ordered, display in the order that they are added to the ilst.
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addErrorMessage: (state, action) => {
      const elementKey = `message-${state.messages.length}-error}`;
      state.messages = [
        ...state.messages,
        {
          key: elementKey,
          severity: 'error', // This matches the MUI severity classes on the snackbar Alert component
          messageText: action.payload,
        },
      ];
    },
    addWarningMessage: (state, action) => {
      const elementKey = `message-${state.messages.length}-warning}`;
      state.messages = [
        ...state.messages,
        {
          key: elementKey,
          severity: 'warning', // This matches the MUI severity classes on the snackbar Alert component
          messageText: action.payload,
        },
      ];
    },
    addSuccessMessage: (state, action) => {
      const elementKey = `message-${state.messages.length}-success`;
      state.messages = [
        ...state.messages,
        {
          key: elementKey,
          messageType: 'success',
          messageText: action.payload,
        },
      ];
    },
    removePreviousMessage: (state, action) => {
      if (state.messages.length) {
        state.messages = state.messages.slice(1);
      }
    },
  },
});

export const { addErrorMessage, addWarningMessage, addSuccessMessage, removePreviousMessage } = messagesSlice.actions;

export const selectAllMessages = (state) => state.messages.messages;
export const selectErrorMessages = (state) => state.messages.messages.filter((msg) => msg.status === 'error');
export const selectActiveMessage = (state) => state.messages.messages.length ? state.messages.messages[0] : undefined;

export default messagesSlice.reducer;