import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "./icons/crosshairs-gps.svg";
import GreenMarker from "./icons/leaflet-marker-green.svg";
import YellowMarker from "./icons/leaflet-marker-yellow.svg";
import GreenMarkerSelected from "./icons/leaflet-marker-green-selected.svg";
import YellowMarkerSelected from "./icons/leaflet-marker-yellow-selected.svg";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Circle,
  Popup,
  useMap,
  LayersControl,
  useMapEvents,
  Tooltip,
} from "react-leaflet";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { FeatureLayer } from "react-esri-leaflet";
import { Stack, Box, Typography } from "@mui/material";
import {
  formsLoadingStatus,
  getAllWetlandFormsAsync,
  selectSelectedWetlandFormIds,
  selectSelectedWetlandForms,
  selectWetlandFormsLoadingStatus,
  setSelectedForms,
} from "../../api/services/wetlandforms/wetlandFormsSlice";
import { selectBasemapUrl, selectMapZoom, selectWetlandFormsWithProjectFilter } from "../../api/services/spatial/spatialSlice";
import WetlandFormPopup from "./components/WetlandFormPopup";
// import CountiesFeatureLayer from "./components/CountiesFeatureLayer";
import { colorGreyMidtone } from "../../utils/colors";
import { mapColorsNwiClasses } from "./components/StyleColors";

const MyLocationIcon = new L.Icon({
  iconUrl: LocationIcon,
  iconRetinaUrl: LocationIcon,
  iconAnchor: new L.Point(12, 12),
  popupAnchor: new L.Point(0, 0),
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: new L.Point(12, 12),
  iconSize: new L.Point(24, 24),
});

const WetlandMarkerIcon = L.Icon.extend({
  options: {},
});

// Leaflet icon is 82 tall by 50 wide
const greenIcon = new L.Icon({
  iconUrl: GreenMarker,
  iconRetinaUrl: GreenMarker,
  iconSize: new L.Point(25, 41),
  iconAnchor: new L.Point(12.5, 41),
  popupAnchor: new L.Point(0, -41),
  shadowUrl: markerShadow,
  shadowAnchor: new L.Point(12.5, 41),
});
const yellowIcon = new WetlandMarkerIcon({
  iconUrl: YellowMarker,
  iconRetinaUrl: YellowMarker,
  iconSize: new L.Point(25, 41),
  iconAnchor: new L.Point(12.5, 41),
  popupAnchor: new L.Point(0, -41),
  shadowUrl: markerShadow,
  shadowAnchor: new L.Point(12.5, 41),
});
const greenIconSelected = new L.Icon({
  iconUrl: GreenMarkerSelected,
  iconRetinaUrl: GreenMarkerSelected,
  iconSize: new L.Point(25, 41),
  iconAnchor: new L.Point(12.5, 41),
  popupAnchor: new L.Point(0, -41),
  shadowUrl: markerShadow,
  shadowAnchor: new L.Point(12.5, 41),
});
const yellowIconSelected = new WetlandMarkerIcon({
  iconUrl: YellowMarkerSelected,
  iconRetinaUrl: YellowMarkerSelected,
  iconSize: new L.Point(25, 41),
  iconAnchor: new L.Point(12.5, 41),
  popupAnchor: new L.Point(0, -41),
  shadowUrl: markerShadow,
  shadowAnchor: new L.Point(12.5, 41),
});

function UserLocationMarker() {
  const [position, setPosition] = useState({ lat: null, lng: null });
  const [accuracy, setAccuracy] = useState(null);
  const map = useMap();
  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      setAccuracy(e.accuracy);
      // map.flyTo(e.latlng, 7);
      const circle = L.circle(e.latlng, e.accuracy);
      circle.addTo(map);
    });
    return function cleanup() {
      // Cleanup to cancel async get position map process to prevent memory leaks
      map.stopLocate();
    };
  }, [map]);

  return position === null ? null : (
    <React.Fragment>
      <Circle center={position} pathOptions={{ color: "blue" }} radius={accuracy} />
      <Marker position={position} icon={MyLocationIcon}>
        <Popup>Your location</Popup>
      </Marker>
    </React.Fragment>
  );
}

const MapEvents = ({ setClickedFeatureAttributes }) => {
  const map = useMapEvents({
    click: (e) => {
      // Reset all currently stored marker data here, reset to empty object
      setClickedFeatureAttributes({});
      // console.log(e.latlng, map.getZoom());
    },
  });
  return null;
};

function ClickedLocationMarker({ clickedFeatureAttributes }) {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click: (e) => {
      setPosition(e.latlng);
    },
  });

  return position === null ? null : (
    <Marker position={position} eventHandlers={{
      click: (e) => {
        return null;
      },
    }}>
      <Tooltip permanent direction="auto" interactive>
          {Object.keys(clickedFeatureAttributes).map((layerItemKey) => (
          <Stack key={layerItemKey} spacing={1} p={1}>
            <Typography fontWeight="bold">{layerItemKey}</Typography>
            <Box px={2}>
              {Object.keys(clickedFeatureAttributes[layerItemKey]).map((attribItemKey) => (
                <Stack key={layerItemKey + '_' + attribItemKey} direction="row">
                  <Box sx={{ width: 160 }}><Typography>{attribItemKey}:</Typography></Box>
                  <Typography>{clickedFeatureAttributes[layerItemKey][attribItemKey]}</Typography>
                </Stack>
              ))}
            </Box>
          </Stack>
        ))}
        {Object.keys(clickedFeatureAttributes).length === 0 && <Typography style={{ color: colorGreyMidtone }}>No layers intersected</Typography>}
      </Tooltip>
    </Marker>
  );
}

export default function LeafletMap() {
  const dispatch = useDispatch();
  const wetlandFormsWithProjectFilter = useSelector(selectWetlandFormsWithProjectFilter);
  const wetlandFormsStatus = useSelector(selectWetlandFormsLoadingStatus);
  const selectedForms = useSelector(selectSelectedWetlandForms);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  const mapZoom = useSelector(selectMapZoom);
  const basemapUrl = useSelector(selectBasemapUrl);

  const [clickedFeatureAttributes, setClickedFeatureAttributes] = useState({});

  function addLayerToClickedFeatureAttributes(layername, properties) {
    const newClickedAttribs = {
      ...clickedFeatureAttributes,
      [layername]: properties,
    };
    setClickedFeatureAttributes(newClickedAttribs);
  }

  useEffect(() => {
    if (wetlandFormsStatus === formsLoadingStatus.IDLE) {
      dispatch(getAllWetlandFormsAsync({ filterByCurrentRegion: true }));
    }
  }, [wetlandFormsStatus, dispatch]);

  function formIconByWetlandAndSelectedStatus(form, isSelected) {
    if (isSelected === true) {
      return form.isWetland ? greenIconSelected : yellowIconSelected;
    } else {
      return form.isWetland ? greenIcon : yellowIcon;
    }
  }

  const getAverageSelectedLocation = () => {
    // If no selected forms, just do a generic center on [40, -95]. If any are selected, avg the lat/longs and center the map on that
    if (selectedForms.length === 0) return [40, -95];

    let latitudes = selectedForms.map((form) => {
      let parsedLat = form.latitude.replace(/[^\d.-]/g, '');
      if (isNaN(parseFloat(form.latitude))) {
        return null;
      }
      return parseFloat(parsedLat);
    });
    latitudes.filter(Number);
    let avgLat = latitudes.reduce((total, latitude) => {
      return total + latitude;
    }, 0) / latitudes.length;

    let longitudes = selectedForms.map((form) => {
      let parsedLong = form.longitude.replace(/[^\d.-]/g, '');
      if (isNaN(parseFloat(form.longitude))) {
        return null;
      }
      return parseFloat(parsedLong);
    });
    longitudes.filter(Number);
    let avgLong = longitudes.reduce((total, longitude) => {
      return total + longitude;
    }, 0) / longitudes.length;
    return [avgLat, avgLong];
  }

  return (
    <MapContainer center={getAverageSelectedLocation()} zoom={mapZoom ?? 10} style={{ height: "80vh" }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url={basemapUrl}
      />
      <MapEvents setClickedFeatureAttributes={setClickedFeatureAttributes} />
      <LayersControl position="topleft" collapsed={false}>
        <LayersControl.Overlay name="City/County">
          <FeatureLayer
            url={
              "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Counties/FeatureServer/0"
            }
            eventHandlers={{
              createfeature: (e) => {
                // Remove all necessary attributes
                const filteredKeys = {};
                filteredKeys['FIPS'] = e.feature.properties['FIPS'];
                filteredKeys['STATE_FIPS'] = e.feature.properties['STATE_FIPS'];
                filteredKeys['CNTY_FIPS'] = e.feature.properties['CNTY_FIPS'];
                filteredKeys['NAME'] = e.feature.properties['NAME'];
                filteredKeys['STATE_NAME'] = e.feature.properties['STATE_NAME'];
                e.feature.properties = filteredKeys;
                return e;
              },
              click: async (e) => {
                await new Promise(resolve => setTimeout(resolve, 200));
                addLayerToClickedFeatureAttributes("City/County", e.layer.feature.properties);
              },
            }}
            style={(feature) => {
              // console.log(feature.properties.NAME);
              return { color: 'grey', weight: 2, fillColor: 'black', fillOpacity: 0 };
            }}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="USACE Subregions">
          <FeatureLayer
            url={
              "https://services7.arcgis.com/n1YM8pTrFmm7L4hs/arcgis/rest/services/coe_wetland_subregions/FeatureServer/0"
            }
            eventHandlers={{
              createfeature: (e) => {
                // Remove all necessary attributes
                const filteredKeys = {};
                filteredKeys['ADS_REGSUP'] = e.feature.properties['ADS_REGSUP'];
                filteredKeys['ADS_SUB_NM'] = e.feature.properties['ADS_SUB_NM'];
                filteredKeys['LRR_NAME'] = e.feature.properties['LRR_NAME'];
                filteredKeys['MLRA_NAME'] = e.feature.properties['MLRA_NAME'];
                e.feature.properties = filteredKeys;
                return e;
              },
              click: async (e) => {
                // This is currently a bit messy. There is just a small delay built in so that the initial map click (done 
                // when clicking anywhere on the map) resets the clicked layer attributes to an empty object, ensuring that 
                // this is done before this layer click event is called. This is needed because the layer click event 
                // actually triggers before the general map click event.
                // In the future, transition to storing the clicked layer attributes in redux, and wait for the layer 
                // reset to occur before completing this click event function.
                await new Promise(resolve => setTimeout(resolve, 200));
                addLayerToClickedFeatureAttributes("USACE Subregion", e.layer.feature.properties);
              },
            }}
            style={(feature) => {
              return { color: 'red', weight: 2, fillOpacity: 0 };
            }}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="PLSS">
          <FeatureLayer
            url={"https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/2"}
            minZoom={11}
            eventHandlers={{
              createfeature: (e) => {
                // Remove all necessary attributes
                const filteredKeys = {};
                filteredKeys['PLSSID'] = e.feature.properties['PLSSID'];
                filteredKeys['FRSTDIVID'] = e.feature.properties['FRSTDIVID'];
                e.feature.properties = filteredKeys;
                return e;
              },
              click: async (e) => {
                await new Promise(resolve => setTimeout(resolve, 200));
                addLayerToClickedFeatureAttributes("PLSS", e.layer.feature.properties);
              },
            }}
            style={(feature) => {
              return { color: 'lightgrey', weight: 1, fillOpacity: 0 };
            }}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="NWI">
          <FeatureLayer
            url={"https://www.fws.gov/wetlandsmapservice/rest/services/Wetlands/MapServer/0"}
            useCors={false}
            minZoom={16}
            eventHandlers={{
              createfeature: (e) => {
                // Remove all necessary attributes
                const filteredKeys = {};
                filteredKeys['NWI_SYSTEM'] = e.feature.properties['NWI_Wetland_Codes.SYSTEM'];
                filteredKeys['NWI_CLASS'] = e.feature.properties['NWI_Wetland_Codes.CLASS'];
                filteredKeys['NWI_SUBCLASS'] = e.feature.properties['NWI_Wetland_Codes.SUBCLASS'];
                filteredKeys['NWI_ATTRIBUTE'] = e.feature.properties['NWI_Wetland_Codes.ATTRIBUTE'];
                filteredKeys['ATTRIBUTE'] = e.feature.properties['Wetlands.ATTRIBUTE'];
                filteredKeys['WETLAND_TYPE'] = e.feature.properties['Wetlands.WETLAND_TYPE'];
                e.feature.properties = filteredKeys;
                return e;
              },
              click: async (e) => {
                await new Promise(resolve => setTimeout(resolve, 200));
                addLayerToClickedFeatureAttributes("NWI", e.layer.feature.properties);
              },
            }}
            style={(feature) => {
              const featSystem = feature.properties.NWI_SYSTEM;
              const featClass = feature.properties.NWI_CLASS;
              let color = 'grey';
              if (mapColorsNwiClasses.hasOwnProperty(featSystem)) {
                const systemColors = mapColorsNwiClasses[featSystem];
                if (systemColors.hasOwnProperty(featClass)) {
                  color = systemColors[featClass];
                } else if (systemColors.hasOwnProperty('default')) {
                  color = systemColors['default'];
                }
              }
              return { color: color, weight: 2, fillColor: color, fillOpacity: 0.2 };
            }}
          />
        </LayersControl.Overlay>
        {/* <LayersControl.Overlay name="Soil Survey Map">
          <DynamicMapLayer
            url="https://landscape11.arcgis.com/arcgis/rest/services/USA_Soils_Map_Units/featureserver"
            // url="https://maps.indiana.edu/ArcGIS/rest/services/Environment/Soils_SSURGO_Soil_Survey/MapServer/0"
            minZoom={14}
            eventHandlers={{
              createfeature: (e) => {
                // Remove all necessary attributes
                // const filteredKeys = {};
                // filteredKeys['PLSSID'] = e.feature.properties['PLSSID'];
                // filteredKeys['FRSTDIVID'] = e.feature.properties['FRSTDIVID'];
                // e.feature.properties = filteredKeys;
                return e;
              },
              click: async (e) => {
                await new Promise(resolve => setTimeout(resolve, 200));
                console.log(e.layer.feature.properties);
                addLayerToClickedFeatureAttributes("Soil Survey Map", e.layer.feature.properties);
              },
            }}
          />
        </LayersControl.Overlay> */}
      </LayersControl>

      {/* <CountiesFeatureLayer /> */}
      {wetlandFormsWithProjectFilter?.map((form, index) => {
        let parsedLat = form.latitude.replace(/[^\d.-]/g, '');
        let parsedLong = form.longitude.replace(/[^\d.-]/g, '');
        if (isNaN(parseFloat(form.latitude)) || isNaN(parseFloat(form.longitude))) {
          return undefined;
        }
        let isSelected = selectedFormIds.includes(form.id);
        return (
          <Marker
            key={`map-marker-${index}`}
            position={[parsedLat, parsedLong]}
            // icon={form.isWetland ? greenIcon : yellowIcon}
            icon={formIconByWetlandAndSelectedStatus(form, isSelected)}
            eventHandlers={{
              click: (e) => {
                // So that the PDF download button works properly, and to help with navigation, clicking on a form's marker selects that form
                dispatch(setSelectedForms([form.id]));
              },
            }}
          >
            <WetlandFormPopup form={form} />
          </Marker>
        );
      })}
      <ClickedLocationMarker clickedFeatureAttributes={clickedFeatureAttributes} />
      <UserLocationMarker />
    </MapContainer>
  );
}
