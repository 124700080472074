import React, { useState } from "react";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserOrganization,
  selectOrganization,
  selectUser,
  setOrganization,
} from "../../api/services/user/userSlice";
import { addProject } from "../../api/services/projects/projectsSlice";

const defaultProjectData = {
  organization: undefined,
  name: "",
  slug: "",
  project_number: "",
  project_or_site_name: "",
  applicant_or_owner_name: "",
  notes: "",
};

export default function CreateProjectDialog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const [open, setOpen] = useState(false);
  const [projectData, setProjectData] = useState({ ...defaultProjectData });

  const handleClickOpen = async () => {
    setProjectData({ ...defaultProjectData });
    if (!organization) {
      const response = await dispatch(getUserOrganization()).unwrap();
      if (response.status === 200) {
        dispatch(setOrganization(response.data));
        setProjectData({
          ...projectData,
          organization: response.data.id,
        });
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateAndClose = async () => {
    // After creating the project, if successful, navigate to that page
    const response = await dispatch(addProject(projectData)).unwrap();
    if (response.status === 201) {
      setProjectData({
        ...response.data,
      });
      setOpen(false);
      navigate(`/projects/${response.data.slug}-${response.data.id}`, { state: { initialProjectData: response.data } });
    }
  };

  const changeProjectName = (e) => {
    // This also udpates the slug of the project
    const newValue = e.target.value;
    const slugValue = newValue
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
    setProjectData({ ...projectData, [e.target.name]: newValue, slug: slugValue });
  };

  const changeProjectValue = (e) => {
    const newValue = e.target.value;
    setProjectData({ ...projectData, [e.target.name]: newValue });
  };

  return (
    <div>
      <Box>
      {/* Previously used title={user?.org_user_role === "regular" ? "You must be an Organization Manager to create and edit projects" : "Create a new project"} on Tooltip */}
        <Tooltip title={"Create a new project"}>
          <span>
            <Button
              edge="end"
              aria-label="Create a new project"
              // disabled={user?.org_user_role === "regular"}
              onClick={handleClickOpen}
              color="secondary"
              variant="contained"
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a New Project</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              margin="dense"
              id="project-name"
              name="name"
              label="Project Name"
              helperText={`Project slug: ${projectData.slug}`}
              fullWidth
              variant="outlined"
              required
              value={projectData.name}
              error={projectData.name === ""}
              onChange={changeProjectName}
            />
            <TextField
              id="project-number"
              name="project_number"
              label="Project Number"
              helperText="Use as a unique project identifier"
              fullWidth
              variant="outlined"
              value={projectData.project_number}
              onChange={changeProjectValue}
            />
            <TextField
              id="project-or-site-name"
              name="project_or_site_name"
              label="Project/Site Name"
              helperText="Name recorded in PDF outputs"
              fullWidth
              variant="outlined"
              value={projectData.project_or_site_name}
              onChange={changeProjectValue}
            />
            <TextField
              id="applicant-or-owner-name"
              name="applicant_or_owner_name"
              label="Applicant/Owner"
              helperText="Applicant/Owner as it will appear in PDF output"
              fullWidth
              variant="outlined"
              value={projectData.applicant_or_owner_name}
              onChange={changeProjectValue}
            />
            <TextField
              id="project-notes"
              name="notes"
              label="Project Notes"
              helperText="These notes are for internal use only, and do not appear in any output data"
              multiline
              maxRows={6}
              fullWidth
              variant="outlined"
              value={projectData.notes}
              onChange={changeProjectValue}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateAndClose}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
