import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestStatuses } from '../../../utils/constants';
import { addErrorMessage } from '../messages/messagesSlice';
import ProjectsApiService from './projectsAPI';

const initialState = {
  loading_status: requestStatuses.IDLE,
  projects: null,
  projects_sort_by: ['name', 'created_date', 'completion_date', 'status', 'project_number'].includes(JSON.parse(localStorage.getItem('projects_sort_by'))) ? JSON.parse(localStorage.getItem('projects_sort_by')) : 'name',
  projects_sort_order: ['ASC', 'DESC'].includes(JSON.parse(localStorage.getItem('projects_sort_order'))) ? JSON.parse(localStorage.getItem('projects_sort_order')) : 'ASC',
  projects_filter_text: JSON.parse(localStorage.getItem('projects_filter_text')) ?? '',
  selected_project_loading_status: requestStatuses.IDLE,
  selected_project: null,
};

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setProjectsLoadingStatus(requestStatuses.LOADING));
    const response = await ProjectsApiService.getProjects();
    if (response.status === 200) {
      thunkAPI.dispatch(setProjectsLoadingStatus(requestStatuses.SUCCESS));
      thunkAPI.dispatch(setProjects(response.data));
      const sort_by = thunkAPI.getState().projects.projects_sort_by;
      const sort_order = thunkAPI.getState().projects.projects_sort_order;
      thunkAPI.dispatch(setProjectsSorting({ sort_by: sort_by, sort_order: sort_order }));
    } else {
      thunkAPI.dispatch(setProjectsLoadingStatus(requestStatuses.ERROR));
    }
    return response.data;
  }
);

export const getProject = createAsyncThunk(
  'projects/getProject',
  async ({ slug, id }, thunkAPI) => {
    thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.LOADING));
    try {
      const response = await ProjectsApiService.getProjectDetail(slug, id);
      if (response.status === 200) {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.SUCCESS));
        thunkAPI.dispatch(setSelectedProject(response.data));
      } else {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      }
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
    }
  }
);

export const addProject = createAsyncThunk(
  'projects/addProject',
  async (addProjectData, thunkAPI) => {
    thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.LOADING));
    try {
      const response = await ProjectsApiService.addProject(addProjectData);
      if (response.status === 201) {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.SUCCESS));
        thunkAPI.dispatch(setSelectedProject(response.data));
      } else {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
        thunkAPI.dispatch(addErrorMessage(`Error creating project: ${response.status} ${response.statusText}`));
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      if (errorMessageContent.data.hasOwnProperty('slug')) {
        thunkAPI.dispatch(addErrorMessage(`Error creating project: a project with this slug/name already exists.`));
      } else if (errorMessageContent.status === 403) {
        thunkAPI.dispatch(addErrorMessage(`Error creating project: You do not have permission to perform this action.`));
      } else {
        thunkAPI.dispatch(addErrorMessage(`Error creating project: ${errorMessageContent.status} ${errorMessageContent.statusText}`));
      }
      return errorMessageContent;
    }
    
  }
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (updatedProjectData, thunkAPI) => {
    thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.LOADING));
    try {
      const response = await ProjectsApiService.updateProject(updatedProjectData);
      if (response.status === 200) {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.SUCCESS));
        thunkAPI.dispatch(setSelectedProject(response.data));
      } else {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 403) {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.SUCCESS));
      } else {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      }
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      thunkAPI.dispatch(addErrorMessage(`Error updating project: ${errorMessageContent.data.detail}`));
      return errorMessageContent;
    }
    
  }
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async ({ slug, id }, thunkAPI) => {
    thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.LOADING));
    try {
      const response = await ProjectsApiService.deleteProject(slug, id);
      if (response.status === 204) {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.SUCCESS));
        thunkAPI.dispatch(setSelectedProject(null));
      } else {
        thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      }
      return {
        status: response.status,
        data: response.data
      };
    } catch (error) {
      thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.ERROR));
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      thunkAPI.dispatch(addErrorMessage(`Error deleting project: ${errorMessageContent.data.detail}`))
      return errorMessageContent;
    }
  }
);

export const updateProjectAssignmentRoles = createAsyncThunk(
  'projects/updateProjectAssignmentRoles',
  async ({project_id, rawUsersData}, thunkAPI) => {
    thunkAPI.dispatch(setSelectedProjectLoadingStatus(requestStatuses.LOADING));
    try {
      // Transform user assignments into the right format for the endpoing
      const alteredUsersData = rawUsersData.map((userAssignment) => {
        let action = '';
        if (userAssignment.currently_in_project && !userAssignment.updated_in_project) {
          action = 'remove';
        } else if (!userAssignment.currently_in_project && userAssignment.updated_in_project) {
          action = 'add';
        } else if (userAssignment.current_permission_level !== userAssignment.updated_permission_level) {
          action = 'modify';
        }
        return {
          email: userAssignment.email,
          action: action,
          permission_level: userAssignment.updated_permission_level ?? 'ReadAllWriteOwn',
        };
      });
      // Abort if this change would lead to the project having no project editors
      const willHaveAtLeastOneProjectEditor = alteredUsersData.some((userItem) => userItem.permission_level === 'ProjectEditor')
      if (!willHaveAtLeastOneProjectEditor) {
        const errorMessageContent = {
          status: 'Error Updating Project',
          statusText: 'Error Updating Project',
          data: 'A project must always have at least one Project Editor',
        };
        thunkAPI.dispatch(addErrorMessage(`Error updating project: A project must always have at least one Project Editor`));
        return errorMessageContent;
      }
      const filteredUsersData = alteredUsersData.filter((userAssignment) => userAssignment.action !== '');
      const response = await ProjectsApiService.updateProjectAssignmentRoles(project_id, filteredUsersData);
      return response.data;
    } catch (error) {
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      thunkAPI.dispatch(addErrorMessage(`Error altering project users: ${errorMessageContent.status}`))
      return errorMessageContent;
    }
  }
);

export const assignWetlandFormsToProject = createAsyncThunk(
  'projects/assignWetlandFormsToProject',
  async ({dataFormIds, projectUuid}, thunkAPI) => {
    try {
      const response = await ProjectsApiService.updateWetlandFormsAssignedProject(dataFormIds, projectUuid);
      return {status: response.status, data: response.data};
    } catch (error) {
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      thunkAPI.dispatch(addErrorMessage(`Error assigning the data forms to the chosen project: ${errorMessageContent.status}`))
      return errorMessageContent;
    }
  }
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProjectsLoadingStatus: (state, action) => {
      state.loading_status = action.payload;
    },
    setProjectsSorting: (state, action) => {
      const sort_by = action.payload.sort_by;
      state.projects_sort_by = action.payload.sort_by;
      state.projects_sort_order = action.payload.sort_order;
      if (state.projects) {
        let projectsSort = [...state.projects];
        if (action.payload.sort_order === 'ASC') {
          state.projects = projectsSort.sort((a, b) => (a[sort_by] > b[sort_by]) ? 1 : (a[sort_by] < b[sort_by]) ? -1 : 0);
        } else {
          // state.projects = projectsSort.sort((a, b) => b[action.payload.sort_by] - a[action.payload.sort_by]);
          state.projects = projectsSort.sort((a, b) => (a[sort_by] < b[sort_by]) ? 1 : (a[sort_by] > b[sort_by]) ? -1 : 0);
        }
      }
    },
    setProjectsFilterText: (state, action) => {
      state.projects_filter_text = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selected_project = action.payload;
    },
    setSelectedProjectLoadingStatus: (state, action) => {
      state.selected_project_loading_status = action.payload;
    },
    changeSelectedProjectValue: (state, action) => {
      // Here, payload is an object with name (name of item to change) and value (new value of this item)
      let name = action.payload.name;
      state.selected_project[name] = action.payload.value;
    },
    
  },
});

export const { setProjects, setProjectsLoadingStatus, setProjectsSorting, setProjectsFilterText, setSelectedProject, setSelectedProjectLoadingStatus, changeSelectedProjectValue } = projectsSlice.actions;

export const selectProjects = (state) => state.projects.projects;
export const selectProjectsWithOnlyEssentialAttributes = (state) => {
  // Removes all the extra lists including the users, etc.
  if (state.projects.projects) return state.projects.projects.map(({assigned_users, assignment_projects, created_by, ...attribsToKeep}) => attribsToKeep);
};
export const selectProjectsLoadingStatus = (state) => state.projects.loading_status;
export const selectProjectsSortOrder = (state) => state.projects.projects_sort_order;
export const selectProjectsSortBy = (state) => state.projects.projects_sort_by;
export const selectProjectsFilterText = (state) => state.projects.projects_filter_text;
export const selectSelectedProject = (state) => state.projects.selected_project;
export const selectSelectedProjectLoadingStatus = (state) => state.projects.selected_project_loading_status;

export default projectsSlice.reducer;