import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentWetlandFormShowFiveStrataToggle,
  selectCurrentWetlandFormShowSubregionIndOverrideToggle,
  selectCurrentFormShowFifthStrata,
  selectCurrentWetlandFormRegion,
  selectCurrentWetlandForm,
  selectCurrentWetlandFormUseSubregionIndicatorIverrides,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import StratumSection from "./components/StratumSection";
import { VegStratum } from "../../../utils/formConstants";
import FiveStrataToggle from "./components/FiveStrataToggle";
import SubregionIndOverrideSwitch from "./components/SubregionIndOverrideSwitch";
import { Grid } from "@mui/material";
import VegCalculationWorksheets from "./components/VegCalculationWorksheets";
import VegCalculationResults from "./components/VegCalculationResults";
import ConditionalVegetationFields from "./components/ConditionalVegetationFields";
import { agcpWesternGulfCoastalPlainSubregionIndicators, ncneGreatLakesSubregionIndicators } from "../../../utils/constants/subregionIndicatorOverrides";

async function loadPlantsFromRegion(aceRegion) {
  let url;
  switch (aceRegion) {
    case "AGCP":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_AGCP.json";
      break;
    case "AK":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_AK.json";
      break;
    case "AW":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_AW.json";
      break;
    case "CB":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_CB.json";
      break;
    case "EMP":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_EMP.json";
      break;
    case "GP":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_GP.json";
      break;
    case "HI":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_HI.json";
      break;
    case "MW":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_MW.json";
      break;
    case "NCNE":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_NCNE.json";
      break;
    case "WMVC":
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_WMVC.json";
      break;
    default:
      url = "https://bioapp-nwpl.s3.us-east-2.amazonaws.com/ace_plants_NCNE.json";
      break;
  }
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

export default function VegetationTab() {
  const showFiveStrataToggle = useSelector(selectCurrentWetlandFormShowFiveStrataToggle);
  const useFiveStrata = useSelector(selectCurrentFormShowFifthStrata);
  const showSubregionIndOverrideToggle = useSelector(selectCurrentWetlandFormShowSubregionIndOverrideToggle);

  const aceRegion = useSelector(selectCurrentWetlandFormRegion);
  const useSubregionIndicatorOverrides = useSelector(selectCurrentWetlandFormUseSubregionIndicatorIverrides);

  const [plantsList, setPlantsList] = useState([]);

  useEffect(() => {
    // Fetch plants list here so that it is only done once, and not every time an autocomplete text field obtains focus.
    (async () => {
      const plants = await loadPlantsFromRegion(aceRegion);
      // If using subregion override, and in proper region, use override indicators
      if (useSubregionIndicatorOverrides === true && (aceRegion === 'AGCP' || aceRegion === 'NCNE')) {
        if (aceRegion === 'NCNE') {
          ncneGreatLakesSubregionIndicators.forEach(overridePlant => {
            const objIndex = plants.findIndex((plant => plant.sciName === overridePlant.sciName));
            plants[objIndex].indicator = overridePlant.indicator;
          });
        } else {
          agcpWesternGulfCoastalPlainSubregionIndicators.forEach(overridePlant => {
            const objIndex = plants.findIndex((plant => plant.sciName === overridePlant.sciName));
            plants[objIndex].indicator = overridePlant.indicator;
          });
        }
      }
      const sortedPlants = plants.sort((a, b) => a.family === '' ? -1 : a.family > b.family ? 1 : -1);
      setPlantsList(sortedPlants);
    })();

  }, []);
  

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={8}>
        {showFiveStrataToggle && <FiveStrataToggle />}
        {showSubregionIndOverrideToggle && <SubregionIndOverrideSwitch />}
        <StratumSection stratum={VegStratum.Tree} plantsList={plantsList} />
        <StratumSection stratum={VegStratum.Shrub} plantsList={plantsList} />
        {useFiveStrata && <StratumSection stratum={VegStratum.Sapling} plantsList={plantsList} />}
        <StratumSection stratum={VegStratum.Herb} plantsList={plantsList} />
        <StratumSection stratum={VegStratum.Vine} plantsList={plantsList} />
        <ConditionalVegetationFields />
      </Grid>
      <Grid item sm={12} md={4}>
        <VegCalculationWorksheets />
      </Grid>
      <Grid item xs={12}>
        <VegCalculationResults />
      </Grid>
    </Grid>
  );
}
