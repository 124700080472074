import React from "react";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentWetlandFormValue, selectCurrentFormShowFifthStrata } from "../../../../api/services/wetlandforms/wetlandFormsSlice";

export default function FiveStrataToggle() {
  const useFiveStrata = useSelector(selectCurrentFormShowFifthStrata);
  const dispatch = useDispatch();

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.checked }));
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            id="useFiveStrata"
            checked={useFiveStrata}
            onChange={handleSwitchInputFormValueChange}
          />
        }
        label="Use five strata (adds sapling strata)?"
      />
    </FormGroup>
  );
}
