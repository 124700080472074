import { Box, Button, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import PropTypes from "prop-types";
import { format } from "date-fns";

SubscriptionReceiptList.propTypes = {
  purchaseEvents: PropTypes.array,
  loading: PropTypes.bool,
  handleGenerateReceiptPdf: PropTypes.func.isRequired,
};

export default function SubscriptionReceiptList({ purchaseEvents, loading=false, handleGenerateReceiptPdf, ...props }) {

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={40} color="info" sx={{ m: 2 }} />
      </Box>
    );
  }

  if (purchaseEvents == null) {
    return (
      <Typography>No purchase receipts loaded.</Typography>
    );
  }

  if (!Array.isArray(purchaseEvents) || !purchaseEvents.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={2}
      >
        <Typography color="text.secondary">No purchase receipts.</Typography>
      </Box>
    );
  }

  return (
    <List>
      {purchaseEvents.map((purchaseEvent, index) => {
        const receiptName = `${format(new Date(purchaseEvent.purchased_at), "MMMM do, yyyy")}`;
        return (
          <ListItem key={`purchase-event-${index}`}>
            <ListItemText primary={receiptName || ""} />
            <Button
              variant="contained"
              color="info"
              sx={{ mx: 2 }}
              onClick={(e) => handleGenerateReceiptPdf(e, purchaseEvent)}
            >
              PDF
            </Button>
          </ListItem>
        );
      })}
    </List>
  )
}
