import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestStatuses } from '../../../utils/constants';
import axiosInstance from '../../auth';
import UserApiService from './userAPI';

const initialState = {
  status: requestStatuses.IDLE,
  user: null,
  organization: null,
  orgMembership: null,
  accessibleOrgMembers: null,
  access_token: localStorage.getItem('access_token'),
  refresh_token: localStorage.getItem('refresh_token'),
  purchaseEvents: null,
};

export const getUser = createAsyncThunk(
  'user/getUser',
  async (_, thunkAPI) => {
    const response = await UserApiService.getUser();
    // if (response.type !== 'user/getUser/rejected') {
    //   thunkAPI.dispatch(setUser(response.payload));
    // }
    return response.data;
  }
);

export const getUserMembership = createAsyncThunk(
  'user/getUserMembership',
  async (_, thunkAPI) => {
    const response = await UserApiService.getUserMembership();
    return {
      status: response?.status ?? null,
      data: response.data,
    };
  }
);

export const getUserOrganization = createAsyncThunk(
  'user/getUserOrganization',
  async (_, thunkAPI) => {
    const response = await UserApiService.getUserOrganization();
    return {
      status: response?.status ?? null,
      data: response.data,
    };
  }
);

export const userCreateOrganization = createAsyncThunk(
  'user/userCreateOrganization',
  async (orgName, thunkAPI) => {
    const response = await UserApiService.createOrganization(orgName);
    return {
      status: response?.status ?? null,
      data: response.data,
    };
  }
);

export const listOrganizationMembers = createAsyncThunk(
  'user/listOrganizationMembers',
  async (_, thunkAPI) => {
    const response = await UserApiService.listOrganizationMembers();
    return {
      status: response?.status ?? null,
      data: response.data,
    };
  }
);

export const inviteUserToOrganization = createAsyncThunk(
  'user/inviteUserToOrganization',
  async (email, thunkAPI) => {
    try {
      // The email address has already been validated.
      const response = await UserApiService.inviteUserToOrganization({'email': email});
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      const errorMessageContent = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      return errorMessageContent;
    }
  }
);

export const requestToJoinOrganization = createAsyncThunk(
  'user/requestToJoinOrganization',
  async (organizationInfo, thunkAPI) => {
    // Input is an object that contains the orgName and orgUuid
    const response = await UserApiService.requestToJoinOrganization({'name': organizationInfo.orgName, 'uuid': organizationInfo.orgUuid});
    return {
      status: response.status,
      data: response.data,
    };
  }
);

export const respondToOrganizationInvite = createAsyncThunk(
  'user/respondToOrganizationInvite',
  async (userResponse, thunkAPI) => {
    const response = await UserApiService.respondToOrganizationInvite({'response': userResponse.response, 'email': userResponse.email});
    return {
      status: response.status,
      data: response.data,
    };
  }
);

export const attemptPromoteOrDemoteUser = createAsyncThunk(
  'user/attemptPromoteOrDemoteUser',
  async (userToModify, thunkAPI) => {
    // The user argument is the user that is to be promoted or demoted
    // User can try submitting any email, but will only be able to change the user's role if they are an organization manager
    // If the user is a regular user, attempts to promote them, otherwise will demote them.
    let response;
    if (userToModify.org_user_role === "regular") {
      response = await UserApiService.promoteOrDemoteUser({'email': userToModify.email, 'action': 'promote'});
    } else {
      response = await UserApiService.promoteOrDemoteUser({'email': userToModify.email, 'action': 'demote'});
    }
    return {
      status: response.status,
      data: response.data,
    };
  }
);

export const userLeaveOrganization = createAsyncThunk(
  'user/userLeaveOrganization',
  async (email, thunkAPI) => {
    // User can try submitting any email, but will only be able to make the associated account leave the org if they have access permission
    const response = await UserApiService.userLeaveOrganization({'email': email});
    return {
      status: response.status,
      data: response.data,
    };
  }
);

// export const signInUser = createAsyncThunk(
//   'user/signInUser',
//   async (amount) => {
//     const response = await attemptUserLogin(amount);
//     return response.data;
//   }
// );

export const getUserSubscriptionEvents = createAsyncThunk(
  'user/getUserSubscriptionEvents',
  async (_, thunkAPI) => {
    const response = await UserApiService.getUserSubscriptionEvents();
    return {
      status: response?.status ?? null,
      data: response.data,
    };
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setOrgMembership: (state, action) => {
      state.orgMembership = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    setAccessibleOrgMembers: (state, action) => {
      state.accessibleOrgMembers = action.payload;
    },
    setAuthTokens: (state, action) => {
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
    },
    resetAuthTokens: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers['Authorization'] = null;
      state.access_token = null;
      state.refresh_token = null;
      state.user = null;
      state.organization = null;
      state.orgMembership = null;
      state.status = requestStatuses.IDLE;
    },
    setPurchaseEvents: (state, action) => {
      state.purchaseEvents = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(signInUser.pending, (state) => {
  //       state.status = loginStatuses.LOADING;
  //     })
  //     .addCase(signInUser.fulfilled, (state, action) => {
  //       state.status = loginStatuses.IDLE;
  //       state.value += action.payload;
  //     });
  // },
});

export const { setUser, setOrgMembership, setOrganization, setAccessibleOrgMembers, setAuthTokens, resetAuthTokens, setPurchaseEvents } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectOrgMembership = (state) => state.user.orgMembership;
export const selectOrganization = (state) => state.user.organization;
export const selectAccessibleOrgMembers = (state) => state.user.accessibleOrgMembers;
export const selectAccessToken = (state) => state.user.access_token ?? localStorage.getItem('access_token');
export const selectValidRefreshToken = (state) => {
  let refreshToken = state.user.refresh_token ?? localStorage.getItem('refresh_token');
  if (refreshToken && refreshToken !== undefined) {
    const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
    const now = Math.ceil(Date.now() / 1000);
    if (tokenParts.exp > now) {
      return refreshToken;
    }
  }
  return null;
}
export const selectPurchaseEvents = (state) => state.user.purchaseEvents;

export default userSlice.reducer;