import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Divider, Grid, Typography } from "@mui/material";
import { selectCurrentWetlandForm, selectCurrentWetlandFormPrevIndTotal, selectCurrentWetlandPrevIndexAsRoundedString } from "../../../../api/services/wetlandforms/wetlandFormsSlice";

function PrevIndexWorksheetRow({ indName, cover, multiplyFactor }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <Typography>{indName} species</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color="secondary" sx={{ fontWeight: "bold" }}>
          {cover}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography> x {multiplyFactor} = </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color="secondary" sx={{ fontWeight: "bold" }}>
          {cover * multiplyFactor.toFixed(2)}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default function VegCalculationWorksheets() {
  const currentForm = useSelector(selectCurrentWetlandForm);
  const prevIndTotal = useSelector(selectCurrentWetlandFormPrevIndTotal);
  const prevInd = useSelector(selectCurrentWetlandPrevIndexAsRoundedString);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
          Dominance Test Worksheet
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography># dominant OBL, FACW, or FAC species:</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography display="inline" color="secondary" mr={1} sx={{ fontWeight: "bold" }}>
          {currentForm?.domTestA}
        </Typography>
        <Typography display="inline">(A)</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>Total # of dominant species across all strata:</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography display="inline" color="secondary" mr={1} sx={{ fontWeight: "bold" }}>
          {currentForm?.domTestB}
        </Typography>
        <Typography display="inline">(B)</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>% of dominant species that are OBL, FACW, or FAC:</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography display="inline" color="secondary" mr={1} sx={{ fontWeight: "bold" }}>
          {currentForm?.domTestABPercent ?? "N/A"}
        </Typography>
        <Typography display="inline">(A/B)</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
          Prevalence Index Worksheet
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Total % Cover of:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Multiplied by:</Typography>
      </Grid>

      <PrevIndexWorksheetRow indName="OBL" cover={currentForm?.prevIndObl} multiplyFactor={1} />
      <PrevIndexWorksheetRow indName="FACW" cover={currentForm?.prevIndFacw} multiplyFactor={2} />
      <PrevIndexWorksheetRow indName="FAC" cover={currentForm?.prevIndFac} multiplyFactor={3} />
      <PrevIndexWorksheetRow indName="FACU" cover={currentForm?.prevIndFacu} multiplyFactor={4} />
      <PrevIndexWorksheetRow indName="UPL" cover={currentForm?.prevIndUpl} multiplyFactor={5} />
      <Divider />
      <Grid item xs={6}>
        <Typography>Column Totals:</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color="secondary" display="inline" mr={1} sx={{ fontWeight: "bold" }}>
          {currentForm?.prevIndTotal}
        </Typography>
        <Typography display="inline">(A)</Typography>
      </Grid>
      <Grid item xs={2}>
      </Grid>
      <Grid item xs={2}>
      <Typography color="secondary" display="inline" mr={1} sx={{ fontWeight: "bold" }}>
          {prevIndTotal}
        </Typography>
        <Typography display="inline">(B)</Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography display="inline" mr={1}>Prevalence Index = (B/A) = </Typography>
        <Typography color="secondary" display="inline" mr={1} sx={{ fontWeight: "bold" }}>{prevInd ?? "N/A"}</Typography>
      </Grid>
    </Grid>
  );
}
