// export const aceRegions = Object.freeze({
//   "AGCP": 'Atlantic and Gulf Coastal Plain',
//   "AK": 'Alaska',
//   "AW": 'Arid West',
//   "CB": 'Caribbean Islands',
//   "EMP": 'Eastern Mountains and Piedmont',
//   "GP": 'Great Plains',
//   "HI": 'Hawaii and the Pacific Islands',
//   "MW": 'Midwest',
//   "NCNE": 'Northcentral and Northeast',
//   "WMVC": 'Western Mountains, Valleys, and Coasts',
//   "Unspecified": 'Unspecified',
// });

// class AceRegion {
//   static AGCP = new AceRegion('AGCP', 'Atlantic and Gulf Coastal Plain');
//   static AK = new AceRegion('AK', 'Alaska');
//   static AW = new AceRegion('AW', 'Arid West');
//   static CB = new AceRegion('CB', 'Caribbean Islands');
//   static EMP = new AceRegion('EMP', 'Eastern Mountains and Piedmont');
//   static GP = new AceRegion('GP', 'Great Plains');
//   static HI = new AceRegion('HI', 'Hawaii and the Pacific Islands');
//   static MW = new AceRegion('MW', 'Midwest');
//   static NCNE = new AceRegion('NCNE', 'Northcentral and Northeast');
//   static WMVC = new AceRegion('WMVC', 'Western Mountains, Valleys, and Coasts');
//   static Unspecified = new AceRegion('Region', 'Unspecified');

//   constructor(abbreviation, name) {
//     this.abbreviation = abbreviation;
//     this.name = name;
//   }
//   toString() {
//     return `AceRegion.${this.abbreviation}: ${this.name}`;
//   }
// }

export const landformTypes = {
  None: "None",
  Backslope: "Backslope",
  Baseslope: "Baseslope",
  Channel: "Channel",
  Crest: "Crest",
  Depression: "Depression",
  Dip: "Dip",
  Ditch: "Ditch",
  Flat: "Flat",
  Floodplain: "Floodplain",
  Footslope: "Footslope",
  FreeFace: "Free Face",
  Headslope: "Headslope",
  Hillslope: "Hillslope",
  Interfluve: "Interfluve",
  Noseslope: "Noseslope",
  Rise: "Rise",
  Riser: "Riser",
  Shoulder: "Shoulder",
  Sideslope: "Sideslope",
  Slope: "Slope",
  Summit: "Summit",
  Swale: "Swale",
  Talf: "Talf",
  Terrace: "Terrace",
  Toeslope: "Toeslope",
  Tread: "Tread",
  Undulating: "Undulating",
  Other: "Other",
};

export const reliefTypes = ["", "Concave", "Convex", "Microtopography", "None"];

export const slopeTypes = ["", "0-2", "3-7", "8-15", "16-25", "20-60", "60+"];

export const VegStratum = {
  Tree: "tree",
  Sapling: "sapling",
  Shrub: "shrub",
  Herb: "herb",
  Vine: "vine",
};

export const VegStratumAbbreviations = ['t', 'sap', 'shr', 'h', 'v'];

export function vegStratumToString(stratum) {
  switch (stratum) {
    case VegStratum.Tree:
      return "tree";
    case VegStratum.Sapling:
      return "sapling";
    case VegStratum.Shrub:
      return "shrub";
    case VegStratum.Herb:
      return "herb";
    case VegStratum.Vine:
      return "vine";
    default:
      return "tree";
  }
}

export function vegStratumToAbbrevString(stratum) {
  switch (stratum) {
    case VegStratum.Tree:
      return "t";
    case VegStratum.Sapling:
      return "sap";
    case VegStratum.Shrub:
      return "shr";
    case VegStratum.Herb:
      return "h";
    case VegStratum.Vine:
      return "v";
    default:
      return "t";
  }
}

export function vegStratumToStringCapitalized(stratum) {
  switch (stratum) {
    case VegStratum.Tree:
      return "Tree";
    case VegStratum.Sapling:
      return "Sapling";
    case VegStratum.Shrub:
      return "Shrub";
    case VegStratum.Herb:
      return "Herb";
    case VegStratum.Vine:
      return "Vine";
    default:
      return "Tree";
  }
}

export const formStatus = {
  Waiting: "waiting",
  InProgress: "inProgress",
  Complete: "complete",
};

export function formStatusToString(item) {
  switch (item) {
    case formStatus.Waiting:
      return "Waiting";
    case formStatus.InProgress:
      return "In Progress";
    case formStatus.Complete:
      return "Complete";
    default:
      return "Waiting";
  }
}

export function formStatusColor(item) {
  switch (item) {
    case formStatus.Waiting:
      return null;
    case formStatus.InProgress:
      return '#FF9800';
    case formStatus.Complete:
      return '#4caf50';
    default:
      return null;
  }
}
