import React from 'react';
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
} from "@mui/material";

export default function ErrorFallback() {
  return (
    <Box style={{ padding: "32px" }}>
      <Card style={{ padding: "32px" }}>
        <Stack direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}>
          <Typography fontSize={24}>An uncaught error occurred - please re-load the page.</Typography>
          <Link href="/" color="primary">Back to Home Page</Link>
        </Stack>
      </Card>
    </Box>
  )
}
