import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Popper,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { VegStratum, vegStratumToString } from "../../../../utils/formConstants";
import {
  calcDominantPlants,
  deleteWetlandPlant,
  hydrophyticCalcs,
  selectCurrentWetlandFormRegion,
  setWetlandPlantCover,
  setWetlandPlantIndicator,
  setWetlandPlantName,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { colorGreyLight } from "../../../../utils/colors";
import { styled, lighten, darken } from '@mui/system';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const AutocompletePopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.sciName} ${option.commName} ${option.family}`,
  // stringify: (option) => option.sciName,
});

export default function PlantEntry({ stratum = VegStratum.Tree, plant, index, options = [] }) {
  const dispatch = useDispatch();
  const aceRegion = useSelector(selectCurrentWetlandFormRegion);
  // const useSubregionIndicatorOverrides = useSelector(selectCurrentWetlandForm).useSubregionIndicatorOverrides;

  const baseElementName = `${vegStratumToString(stratum)}-plant-${index}`;
  const [autocompleteOpen, setOpen] = useState(false);
  const [autocompleteOptions, setOptions] = useState([]);
  const autocompleteLoading = autocompleteOpen && autocompleteOptions.length === 0;

  // TODO: after fetching species list, save in state to keep from slow re-fetch every time a user edits an autocomplete textfield

  useEffect(() => {
    // let active = true;

    if (!autocompleteLoading) {
      return undefined;
    }

    // console.log(options);

    if (plant.sciName.length > 1) {
      setOptions([...options.filter((item) => item.sciName.toLowerCase().includes(plant.sciName.toLowerCase()))]);
    }

    // return () => {
    //   active = false;
    // };
  }, [autocompleteLoading, plant, aceRegion]);

  useEffect(() => {
    if (!autocompleteOpen) {
      setOptions([]);
    }
  }, [autocompleteOpen]);

  const doPlantCalculations = ({ sortList }) => {
    dispatch(calcDominantPlants({ sortList }));
    dispatch(hydrophyticCalcs());
  };

  const handleSciNameChange = (e) => {
    if (e !== null) {
      dispatch(setWetlandPlantName({ index: index, sciName: e.target.value }));
    }
  };

  const handleSciNameSelect = (e, newValue) => {
    if (typeof newValue === "string") {
      dispatch(setWetlandPlantName({ index: index, sciName: newValue }));
      if (options && newValue && newValue != null) {
        if (newValue.length > 1) {
          setOptions([...options.filter((item) => item.sciName.toLowerCase().includes(newValue.toLowerCase()))]);
        }
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      dispatch(setWetlandPlantName({ index: index, sciName: newValue.inputValue }));
      if (newValue.inputValue && newValue.inputValue != null) {
        if (newValue.inputValue.length > 1) {
          setOptions([...options.filter((item) => item.sciName.toLowerCase().includes(newValue.inputValue.toLowerCase()))]);
        }
      }
    } else {
      dispatch(setWetlandPlantName({ index: index, sciName: newValue?.sciName ?? "" }));
      dispatch(setWetlandPlantIndicator({ index: index, indicator: newValue?.indicator ?? "" }));
      if (newValue && newValue != null) {
        if (options && newValue.length > 1) {
          setOptions([...options.filter((item) => item.sciName.toLowerCase().includes(newValue.toLowerCase()))]);
        }
      } else {
        // newValue is null when the autocomplete is cleared (or the last character is deleted). Should set back to the full options list
        setOptions([...options]);
      }
    }
    doPlantCalculations({ sortList: false });
  };

  const handleIndicatorChange = (e, newValue) => {
    if (e !== null) {
      dispatch(setWetlandPlantIndicator({ index: index, indicator: e.target.value }));
      doPlantCalculations({ sortList: false });
    }
  };

  const handleIndicatorSelect = (e, newValue) => {
    dispatch(setWetlandPlantIndicator({ index: index, indicator: newValue }));
    doPlantCalculations({ sortList: false });
  };

  const handleCoverChange = (e) => {
    if (e !== null) {
      dispatch(setWetlandPlantCover({ index: index, cover: e.target.value }));
      doPlantCalculations({ sortList: false });
    }
  };

  const handleDeletePlant = (e) => {
    e.preventDefault();
    dispatch(deleteWetlandPlant(index));
    doPlantCalculations({ sortList: true });
  };

  return (
    <Grid key={baseElementName} container item spacing={4}>
      <Grid item sm={4} md={4}>
        <Autocomplete
          id={`${baseElementName}-sciname`}
          open={autocompleteOpen}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          openOnFocus
          autoComplete
          freeSolo
          groupBy={(option) => option.family}
          filterOptions={filterOptions}
          // filterOptions={(options, value) => options.filter((option) => option.sciName.toLowerCase() === value.sciName.toLowerCase())} // TODO: override bas filter options, search by common name/family as well
          isOptionEqualToValue={(option, value) => option.sciName === value.sciName}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "[option]" option, created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.sciName;
          }}
          options={autocompleteOptions}
          loading={autocompleteLoading}
          value={plant.sciName}
          onChange={handleSciNameSelect} // onChange here is more akin to an onSelect
          onBlur={(e) => doPlantCalculations({ sortList: true })}
          onInputChange={handleSciNameChange}
          PopperComponent={AutocompletePopper}
          renderOption={(props, option) => {
            return (
              <Box {...props}>
                <Typography>{option.sciName}</Typography>
                {option.commName && <Typography color={colorGreyLight}>&nbsp;- {option.commName}</Typography>}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Scientific Name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      </Grid>
      <Grid item sm={2} md={2}>
        <Autocomplete
          id={`${baseElementName}-indicator`}
          freeSolo
          openOnFocus
          isOptionEqualToValue={(option, value) => option === value}
          options={["OBL", "FACW", "FAC", "FACU", "UPL"]}
          filterOptions={(x) => x}
          value={plant.indicator ?? ""}
          onChange={handleIndicatorSelect} // onChange here is more akin to an onSelect
          onInputChange={handleIndicatorChange}
          onBlur={(e) => doPlantCalculations({ sortList: true })}
          renderInput={(params) => <TextField {...params} label="Indicator" style={{ textOverflow: "none" }} />}
          disableClearable
        />
      </Grid>
      <Grid item sm={2} md={2}>
        <TextField
          id={`${baseElementName}-cover`}
          name="cover"
          label="Cover"
          type="number"
          value={plant.cover ?? ""}
          onChange={handleCoverChange}
          onBlur={(e) => doPlantCalculations({ sortList: true })}
        />
      </Grid>
      <Grid item xs={2} alignSelf="center">
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={plant.dominant} disabled inputProps={{ "aria-label": "controlled" }} />}
            label="Dominant?"
            labelPlacement="top"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={1} alignSelf="center">
        <Button variant="contained" color="error" onClick={handleDeletePlant}>
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
}