export const formActionType = {
  ADD: 'add',
  EDIT: 'edit',
}

export const aceRegions = Object.freeze([
  "AGCP",
  "AK",
  "AW",
  "CB",
  "EMP",
  "GP",
  "HI",
  "MW",
  "NCNE",
  "WMVC",
  "All"
]);

export const requestStatuses = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
}