import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

DeleteFormsButton.propTypes = {
  numberOfFormsToDelete: PropTypes.number.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default function DeleteFormsButton(props) {
  const { numberOfFormsToDelete, buttonDisabled, onConfirm } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAskToDeleteForms = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  return (
    <Fragment>
      <Button variant="contained" color="error" disabled={buttonDisabled} onClick={handleAskToDeleteForms}>
        <DeleteIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete {numberOfFormsToDelete} Form(s)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {numberOfFormsToDelete} form(s) will be permanently deleted. Ensure that all desired data has been backed up
            or exported before confirming this action!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
