import React from "react";
import { Stack, Switch, TextField, FormGroup, FormControlLabel } from "@mui/material";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandSoils,
  selectCurrentWetlandSoilsComment,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function SoilsSummary() {
  const wetlandSoils = useSelector(selectCurrentWetlandSoils);
  const soilsComment = useSelector(selectCurrentWetlandSoilsComment);
  const dispatch = useDispatch();

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.checked }));
  };

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  return (
    <Stack>
      <FormGroup>
        <FormControlLabel
          control={<Switch id="wetlandSoils" checked={wetlandSoils} onChange={handleSwitchInputFormValueChange} />}
          label="Wetland Soils"
          sx={{ mt: 4 }}
        />
      </FormGroup>
      <TextField
        id="soilsComment"
        label="Soils Comment"
        fullWidth
        multiline
        rows={4}
        value={soilsComment ?? ""}
        onChange={handleStandardFormValueChange}
        sx={{ mt: 2 }}
      />
    </Stack>
  );
}
