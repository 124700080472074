export function getSubregionIndicatorOverrides(region) {
  if (region === 'NCNE') return ncneGreatLakesSubregionIndicators;
  return agcpWesternGulfCoastalPlainSubregionIndicators;
}

export const ncneGreatLakesSubregionIndicators = [
  {
    "sciName": "Populus tremuloides",
    "indicator": "FAC",
  },
  {
    "sciName": "Rubus idaeus",
    "indicator": "FAC",
  },
];

export const agcpWesternGulfCoastalPlainSubregionIndicators = [
  {
    "sciName": "Aralia spinosa",
    "indicator": "FACU",
  },
  {
    "sciName": "Asimina triloba",
    "indicator": "FACU",
  },
  {
    "sciName": "Carya cordiformis",
    "indicator": "FACU",
  },
  {
    "sciName": "Carya laciniosa",
    "indicator": "FAC",
  },
  {
    "sciName": "Cornus florida",
    "indicator": "UPL",
  },
  {
    "sciName": "Diodia virginiana",
    "indicator": "FAC",
  },
  {
    "sciName": "Gelsemium sempervirens",
    "indicator": "FACU",
  },
  {
    "sciName": "Paspalum denticulatum",
    "indicator": "FACW",
  },
  {
    "sciName": "Rudbeckia maxima",
    "indicator": "FACW",
  },
  {
    "sciName": "Sambucus nigra",
    "indicator": "FAC",
  },
  {
    "sciName": "Urochloa reptans",
    "indicator": "FACU",
  },
  {
    "sciName": "Viburnum dentatum",
    "indicator": "FACU",
  },
];


export function getSubregionOriginalIndicators(region) {
  if (region === 'NCNE') return ncneOriginalIndicators;
  return agcpOriginalIndicators;
}

export const ncneOriginalIndicators = [
  {
    "sciName": "Populus tremuloides",
    "indicator": "FACU",
  },
  {
    "sciName": "Rubus idaeus",
    "indicator": "FACU",
  },
];

export const agcpOriginalIndicators = [
  {
    "sciName": "Aralia spinosa",
    "indicator": "FAC",
  },
  {
    "sciName": "Asimina triloba",
    "indicator": "FAC",
  },
  {
    "sciName": "Carya cordiformis",
    "indicator": "FAC",
  },
  {
    "sciName": "Carya laciniosa",
    "indicator": "FACW",
  },
  {
    "sciName": "Cornus florida",
    "indicator": "FACU",
  },
  {
    "sciName": "Diodia virginiana",
    "indicator": "FACW",
  },
  {
    "sciName": "Gelsemium sempervirens",
    "indicator": "FAC",
  },
  {
    "sciName": "Paspalum denticulatum",
    "indicator": "OBL",
  },
  {
    "sciName": "Rudbeckia maxima",
    "indicator": "FAC",
  },
  {
    "sciName": "Sambucus nigra",
    "indicator": "FACW",
  },
  {
    "sciName": "Urochloa reptans",
    "indicator": "UPL",
  },
  {
    "sciName": "Viburnum dentatum",
    "indicator": "FAC",
  },
];