import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import {
  changeCurrentWetlandFormSoilLayerItem,
  selectCurrentWetlandSoilLayers,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";

const redoxTypeOptions = {
  "": "Type",
  C: " (Concentration)",
  D: " (Depletion)",
  RM: " (Reduced Matrix)",
  MS: " (Masked Sand Grains)",
  CS: " (Covered or Coated Sand Grains - AK region only)",
};

const redoxLocationOptions = {
  "": "Location",
  M: " (Matrix)",
  PL: " (Pore Lining)",
  "M/PL": " (Matrix/Pore Lining)",
};

export default function SoilLayerRedoxInfoRow({ index }) {
  const soilLayers = useSelector(selectCurrentWetlandSoilLayers);
  const layer = soilLayers[index];
  const dispatch = useDispatch();

  const handleSoilLayerStandardItemChange = (e) => {
    dispatch(changeCurrentWetlandFormSoilLayerItem({ index: index, name: e.target.name, value: e.target.value }));
  };

  return (
    <Fragment>
      <Grid item xs={2} md={2}></Grid>
      <Grid item xs={4} md={4}>
        <FormControl fullWidth>
          <InputLabel id={`soil-layer-redox-type-${index}-label`}>Redox Type</InputLabel>
          <Select
            labelId={`soil-layer-redox-type-${index}-label`}
            id={`soil-layer-redox-type-${index}`}
            name="redoxType"
            value={layer.redoxType ?? ""}
            label="Redox Type"
            onChange={handleSoilLayerStandardItemChange}
          >
            {Object.entries(redoxTypeOptions).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {key} {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} md={4}>
        <FormControl fullWidth>
          <InputLabel id={`soil-layer-redox-location-${index}-label`}>Redox Location</InputLabel>
          <Select
            labelId={`soil-layer-redox-location-${index}-label`}
            id={`soil-layer-redox-location-${index}`}
            name="redoxLocation"
            value={layer.redoxLocation ?? ""}
            label="Redox Location"
            onChange={handleSoilLayerStandardItemChange}
          >
            {Object.entries(redoxLocationOptions).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {key} {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={2} md={2} alignSelf="center">
        <Typography>Redox Contrast:</Typography>
        <Typography fontWeight="bold">{layer.redoxDesc ?? ""}</Typography>
      </Grid> */}
    </Fragment>
  );
}
