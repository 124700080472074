import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Card, Link, Stack, Typography, List, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import BatchUploadPopupButton from './BatchUploadPopupButton';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: '24px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

export default function BatchUpload() {
  const theme = useTheme();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles(acceptedFiles);
  }, [])
  const {acceptedFiles, getRootProps, getInputProps, isFocused, isDragActive, isDragAccept, isDragReject, inputRef} = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? {borderColor: theme.palette.secondary.main} : {}),
    ...(isDragAccept ? {borderColor: theme.palette.success.main} : {}),
    ...(isDragReject ? {borderColor: theme.palette.error.main} : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.error.main,
  ]);

  const handleRemoveFile = (event) => {
    event.preventDefault();
    setSelectedFiles([]);
    inputRef.current.value = '';
  };

  const files = selectedFiles.map(file => (
    <Stack key={file.path} direction="row" alignItems="center" spacing={2}>
      <Typography>
        {file.path}
      </Typography>
      <Button
        variant="text"
        color="error"
        endIcon={<DeleteIcon />}
        onClick={handleRemoveFile}
        aria-label="remove the selected batch upload file"
      >
        Remove
      </Button>
    </Stack>
  ));

  return (
    <Stack>
      <Card sx={{ margin: 4 }}>
        <Stack spacing={2} p={2} alignItems="center">
          <Typography component="h2" variant="h4">
            Batch Update Data
          </Typography>
          <Box maxWidth="sm" alignItems="center">
            <Typography fontSize={14}>
              To make bulk edits of multiple data forms at once, upload a CSV file containing the modified data. To ensure your data upload is successful, please base your edits on our template form, which can be downloaded below.
            </Typography>
            <List dense={true}>
              <ListItem>
                <ListItemText primary="Remove any unused columns from this CSV before use." />
              </ListItem>
              <ListItem>
                <ListItemText primary='To prevent accidental data loss, blank fields will be ignored. To set a field as blank, use: [blank]' />
              </ListItem>
              <ListItem>
                <ListItemText primary='Please ensure that your data is backed up before performing this operation to mitigate potential data overwrites.' />
              </ListItem>
              <ListItem>
                <Link 
              href="https://bioapp-public-resources.s3.us-east-2.amazonaws.com/BioApp_batch_edit_template.csv" 
              color="secondary"
              aria-label="To download the bulk edit CSV template, click this link to download the file"
            >
              BioApp_batch_edit_template.csv
            </Link>
              </ListItem>
              <ListItem>
                <Link 
              href="https://bioapp-public-resources.s3.us-east-2.amazonaws.com/BioApp_batch_edit_template_example.csv" 
              color="secondary"
              aria-label="To download the bulk edit CSV template with pre-filled example data, click this link to download the file"
            >
              BioApp_batch_edit_template_example.csv
            </Link>
              </ListItem>
            </List>
          </Box>
          <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop file here</p> :
                <p>Drag and drop input file here, or click to select file</p>
            }
          </div>
          {files}
          <BatchUploadPopupButton
            selectedFiles={selectedFiles} 
            buttonDisabled={selectedFiles.length === 0}
          />
        </Stack>
      </Card>
    </Stack>
  );
}
