export function facNeutralCalc({ plants, domPlants }) {
  // This is called in the hydrophytic calcs so that on any plant calc change, FAC-neutral will reflect it properly
  // If list of dom plant species (without FAC plants) is more than 50% FACW or OBL, is met. If equal # of wet and dom plants, check non-doms
  // returns a bool of if the test is met or not.
  let facNeutral = false;
  const numWetDoms = domPlants.filter((plant) => plant.indicator === "FACW" || plant.indicator === "OBL").length;
  const numDryDoms = domPlants.filter((plant) => plant.indicator === "FACU" || plant.indicator === "UPL").length;
  if (numWetDoms > numDryDoms) {
    facNeutral = true;
  } else if (numDryDoms > numWetDoms) {
    facNeutral = false;
  } else {
    // If equal, consider non-doms
    const numWetAll = plants.filter((plant) => plant.indicator === "FACW" || plant.indicator === "OBL").length;
    const numDryAll = plants.filter((plant) => plant.indicator === "FACU" || plant.indicator === "UPL").length;
    if (numWetAll > numDryAll) {
      facNeutral = true;
    } else {
      facNeutral = false;
    }
  }
  return facNeutral;
}

export function indicator1Calc({ dominantPlants }) {
  // Indicator 1: rapid test for hydrophytic vegetation - all dominants are FACW or OBL
  // If the plants list is empty, the expression would evalutate to true, so has to be inside this if/else to check
  let rapidTest = false;
  if (dominantPlants.length) {
    rapidTest = dominantPlants.every((plant) => plant.indicator ==='OBL' || plant.indicator === 'FACW');
  } else {
    rapidTest = false;
  }
  return rapidTest;
}

export function indicator2Calc({ dominantPlants }) {
  // Indicator 2: Dominance test - more than 50% of dominants are FAC, FACW, or OBL
  // This returns an object with the domTestA, domTestB, domTestABPercent, and actual dominance test (dominanceTest) results, for updating the state.
  let output = { domTestA: null, domTestB: null, domTestABPercent: null, dominanceTest: false };
  const numDomPlants = dominantPlants.length;
  const numHydroDomPlants = dominantPlants.filter(
          (plant) => plant.indicator === 'FAC' || plant.indicator === 'FACW' || plant.indicator === 'OBL'
  ).length;
  output.domTestA = numHydroDomPlants.toString();
  output.domTestB = numDomPlants.toString();
  if (numDomPlants !== 0) {
    output.domTestABPercent = ((numHydroDomPlants / numDomPlants) * 100).toFixed(2);
    if (numHydroDomPlants / numDomPlants > 0.5) {
      output.dominanceTest = true;
    } else {
      output.dominanceTest = false;
    }
  } else {
    output.dominanceTest = false;
    output.domTestABPercent = '';
  }
  return output;
}

export function indicator3Calc({ plants }) {
  // Indicator 3: Prevalence index - prev index is a range from 1 to 5. A prev index of 3.0 or less indicates hydrophytic veg (only if wetland hydro and soils are met as well)
  let covSum = 0;
  let weightedCovSum = 0;
  let indicatorSums = {'UPL': 0, 'FACU': 0, 'FAC': 0, 'FACW': 0, 'OBL': 0};
  plants.forEach((plant) => {
    if (indicatorSums.hasOwnProperty(plant.indicator)) {
      const cover = parseFloat(plant.cover);
      if (cover) {
        covSum += cover;
        indicatorSums[plant.indicator] += cover;
      }
    }
  });
  const prevIndTotal = Object.values(indicatorSums).reduce((sum, element) => sum + element); // Sum of all the indicatorSums items
  weightedCovSum += indicatorSums['OBL'];
  weightedCovSum += indicatorSums['FACW'] * 2.0;
  weightedCovSum += indicatorSums['FAC'] * 3.0;
  weightedCovSum += indicatorSums['FACU'] * 4.0;
  weightedCovSum += indicatorSums['UPL'] * 5.0;

  let output = {...indicatorSums, prevIndTotal: prevIndTotal, prevalenceIndex: null, prevalenceIndexMet: false};

  // Handling a couple edge cases - where covSum is 0
  if (covSum === 0) {
    output.prevalenceIndex = null;
    return output;
  }
  output.prevalenceIndex = weightedCovSum / covSum;
  if (output.prevalenceIndex === null) {
    output.prevalenceIndexMet = false;
    return output;
  } else {
    output.prevalenceIndexMet = output.prevalenceIndex <= 3.0 ? true : false;
    return output;
  }
}

export function indicator4Calc() {
  // Indicator 4: Morphological adaptations - if user indicates morphological adaptations are
  // present, they are prompted to reassign the indicator of 1 or more species from FACU to FAC.
  // The calculations will then be re-run to check indicator calcs 2 and 3.
  // As such, there is no actual calculation that has to be done in this indicator test.
  // TODO: create a hint button explaining to the user how it works, and implement in UI
  return false;
}