import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formActionType } from "../../../utils/constants";
import WetlandFormsApiService from "./wetlandFormsAPI";
import SpatialDataApiService from "./spatialDataAPI";
import { soilColors } from "../../../resources/soilColors";
import { VegStratumAbbreviations, vegStratumToAbbrevString, vegStratumToString } from "../../../utils/formConstants";
import axios from "axios";
import { hydroIndicators } from "../../../resources/hydroIndicators";
import { facNeutralCalc, indicator1Calc, indicator2Calc, indicator3Calc } from "./hydrophyticVegCalcFunctions";
import { getRedoxContrast } from "./redoxContrastCalcs";
import {
  getSubregionIndicatorOverrides,
  getSubregionOriginalIndicators,
} from "../../../utils/constants/subregionIndicatorOverrides";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

export const formsLoadingStatus = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
};

const initialState = {
  status: formsLoadingStatus.IDLE,
  error: null,
  region: localStorage.getItem("ace_region") ?? "All",
  forms: null,
  selectedForms: [],
  currentForm: null,
  currentFormActionType: formActionType.ADD,
};

export const getAllWetlandFormsAsync = createAsyncThunk("wetlandForms/getAllWetlandForms", async ({ filterByCurrentRegion=false }, thunkAPI) => {
  const currState = thunkAPI.getState();
  const currRegion = currState.wetlandForms.region;
  const queryParams = (currRegion !== "All" && filterByCurrentRegion === true) ? `?aceRegion=${currRegion}` : "";
  const response = await WetlandFormsApiService.getAll(queryParams);
  return response.data;
});

export const getProjectWetlandFormsAsync = createAsyncThunk("wetlandForms/getProjectWetlandForms", async (project_id, thunkAPI) => {
  // Query the endpoint to get all wetland forms associated with a project, regardless of region
  const response = await WetlandFormsApiService.getAllByProject(project_id);
  return response.data;
});

export const getWetlandForm = createAsyncThunk("wetlandForms/getWetlandForm", async (id, thunkAPI) => {
  const response = await WetlandFormsApiService.get(id);
  // console.log(response.data);
  thunkAPI.dispatch(setCurrentWetlandFormData(response.data));
  return response.data;
});

export const getWetlandFormPhotos = createAsyncThunk("wetlandForms/getWetlandFormPhotos", async (id, thunkAPI) => {
  thunkAPI.dispatch(setCurrentWetlandFormPhotosLoading(true));
  const response = await WetlandFormsApiService.getPhotos(id);
  thunkAPI.dispatch(setCurrentWetlandFormPhotos(response.data));
  thunkAPI.dispatch(setCurrentWetlandFormPhotosLoading(false));
  return response.data;
});

export const deleteWetlandForms = createAsyncThunk("wetlandForms/deleteWetlandForms", async (ids, thunkAPI) => {
  const response = await WetlandFormsApiService.deleteMultiple(ids);
  thunkAPI.dispatch(resetFormsLoadingStatus()); // This triggers another fetch of data forms in the FormsList component
  return response.data;
});

export const saveCurrentWetlandForm = createAsyncThunk("wetlandForms/saveCurrentWetlandForm", async (_, thunkAPI) => {
  // If the current form already exists, updates it, otherwise adds it as a new form
  let currState = thunkAPI.getState();
  let currForm = { ...currState.wetlandForms.currentForm }; // This copy needs to be done to ensure that the redux store state isn't mutated to remove the photos property from the current data
  // Remove the photos from the current form, as they are uploaded separately (if upload is even necessary)
  let { photos, ...currFormNoPhotos } = currForm;

  // Properly add the soil color items back to the unaltered flat soil data
  let editableForm = JSON.parse(JSON.stringify(currFormNoPhotos));
  editableForm.wetlandSoilLayers.forEach((layer, layerIndex) => {
    if (layer.matrixColorItems.length > 0) {
      editableForm.wetlandSoilLayers[layerIndex].matrixPage = layer.matrixColorItems[0].matrixPage;
      editableForm.wetlandSoilLayers[layerIndex].matrixHue = layer.matrixColorItems[0].matrixHue;
      editableForm.wetlandSoilLayers[layerIndex].matrixValue = layer.matrixColorItems[0].matrixValue;
      editableForm.wetlandSoilLayers[layerIndex].matrixChroma = layer.matrixColorItems[0].matrixChroma;
      editableForm.wetlandSoilLayers[layerIndex].matrixPercent = layer.matrixColorItems[0].matrixPercent;
    }
    if (layer.matrixColorItems.length > 1) {
      editableForm.wetlandSoilLayers[layerIndex].matrixPage2 = layer.matrixColorItems[1].matrixPage;
      editableForm.wetlandSoilLayers[layerIndex].matrixHue2 = layer.matrixColorItems[1].matrixHue;
      editableForm.wetlandSoilLayers[layerIndex].matrixValue2 = layer.matrixColorItems[1].matrixValue;
      editableForm.wetlandSoilLayers[layerIndex].matrixChroma2 = layer.matrixColorItems[1].matrixChroma;
      editableForm.wetlandSoilLayers[layerIndex].matrixPercent2 = layer.matrixColorItems[1].matrixPercent;
    }
    if (layer.matrixColorItems.length > 2) {
      editableForm.wetlandSoilLayers[layerIndex].matrixPage3 = layer.matrixColorItems[2].matrixPage;
      editableForm.wetlandSoilLayers[layerIndex].matrixHue3 = layer.matrixColorItems[2].matrixHue;
      editableForm.wetlandSoilLayers[layerIndex].matrixValue3 = layer.matrixColorItems[2].matrixValue;
      editableForm.wetlandSoilLayers[layerIndex].matrixChroma3 = layer.matrixColorItems[2].matrixChroma;
      editableForm.wetlandSoilLayers[layerIndex].matrixPercent3 = layer.matrixColorItems[2].matrixPercent;
    }
    if (layer.redoxColorItems.length > 0) {
      editableForm.wetlandSoilLayers[layerIndex].redoxPage = layer.redoxColorItems[0].redoxPage;
      editableForm.wetlandSoilLayers[layerIndex].redoxHue = layer.redoxColorItems[0].redoxHue;
      editableForm.wetlandSoilLayers[layerIndex].redoxValue = layer.redoxColorItems[0].redoxValue;
      editableForm.wetlandSoilLayers[layerIndex].redoxChroma = layer.redoxColorItems[0].redoxChroma;
      editableForm.wetlandSoilLayers[layerIndex].redoxPercent = layer.redoxColorItems[0].redoxPercent;
    }
    if (layer.redoxColorItems.length > 1) {
      editableForm.wetlandSoilLayers[layerIndex].redoxPage2 = layer.redoxColorItems[1].redoxPage;
      editableForm.wetlandSoilLayers[layerIndex].redoxHue2 = layer.redoxColorItems[1].redoxHue;
      editableForm.wetlandSoilLayers[layerIndex].redoxValue2 = layer.redoxColorItems[1].redoxValue;
      editableForm.wetlandSoilLayers[layerIndex].redoxChroma2 = layer.redoxColorItems[1].redoxChroma;
      editableForm.wetlandSoilLayers[layerIndex].redoxPercent2 = layer.redoxColorItems[1].redoxPercent;
    }
    if (layer.redoxColorItems.length > 2) {
      editableForm.wetlandSoilLayers[layerIndex].redoxPage3 = layer.redoxColorItems[2].redoxPage;
      editableForm.wetlandSoilLayers[layerIndex].redoxHue3 = layer.redoxColorItems[2].redoxHue;
      editableForm.wetlandSoilLayers[layerIndex].redoxValue3 = layer.redoxColorItems[2].redoxValue;
      editableForm.wetlandSoilLayers[layerIndex].redoxChroma3 = layer.redoxColorItems[2].redoxChroma;
      editableForm.wetlandSoilLayers[layerIndex].redoxPercent3 = layer.redoxColorItems[2].redoxPercent;
    }
  });
  let response;
  if (editableForm.id) {
    response = await WetlandFormsApiService.put(editableForm);
  } else {
    response = await WetlandFormsApiService.post(editableForm);
    // After uploading a new form, have to set the response values right away (namely the wetland id), so that everything works properly with photo uploads
    if (response.status === 201) {
      thunkAPI.dispatch(updateCurrentFormId(response.data.id));
    }
  }
  return { status: response.status, data: response.data };
});

export const syncWetlandPhotos = createAsyncThunk("wetlandForms/syncWetlandPhotos", async (_, thunkAPI) => {
  // When a form is saved (successful response code), this function is also called to sync any changes to photo objects.
  // This deletes any photos flagged for deletion, and updates or creates and photo objects when necessary.
  const currState = thunkAPI.getState();
  const photosToRemove = currState.wetlandForms.currentForm.photos.filter((photo) => photo?.shouldRemove === true);
  const removeResults = await thunkAPI.dispatch(removeFlaggedWetlandPhotos(photosToRemove));
  const uploadResults = await thunkAPI.dispatch(uploadWetlandPhotos(currState.wetlandForms.currentForm.photos));
  return {
    uploads: uploadResults.payload,
    removes: removeResults.payload,
  };
});

export const removeFlaggedWetlandPhotos = createAsyncThunk(
  "wetlandForms/removeFlaggedWetlandPhotos",
  async (photosToRemove, thunkAPI) => {
    // Any photo objects in state that have the 'shouldRemove' flag will be removed here
    let removeResults = {
      successes: [],
      failures: [],
    };
    await Promise.all(
      photosToRemove.map(async (photo) => {
        try {
          await WetlandFormsApiService.removePhoto(photo.id);
          removeResults.successes.push(photo.id);
        } catch (e) {
          removeResults.failures.push(photo.id);
        }
      })
    );
    const currState = thunkAPI.getState();
    thunkAPI.dispatch(
      setCurrentWetlandFormPhotos(
        currState.wetlandForms.currentForm.photos.filter(
          (photo) => photo?.shouldRemove !== true || removeResults.failures.includes(photo?.id)
        )
      )
    );
    return removeResults;
  }
);

export const uploadWetlandPhotos = createAsyncThunk("wetlandForms/uploadWetlandPhotos", async (photos, thunkAPI) => {
  // Only upload photos if the image has not been uploaded to S3 (image URL starts with 'blob')
  let uploadResults = {
    successes: [],
    failures: [],
  };
  const photosCopy = [...photos];
  await Promise.all(
    photosCopy.map(async (photo, index) => {
      if (photo?.shouldRemove !== true) {
        if (photo.image.startsWith("blob")) {
          // Upload the new image
          let imageFile = await axios({
            baseURL: "",
            method: "get",
            url: photo.image,
            responseType: "blob",
          });
          let newFileNameUuid = uuidv4();
          const imageFileExtension = imageFile.data.type.split("image/")[1]; // Will result in "png", "jpeg", etc.
          const formData = new FormData();
          formData.append("image", imageFile.data, `${newFileNameUuid}.${imageFileExtension}`);
          formData.append("direction", photo.direction);
          if (photo.hasOwnProperty("id")) {
            formData.append("id", photo.id);
          }
          formData.append("note", photo.note);
          formData.append("latitude", photo.latitude);
          formData.append("longitude", photo.longitude);
          formData.append("include_in_report", photo.include_in_report);
          formData.append("photo_order_in_report", photo.photo_order_in_report);
          formData.append("user", photo.user);
          formData.append("wetland", photo.wetland);
          try {
            const result = await WetlandFormsApiService.uploadPhoto(formData);
            uploadResults.successes.push(result.data);
            thunkAPI.dispatch(setCurrentWetlandFormPhotoAtIndex({ data: result.data, index: index }));
          } catch (e) {
            uploadResults.failures.push(photo?.id);
          }
        } else {
          // Just update the photo details (direction, note)
          // This is only done if the "info changed" flag is switched to true, just to prevent additional server requires that aren't needed
          if (photo.hasOwnProperty('metadataModified')) {
            if (photo.metadataModified === true) {
              await WetlandFormsApiService.updatePhotoMetadata(photo);
            }
          }
        }
      }
    })
  );
  return uploadResults;
});

export const generateWetlandExcel = createAsyncThunk("wetlandForms/generateWetlandExcel", async (ids, thunkAPI) => {
  const response = await WetlandFormsApiService.generateExcel(ids);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `wetland_forms.xlsx`);
  document.body.appendChild(link);
  link.click();
  return {status: response.status};
});

export const generateWetlandExcelADF = createAsyncThunk("wetlandForms/generateWetlandExcelADF", async (form, thunkAPI) => {
  const response = await WetlandFormsApiService.generateExcelADF(form.id);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${form.sampleName}.xlsm`);
  document.body.appendChild(link);
  link.click();
  return {status: response.status};
});

export const generateWetlandPointsShapefile = createAsyncThunk("wetlandForms/generateWetlandPointsShapefile", async (ids, thunkAPI) => {
  const response = await WetlandFormsApiService.generateShapefile(ids);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `wetland_points.zip`);
  document.body.appendChild(link);
  link.click();
  return {status: response.status};
});

export const queryBulkUpdateAffectedDataCheck = createAsyncThunk("wetlandForms/queryBulkUpdateAffectedDataCheck", async (file, thunkAPI) => {
  const formData = new FormData();
  formData.append('csvFile', file);
  try {
    const response = await WetlandFormsApiService.bulkUpdateAffectedDataCheck(formData);
    return {status: response.status, data: response.data};
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data,
    };
  }
});

export const performBulkUpdateFromCsv = createAsyncThunk("wetlandForms/performBulkUpdateFromCsv", async (file, thunkAPI) => {
  const formData = new FormData();
  formData.append('csvFile', file);
  try {
    const response = await WetlandFormsApiService.bulkUpdateFromCsv(formData);
    return {status: response.status, data: response.data};
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data,
    };
  }
});


export const fetchAllWetlandSpatialData = createAsyncThunk(
  "wetlandForms/fetchAllWetlandSpatialData",
  async (_, thunkAPI) => {
    const currState = thunkAPI.getState();
    const latitude = currState.wetlandForms.currentForm?.latitude;
    const longitude = currState.wetlandForms.currentForm?.longitude;
    const res1 = thunkAPI.dispatch(fetchWetlandSpatialDataCityCounty({ latitude: latitude, longitude: longitude }));
    const res2 = thunkAPI.dispatch(fetchWetlandSpatialDataSecTownRange({ latitude: latitude, longitude: longitude }));
    const res3 = thunkAPI.dispatch(fetchWetlandSpatialDataAceSubregion({ latitude: latitude, longitude: longitude }));
    const res4 = thunkAPI.dispatch(fetchWetlandSpatialDataNwiClass({ latitude: latitude, longitude: longitude }));
    const res5 = thunkAPI.dispatch(fetchWetlandSpatialDataSoilMapUnit({ latitude: latitude, longitude: longitude }));
    return Promise.all([res1, res2, res3, res4, res5]).then((values) => {
      return true;
    });
  }
);

export const fetchWetlandSpatialDataCityCounty = createAsyncThunk(
  "wetlandForms/fetchWetlandSpatialDataCityCounty",
  async ({ latitude, longitude }, thunkAPI) => {
    const response = await SpatialDataApiService.getCityCounty({ latitude: latitude, longitude: longitude });
    if (response.status === 200) {
      let attribs;
      if ('features' in response.data) {
        const features = response.data.features;
        if (features.length > 0) {
          attribs = response.data.features[0].attributes;
        } else {
          attribs = { STATE_NAME: "", NAME: "" };
        }
      }
      if ('error' in response.data) {
        thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "usStateAndCityCountyFetchError", value: true }));
      } else {
        thunkAPI.dispatch(changeCurrentWetlandFormSpatialValueWithErrorReset({ name: "usState", value: attribs["STATE_NAME"], resetErrorFlag: 'usStateAndCityCountyFetchError' }));
        thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "cityCounty", value: attribs["NAME"] }));
      }
    }
    return response.data;
  }
);

export const fetchWetlandSpatialDataSecTownRange = createAsyncThunk(
  "wetlandForms/fetchWetlandSpatialDataSecTownRange",
  async ({ latitude, longitude }, thunkAPI) => {
    const response = await SpatialDataApiService.getPLSS({ latitude: latitude, longitude: longitude });
    if (response.status === 200) {
      let plssData;
      if ('features' in response.data) {
        const features = response.data.features;
        if (features.length > 0) {
          plssData = {
            section: "",
            township: "",
            range: "",
          };
          const attribs = response.data.features[0].attributes;
          plssData.section = attribs["FRSTDIVNO"]; // FRSTDIVNO is the PLSS section, township and range are derived from PLSSID
          plssData.township = attribs["PLSSID"].substring(4, 7) + attribs["PLSSID"].substring(8, 9);
          plssData.range = attribs["PLSSID"].substring(9, 12) + attribs["PLSSID"].substring(13, 14);
        }
      }
      let plssString = '';
      if (plssData) {
        plssString = `sec ${plssData.section} T${plssData.township} R${plssData.range}`;
      }
      if ('error' in response.data) {
        thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "secTownRangeFetchError", value: true }));
      } else {
        thunkAPI.dispatch(changeCurrentWetlandFormSpatialValueWithErrorReset({ name: "secTownRange", value: plssString, resetErrorFlag: 'secTownRangeFetchError' }));
      }
    }
    return response.data;
  }
);

export const fetchWetlandSpatialDataAceSubregion = createAsyncThunk(
  "wetlandForms/fetchWetlandSpatialDataAceSubregion",
  async ({ latitude, longitude }, thunkAPI) => {
    const response = await SpatialDataApiService.getAceSubregion({ latitude: latitude, longitude: longitude });
    if (response.status === 200) {
      let subregionName = "";
      if ('features' in response.data) {
        const features = response.data.features;
        if (features.length > 0) {
          subregionName = response.data.features[0].attributes["ADS_SUB_NM"];
        }
      }
      if ('error' in response.data) {
        thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "subregionFetchError", value: true }));
      } else {
        thunkAPI.dispatch(changeCurrentWetlandFormSpatialValueWithErrorReset({ name: "subregion", value: subregionName, resetErrorFlag: 'subregionFetchError' }));
      }
    }
    return response.data;
  }
);

export const fetchWetlandSpatialDataNwiClass = createAsyncThunk(
  "wetlandForms/fetchWetlandSpatialDataNwiClass",
  async ({ latitude, longitude }, thunkAPI) => {
    // Load the outFields from local storage for the dynamic "primary" choice. If necessary, swap for one or more fallbacks
    let storedOutFields;
    try {
      storedOutFields = JSON.parse(localStorage.getItem("nwiRequestOutFields") ?? JSON.stringify(['ATTRIBUTE', 'WETLAND_TYPE']));
    } catch {
      storedOutFields = ['ATTRIBUTE', 'WETLAND_TYPE'];
    }
    let newOutFields;
    let response = await SpatialDataApiService.getNwiClass({ latitude: latitude, longitude: longitude, outFields: storedOutFields });
    if ('error' in response.data || response.status === 400) {
      // Try query again with modified values
      const backupOutFieldOptions = [
        ['ATTRIBUTE', 'WETLAND_TYPE'],
        ['Wetlands.ATTRIBUTE', 'Wetlands.WETLAND_TYPE'],
        // ['agstest.sdeadmin.NWI_Wetland_Codes.attribute', 'agstest.sdeadmin.Wetlands_2.wetland_type'], // Only used for a short period of time in the past, may not be encountered again
      ];
      newOutFields = backupOutFieldOptions.find((option) => option[0] !== storedOutFields[0] && option[1] !== storedOutFields[1]);
      response = await SpatialDataApiService.getNwiClass({ latitude: latitude, longitude: longitude, outFields: newOutFields });
    }
    if (response.status === 200) {
      let nwiClass = "";
      if ('features' in response.data) {
        // At this point, we know the request was successful. Update in local storage the primary request outFields
        if (newOutFields) {
          localStorage.setItem("nwiRequestOutFields", JSON.stringify(newOutFields));
        }
        const features = response.data.features;
        if (features.length > 0) {
          if (response.data.features[0].hasOwnProperty('attributes')) {
            // Need to do some extra work to ensure no issues with case sensitivity or slightly different naming
            let matchingValue = 'attribute';
            for (const [key, value] of Object.entries(response.data.features[0].attributes)) {
              if (key.toLowerCase().includes('attribute')) {
                matchingValue = key;
              }
            }
            nwiClass = response.data.features[0].attributes[`${matchingValue}`];
          }
        } else {
          nwiClass = 'None';
        }
      }
      // Even with a 200 response, AGOL API might return an error in the data. If so, flag that as an error if all alternate options also didn't work
      if ('error' in response.data) {
        thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "nwiClassFetchError", value: true }));
      } else {
        thunkAPI.dispatch(changeCurrentWetlandFormSpatialValueWithErrorReset({ name: "nwiClass", value: nwiClass, resetErrorFlag: 'nwiClassFetchError' }));
      }
    }
    return response.data;
  }
);

export const fetchWetlandSpatialDataSoilMapUnit = createAsyncThunk(
  "wetlandForms/fetchWetlandSpatialDataSoilMapUnit",
  async ({ latitude, longitude }, thunkAPI) => {
    const response = await SpatialDataApiService.getSoilMapUnit({ latitude: latitude, longitude: longitude });
    if (response.status === 200) {
      const responseDataTable = response.data.Table;
      let soilMUName = "";
      if (responseDataTable.length > 0) {
        soilMUName = responseDataTable[0][2]; // The second item in the inner table array is always present, and is always the soil MU name
      } else {
        soilMUName = 'None';
      }
      thunkAPI.dispatch(changeCurrentWetlandFormValue({ name: "soilMUName", value: soilMUName }));
    }
    return response.data;
  }
);

export const wetlandFormsSlice = createSlice({
  name: "wetlandForms",
  initialState,
  reducers: {
    resetFormsLoadingStatus: (state, action) => {
      state.status = formsLoadingStatus.IDLE;
    },
    setAddCurrentWetlandFormNewValues: (state, action) => {
      // User is passed in state, as it is in a different slice
      // Region might be stored as "All" in localStorage, in which case make region unspecified, which will indicate that it must be chosen
      let formRegion = action.payload.region;
      if (action.payload.region === 'All') {
        formRegion = 'Unspecified';
      }
      state.currentForm = {
        user: action.payload.user,
        uuid: uuidv4(),
        aceRegion: formRegion,
        sampleName: "",
        surveyors: "[]",
        dateTime: new Date().toISOString(),
        latitude: "",
        longitude: "",
        accuracy: "",
        pointAvgNum: 10,
        projectNumber: "",
        projectSite: "",
        cityCounty: "",
        applicantOwner: "",
        usState: "",
        island: "",
        secTownRange: "",
        subregion: "",
        datum: "WGS84",
        soilMUName: "",
        nwiClass: "",
        landform: "",
        relief: "",
        slope: "",
        normalCircumstances: true,
        climHydConditionsTypical: true,
        vegSignifDisturb: false,
        hydroSignifDisturb: false,
        soilSignifDisturb: false,
        vegNaturallyProb: false,
        hydroNaturallyProb: false,
        soilNaturallyProb: false,
        useSubregionIndicatorOverrides: false,
        useFiveStrata: false,
        treePlotSize: "30",
        saplingPlotSize: "15",
        shrubPlotSize: "15",
        herbPlotSize: "5",
        vinePlotSize: "30",
        treeTotalCov: "",
        saplingTotalCov: "",
        shrubTotalCov: "",
        herbTotalCov: "",
        vineTotalCov: "",
        domTestA: "",
        domTestB: "",
        domTestABPercent: "",
        prevIndObl: "0",
        prevIndFacw: "0",
        prevIndFac: "0",
        prevIndFacu: "0",
        prevIndUpl: "0",
        prevIndTotal: "0",
        rapidTest: false,
        dominanceTest: false,
        prevalenceIndex: "",
        morphAdaptations: false,
        problematicHydrophyticVeg: null,
        percentBareGround: "",
        percentCoverWetlandBryophytes: "",
        percentBioticCrust: "",
        wetlandVeg: false,
        vegComment: "",
        surfaceWaterDepth: "",
        waterTableDepth: "",
        saturationDepth: "",
        recordedData: "",
        hydroComment: "",
        wetlandHydro: false,
        restrictiveType: "",
        restrictiveDepth: "",
        soilsComment: "",
        wetlandSoils: false,
        isWetland: false,
        overrideWetlandCalcs: false,
        summaryComment: "",
        formStatus: "waiting",
        qaqcer: "",
        qaqcNotes: "",
        wetlandPlants: [],
        wetlandHydroIndicators: [],
        wetlandSoilLayers: [],
        wetlandSoilIndicators: [],
        photos: [],
      };
    },
    setAddCurrentWetlandFormProjectValues: (state, action) => {
      // The currentForm object has already been instantiated, just modify some of these values here based on the project data (passed to this as an input)
      // This is done during the process of adding a new form, but can also be called from the form edit page when changing the project. If so, this can potentially be null.
      if (action.payload !== null) {
        state.currentForm.projectData = action.payload; // This object is just used for storing info about the project, and not actually for POSTing to the server
        state.currentForm.project = action.payload.id;
        state.currentForm.projectNumber = action.payload.project_number;
        state.currentForm.projectSite = action.payload.project_or_site_name;
        state.currentForm.applicantOwner = action.payload.applicant_or_owner_name;
      } else {
        state.currentForm.projectData = null;
        state.currentForm.project = null;
        state.currentForm.projectNumber = '';
        state.currentForm.projectSite = '';
        state.currentForm.applicantOwner = '';
      }
    },
    updateCurrentFormId: (state, action) => {
      // Payload is the new form id. This is called when a form is uploaded (new POST upload) and wetland id has to be updated
      state.currentForm.id = action.payload;
      // Also update all photo items with the wetland id
      state.currentForm.photos = state.currentForm.photos.map((photo) => {
        return { ...photo, wetland: action.payload };
      });
    },
    changeRegion: (state, action) => {
      state.region = action.payload;
      localStorage.setItem("ace_region", action.payload); // Save the updated region in localstorage
      state.status = formsLoadingStatus.IDLE; // Triggers a new fetch for ACE forms
    },
    toggleWetlandFormSelected: (state, action) => {
      if (!state.selectedForms.includes(action.payload)) {
        return {
          ...state,
          selectedForms: [...state.selectedForms, action.payload],
        };
      } else {
        return {
          ...state,
          selectedForms: state.selectedForms.filter((item) => item !== action.payload),
        };
      }
    },
    setSelectedForms: (state, action) => {
      // payload is an array of form ids to set the new selection to
      if (state.forms) {
        const selected = state.forms.filter((form) => action.payload.includes(form.id));
        state.selectedForms = selected;
      }
    },
    resetSelectedForms: (state) => {
      state.selectedForms = [];
    },
    setWetlandFormGeneratePdfLoadingByFormId: (state, action) => {
      // Input is the wetland form id. Sets an attribute in the form's info as 'loading' state
      if (state.forms) {
        const form = state.forms.find((form) => form.id === action.payload);
        if (form !== undefined) {
          form.generatePdfStatus = formsLoadingStatus.LOADING;
        }
      }
    },
    setWetlandFormGeneratePdfCompleteByFormId: (state, action) => {
      if (state.forms) {
        const form = state.forms.find((form) => form.id === action.payload);
        if (form !== undefined) {
          form.generatePdfStatus = formsLoadingStatus.SUCCESS;
        }
      }
    },
    setWetlandFormActionType: (state, action) => {
      state.currentFormActionType = action.payload;
    },
    setCurrentWetlandFormData: (state, action) => {
      // state.currentForm = action.payload;
      // Need to convert all soil layer values and chromas to numbers if non-blank
      const soilLayers = action.payload.wetlandSoilLayers.map(layer => {
        let matrixColorItems = [
          {
            matrixPage: layer.matrixPage,
            matrixHue: layer.matrixHue,
            matrixValue: isNaN(parseFloat(layer.matrixValue)) ? '' : parseFloat(layer.matrixValue),
            matrixChroma: isNaN(parseFloat(layer.matrixChroma)) ? '' : parseFloat(layer.matrixChroma),
            matrixPercent: layer.matrixPercent,
          }
        ];
        if (layer.matrixPage2 !== '' || layer.matrixHue2 !== '' || layer.matrixValue2 !== '' || layer.matrixChroma2 !== '') {
          matrixColorItems.push({
              matrixPage: layer.matrixPage2,
              matrixHue: layer.matrixHue2,
              matrixValue: isNaN(parseFloat(layer.matrixValue2)) ? '' : parseFloat(layer.matrixValue2),
              matrixChroma: isNaN(parseFloat(layer.matrixChroma2)) ? '' : parseFloat(layer.matrixChroma2),
              matrixPercent: layer.matrixPercent2,
            });
        }
        if (layer.matrixPage3 !== '' || layer.matrixHue3 !== '' || layer.matrixValue3 !== '' || layer.matrixChroma3 !== '') {
          matrixColorItems.push({
              matrixPage: layer.matrixPage3,
              matrixHue: layer.matrixHue3,
              matrixValue: isNaN(parseFloat(layer.matrixValue3)) ? '' : parseFloat(layer.matrixValue3),
              matrixChroma: isNaN(parseFloat(layer.matrixChroma3)) ? '' : parseFloat(layer.matrixChroma3),
              matrixPercent: layer.matrixPercent3,
            });
        }
        let redoxColorItems = [
          {
            redoxPage: layer.redoxPage,
            redoxHue: layer.redoxHue,
            redoxValue: isNaN(parseFloat(layer.redoxValue)) ? '' : parseFloat(layer.redoxValue),
            redoxChroma: isNaN(parseFloat(layer.redoxChroma)) ? '' : parseFloat(layer.redoxChroma),
            redoxPercent: layer.redoxPercent,
          }
        ];
        if (layer.redoxPage2 !== '' || layer.redoxHue2 !== '' || layer.redoxValue2 !== '' || layer.redoxChroma2 !== '') {
          redoxColorItems.push({
            redoxPage: layer.redoxPage2,
            redoxHue: layer.redoxHue2,
            redoxValue: isNaN(parseFloat(layer.redoxValue2)) ? '' : parseFloat(layer.redoxValue2),
            redoxChroma: isNaN(parseFloat(layer.redoxChroma2)) ? '' : parseFloat(layer.redoxChroma2),
            redoxPercent: layer.redoxPercent2,
          });
        }
        if (layer.redoxPage3 !== '' || layer.redoxHue3 !== '' || layer.redoxValue3 !== '' || layer.redoxChroma3 !== '') {
          redoxColorItems.push({
            redoxPage: layer.redoxPage3,
            redoxHue: layer.redoxHue3,
            redoxValue: isNaN(parseFloat(layer.redoxValue3)) ? '' : parseFloat(layer.redoxValue3),
            redoxChroma: isNaN(parseFloat(layer.redoxChroma3)) ? '' : parseFloat(layer.redoxChroma3),
            redoxPercent: layer.redoxPercent3,
          });
        }
        return {
          ...layer,
          matrixColorItems: matrixColorItems,
          redoxColorItems: redoxColorItems,
          matrixValue: isNaN(parseFloat(layer.matrixValue)) ? '' : parseFloat(layer.matrixValue),
          matrixChroma: isNaN(parseFloat(layer.matrixChroma)) ? '' : parseFloat(layer.matrixChroma),
          matrixValue2: isNaN(parseFloat(layer.matrixValue2)) ? '' : parseFloat(layer.matrixValue2),
          matrixChroma2: isNaN(parseFloat(layer.matrixChroma2)) ? '' : parseFloat(layer.matrixChroma2),
          matrixValue3: isNaN(parseFloat(layer.matrixValue3)) ? '' : parseFloat(layer.matrixValue3),
          matrixChroma3: isNaN(parseFloat(layer.matrixChroma3)) ? '' : parseFloat(layer.matrixChroma3),
          redoxValue: isNaN(parseFloat(layer.redoxValue)) ? '' : parseFloat(layer.redoxValue),
          redoxChroma: isNaN(parseFloat(layer.redoxChroma)) ? '' : parseFloat(layer.redoxChroma),
          redoxValue2: isNaN(parseFloat(layer.redoxValue2)) ? '' : parseFloat(layer.redoxValue2),
          redoxChroma2: isNaN(parseFloat(layer.redoxChroma2)) ? '' : parseFloat(layer.redoxChroma2),
          redoxValue3: isNaN(parseFloat(layer.redoxValue3)) ? '' : parseFloat(layer.redoxValue3),
          redoxChroma3: isNaN(parseFloat(layer.redoxChroma3)) ? '' : parseFloat(layer.redoxChroma3),
        };
      });
      state.currentForm = {
        ...action.payload,
        wetlandSoilLayers: soilLayers,
      };
    },
    setCurrentWetlandFormPhotos: (state, action) => {
      state.currentForm.photos = action.payload;
    },
    setCurrentWetlandFormPhotosLoading: (state, action) => {
      state.currentForm.photosLoading = action.payload;
    },
    changeCurrentWetlandFormValue: (state, action) => {
      // Here, payload is an object with name (name of item to change) and value (new value of this item)
      let name = action.payload.name;
      state.currentForm[name] = action.payload.value;
    },
    changeCurrentWetlandDate: (state, action) => {
      // Here, payload is an object with the new date
      const currentDateTime = state.currentForm.dateTime;
      const dtSplit = currentDateTime.split('T');
      let currentTime = 'T12:00:00';
      if (dtSplit.length > 1) {
        currentTime = `T${dtSplit[1]}`;
      }
      const newDateFormatted = action.payload;
      state.currentForm.dateTime = `${newDateFormatted}${currentTime}`;
    },
    setCurrentWetlandLatLong: (state, action) => {
      const latitude = action.payload.latitude;
      const longitude = action.payload.longitude;
      if (state.currentForm) {
        // TODO: make sure this call waits until after current form is loaded!
        state.currentForm.latitude = parseFloat(latitude).toFixed(6);
        state.currentForm.longitude = parseFloat(longitude).toFixed(6);
      }
    },
    changeCurrentWetlandFormSpatialValueWithErrorReset: (state, action) => {
      // Here, payload is an object with name (name of item to change) and value (new value of this item). 
      // Can also contain the name of the flag variable that should be removed, if there was previously an error and no longer is.
      let name = action.payload.name;
      state.currentForm[name] = action.payload.value;
      if ('resetErrorFlag' in action.payload) {
        let resetErrorFlag = action.payload.resetErrorFlag;
        if (resetErrorFlag in state.currentForm) {
          delete state.currentForm[resetErrorFlag];
        }
      }
    },
    addWetlandPlantToStratum: (state, action) => {
      // payload is the stratum to add to, as the enum-like
      const newPlant = {
        strata: vegStratumToAbbrevString(action.payload),
        sciName: "",
        commName: "",
        indicator: "",
        cover: "",
        dominant: false,
        family: "",
      };
      state.currentForm.wetlandPlants.push(newPlant);
    },
    setWetlandPlantName: (state, action) => {
      // Payload is an object containing index of the plant and sciName value
      state.currentForm.wetlandPlants[action.payload.index].sciName = action.payload.sciName;
    },
    setWetlandPlantIndicator: (state, action) => {
      // Payload is an object containing index of the plant and indicator value
      state.currentForm.wetlandPlants[action.payload.index].indicator = action.payload.indicator;
    },
    setWetlandPlantCover: (state, action) => {
      // Payload is an object containing index of the plant and cover value
      state.currentForm.wetlandPlants[action.payload.index].cover = action.payload.cover;
    },
    deleteWetlandPlant: (state, action) => {
      const index = action.payload;
      state.currentForm.wetlandPlants.splice(index, 1);
    },
    toggleCurrentWetlandFormUseSubregionOverrides: (state, action) => {
      state.currentForm.useSubregionIndicatorOverrides = action.payload.value;
      // Check the entered plants for any species with a matching name, and update the indicator accordingly
      const overridePlants = getSubregionIndicatorOverrides(state.currentForm.aceRegion);
      const originalPlants = getSubregionOriginalIndicators(state.currentForm.aceRegion);
      [...state.currentForm.wetlandPlants].forEach((plant, index) => {
        if (overridePlants.find((overridePlant) => overridePlant.sciName === plant.sciName)) {
          if (action.payload.value === true) {
            state.currentForm.wetlandPlants[index].indicator = overridePlants.find(
              (overridePlant) => overridePlant.sciName === plant.sciName
            ).indicator;
          } else {
            state.currentForm.wetlandPlants[index].indicator = originalPlants.find(
              (overridePlant) => overridePlant.sciName === plant.sciName
            ).indicator;
          }
        }
      });
    },
    calcDominantPlants: (state, action) => {
      // Calculates the dominant plant species in each strata.
      // action payload is a bool indicating if the list should be sorted or not. Generally sorting is only done when something is selected or on blur, not when a user is still editing an input field.
      // First, sort all plants in descending order by cover (any invalid covers/blank cover values are pushed to the bottom. In the case of ties, sort alphabetically.)
      const sortList = action.payload?.sortList ?? false;
      const plants = [...state.currentForm.wetlandPlants];
      if (sortList) {
        plants.sort((a, b) => {
          const aCov = parseFloat(a.cover);
          const bCov = parseFloat(b.cover);
          if (isNaN(aCov)) {
            return 1;
          } else if (isNaN(bCov)) {
            return -1;
          }
          if (aCov > bCov) {
            return -1;
          } else if (aCov < bCov) {
            return 1;
          } else {
            return a.sciName > b.sciName ? 1 : -1;
          }
        });
      }

      // Sum the total cover for plants in each strata.
      let strataTotalCovs = {};
      for (const strata of VegStratumAbbreviations) {
        let totalCov = 0;
        const strataPlants = plants.filter((plant) => plant.strata === strata);
        strataPlants.forEach((plant) => {
          if (!isNaN(parseFloat(plant.cover))) {
            totalCov += parseFloat(plant.cover);
          }
        });
        strataTotalCovs[strata] = totalCov;
        // Update the total strata cover values in the state treeTotalCov
        const strataTotalCoverName = vegStratumToString(strata) + 'TotalCov';
        state.currentForm[strataTotalCoverName] = totalCov;
      }

      // Calculate dominants for each strata
      for (const strata of VegStratumAbbreviations) {
        const strataPlants = plants.filter((plant) => plant.strata === strata);
        const totalCov = strataTotalCovs[strata];
        let cumulativeCov = 0;
        for (let i = 0; i < strataPlants.length; i++) {
          const plantCov = parseFloat(strataPlants[i].cover);
          let prevPlantCov = 0; // If first item in strata, don't try to index previous item
          if (i > 0) {
            prevPlantCov = parseFloat(strataPlants[i - 1].cover);
          }
          if (
            cumulativeCov <= totalCov * 0.5 ||
            (plantCov === prevPlantCov && strataPlants[i - 1].dominant) ||
            plantCov >= totalCov * 0.2
          ) {
            // Handle edge case of species with 0 cover. All plants in a strata are set to not dominant if the strata's total cover is less than 5%
            if (plantCov === 0 || totalCov < 5) {
              strataPlants[i].dominant = false;
            } else {
              strataPlants[i].dominant = true;
            }
          } else {
            strataPlants[i].dominant = false;
          }
          cumulativeCov += plantCov;
        }
      }
      state.currentForm.wetlandPlants = plants;
    },
    hydrophyticCalcs: (state, _) => {
      // If not using the 5th stratum (sapling), only need to leave those plants out of the combined plants lists, as these lists are what all of the calculations are based on.
      const useFiveStrata = state.currentForm.useFiveStrata;
      const plants = state.currentForm.wetlandPlants.filter((plant) => {
        return !useFiveStrata && plant.strata === "sap" ? false : true;
      });
      const domPlants = plants.filter((plant) => plant.dominant);

      // FAC-neutral is always updated when the plant calculations are done
      // console.log(JSON.stringify(domPlants));
      const facNeutralMet = facNeutralCalc({ plants: plants, domPlants: domPlants });
      if (facNeutralMet) {
        const aceRegion = state.currentForm.aceRegion;
        const facNeutralIndicator = hydroIndicators[aceRegion].find(
          (item) => item.name.toLowerCase() === "fac-neutral test"
        );
        if (!state.currentForm.wetlandHydroIndicators.find((ind) => ind.name.toLowerCase() === "fac-neutral test")) {
          state.currentForm.wetlandHydroIndicators.push({ ...facNeutralIndicator, note: "" });
        }
      } else {
        state.currentForm.wetlandHydroIndicators = state.currentForm.wetlandHydroIndicators.filter(
          (item) => item.name.toLowerCase() !== "fac-neutral test"
        );
      }

      // Test all hydrophytic indicators
      let wetlandVeg = false;
      const ind1 = indicator1Calc({ dominantPlants: domPlants });
      state.currentForm.rapidTest = ind1;
      const ind2 = indicator2Calc({ dominantPlants: domPlants });
      state.currentForm.domTestA = ind2.domTestA;
      state.currentForm.domTestB = ind2.domTestB;
      state.currentForm.domTestABPercent = ind2.domTestABPercent;
      state.currentForm.dominanceTest = ind2.dominanceTest;
      const ind3 = indicator3Calc({ plants: plants });
      state.currentForm.prevIndObl = ind3.OBL;
      state.currentForm.prevIndFacw = ind3.FACW;
      state.currentForm.prevIndFac = ind3.FAC;
      state.currentForm.prevIndFacu = ind3.FACU;
      state.currentForm.prevIndUpl = ind3.UPL;
      state.currentForm.prevIndTotal = ind3.prevIndTotal;
      state.currentForm.prevalenceIndex = ind3.prevalenceIndex ?? '';
      const ind4 = state.currentForm.morphAdaptations;
      [ind1, ind2.dominanceTest, ind4].includes(true) ? (wetlandVeg = true) : (wetlandVeg = false);
      if (wetlandVeg === false) {
        // TODO: when wetland hydro or soils are toggled, re-do hydrophytic veg calculation as well to update this
        if (ind3.prevalenceIndexMet === true && state.currentForm.wetlandHydro && state.currentForm.wetlandSoils)
          wetlandVeg = true;
      }
      state.currentForm.wetlandVeg = wetlandVeg;
    },

    toggleWetlandIndicator: (state, action) => {
      // Payload contains indicatorType (Hydrology or Soil), as well as the indicator object (have to add note field)
      const indicators =
        action.payload.indicatorType === "Hydrology"
          ? state.currentForm.wetlandHydroIndicators
          : state.currentForm.wetlandSoilIndicators;
      if (indicators.filter((item) => item.symbol === action.payload.indicator.symbol).length > 0) {
        // Indicator in list, remove it
        const filteredIndicators = indicators.filter((item) => item.symbol !== action.payload.indicator.symbol);
        action.payload.indicatorType === "Hydrology"
          ? (state.currentForm.wetlandHydroIndicators = filteredIndicators)
          : (state.currentForm.wetlandSoilIndicators = filteredIndicators);
      } else {
        indicators.push({ ...action.payload.indicator, note: "" });
      }
      // Now check if wetland hydro/soil should be changed
      if (action.payload.indicatorType === "Hydrology") {
        const numPrimary = state.currentForm.wetlandHydroIndicators.filter((ind) => ind.category === "Primary").length;
        const numSecondary = state.currentForm.wetlandHydroIndicators.filter(
          (ind) => ind.category === "Secondary"
        ).length;
        if (numPrimary > 0 || numSecondary > 1) {
          state.currentForm.wetlandHydro = true;
        } else {
          state.currentForm.wetlandHydro = false;
        }
      } else {
        if (state.currentForm.wetlandSoilIndicators.length > 0) {
          state.currentForm.wetlandSoils = true;
        } else {
          state.currentForm.wetlandSoils = false;
        }
      }
    },
    changeCurrentWetlandFormSoilLayerItem: (state, action) => {
      // Standard action for changing soil layer values (start depth, end depth, etc.). Things that don't trigger soil autocomplete/checks.
      // Here, payload is an object with index (soil layer index), name (name of item to change), and value (new value of this item)
      let index = action.payload.index;
      let name = action.payload.name;
      state.currentForm.wetlandSoilLayers[index][name] = action.payload.value;
    },
    changeCurrentWetlandFormColorItem: (state, action) => {
      // Standard action for changing matrix or redox color items. Payload includes the soil layer index, matrixOrRedox, and the specific soil color item index
      let index = action.payload.index;
      let colorItemIndex = action.payload.colorItemIndex;
      let matrixOrRedox = action.payload.matrixOrRedox ?? 'matrix';
      let name = action.payload.name;
      if (matrixOrRedox === 'matrix') {
        state.currentForm.wetlandSoilLayers[index].matrixColorItems[colorItemIndex][name] = action.payload.value;
      } else {
        state.currentForm.wetlandSoilLayers[index].redoxColorItems[colorItemIndex][name] = action.payload.value;
      }
      
    },
    changeCurrentWetlandFormPage: (state, action) => {
      // Here, payload is an object with index (soil layer index), matrixOrRedox string, colorItemIndex (the soil color item index), and value (new value of the page). This also sets the hue.
      // The page might not always equate perfectly to a Hue (in the cases of GLEY/WHITE pages), so
      // have to interpret that here. Additionally, changing the selected page should always reset the color (value/chroma) to blank.
      let newPage = action.payload.value;
      let index = action.payload.index;
      let colorItemIndex = action.payload.colorItemIndex;
      let colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      let name = `${action.payload.matrixOrRedox}Page`;
      let hueName = `${action.payload.matrixOrRedox}Hue`;
      let valueName = `${action.payload.matrixOrRedox}Value`;
      let chromaName = `${action.payload.matrixOrRedox}Chroma`;
      state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][name] = newPage;
      state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][valueName] = "";
      state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][chromaName] = "";
      // Since changing the page always reset value and chroma, no need to do redox contrast calcs, just set back to none
      state.currentForm.wetlandSoilLayers[index].redoxDesc = 'None';
      // Making sure the Hue is set properly as well
      // If not a GLEY or WHITE page, set the hue equal to the page value. If it is GLEY/WHITE, hue is set when picking a color.
      if (newPage.includes('GLEY') || newPage.includes('WHITE')) {
        state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][hueName] = '';
      } else {
        state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][hueName] = newPage;
      }
    },
    changeCurrentWetlandFormSoilLayerValue: (state, action) => {
      // Here, payload is an object with index (soil layer index), matrixOrRedox string, colorItemIndex (the soil color item index), and value (new value of this item)
      // This will reset the color's chroma (only if it is out of bounds)
      let index = action.payload.index;
      let colorItemIndex = action.payload.colorItemIndex;
      let colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      let pageName = `${action.payload.matrixOrRedox}Page`;
      let valueName = `${action.payload.matrixOrRedox}Value`;
      const colorItem = state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex];
      const currentPageValue = state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][pageName];
      const filteredColors = soilColors[currentPageValue].filter(
        (colorChoice) => colorChoice.value === action.payload.value
      );
      colorItem[valueName] = action.payload.value;
      // If chroma is not valid, reset it
      const dynamicSoilChromas = [...new Set(filteredColors.map((colorChoice) => colorChoice.chroma))];
      let chromaName = `${action.payload.matrixOrRedox}Chroma`;
      if (!dynamicSoilChromas.includes(colorItem[chromaName])) {
        colorItem[chromaName] = "";
      }
      // If the selected Value has a non-null gleyHue (page is a GLEY page), and a Chroma is also selected, set the matrix/redox hue accordingly
      const currentChroma = state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex][chromaName];
      if (currentChroma) {
        const matchingColor = filteredColors.find((colorChoice) => colorChoice.value === action.payload.value && colorChoice.chroma === currentChroma);
        if (matchingColor) {
          // Make sure hue is properly set if color has a gleyHue
          if (matchingColor.gleyHue) {
            let hueName = `${action.payload.matrixOrRedox}Hue`;
            colorItem[hueName] = matchingColor.gleyHue;
          }
        }
      }
    },
    changeCurrentWetlandFormSoilLayerChroma: (state, action) => {
      // Note that naming here may be confusing. action.payload.value is actually the chroma to be set, "value" refers to the payload value and NOT to the soil color Value
      // Whenever chroma is updated, if the color is a gley color, update the hue as well.
      let index = action.payload.index;
      let colorItemIndex = action.payload.colorItemIndex;
      let colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      let chroma = action.payload.value;
      // let pageName = `${action.payload.matrixOrRedox}Page`;
      // let valueName = `${action.payload.matrixOrRedox}Value`;
      let chromaName = `${action.payload.matrixOrRedox}Chroma`;
      const colorItem = state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex];
      colorItem[chromaName] = chroma;
      // Look up the matching entry in the soil color data, and if it has a gleyHue, set the hue to that
      // const pageColors = soilColors[colorItem[pageName]];
      // const currentValue = colorItem[valueName];
      // const matchingColor = pageColors.find((colorChoice) => colorChoice.value === currentValue && colorChoice.chroma === chroma);
      // if (matchingColor) {
      //   if (matchingColor.gleyHue) {
      //     let hueName = `${action.payload.matrixOrRedox}Hue`;
      //     colorItem[hueName] = matchingColor.gleyHue;
      //   }
      // }
    },
    changeCurrentWetlandFormSoilLayerHue: (state, action) => {
      // Note that naming here may be confusing. action.payload.value is actually the chroma to be set, "value" refers to the payload value and NOT to the soil color Value
      // Whenever chroma is updated, if the color is a gley color, update the hue as well.
      let index = action.payload.index;
      let colorItemIndex = action.payload.colorItemIndex;
      let colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      let hue = action.payload.value;
      let hueName = `${action.payload.matrixOrRedox}Hue`;
      const colorItem = state.currentForm.wetlandSoilLayers[index][colorItemsName][colorItemIndex];
      colorItem[hueName] = hue;
    },
    addCurrentWetlandSoilLayer: (state) => {
      let prevEndDepth = "";
      if (state.currentForm.wetlandSoilLayers.length > 0) {
        prevEndDepth = state.currentForm.wetlandSoilLayers[state.currentForm.wetlandSoilLayers.length - 1].endDepth;
      }
      state.currentForm.wetlandSoilLayers.push({
        startDepth: prevEndDepth !== "" ? prevEndDepth : "",
        endDepth: "",
        matrixColorItems: [
          {
            matrixPage: "",
            matrixHue: "",
            matrixValue: "",
            matrixChroma: "",
            matrixPercent: "",
          },
        ],
        redoxColorItems: [
          {
            redoxPage: "",
            redoxHue: "",
            redoxValue: "",
            redoxChroma: "",
            redoxPercent: "",
          },
        ],
        matrixPage: "",
        matrixHue: "",
        matrixValue: "",
        matrixChroma: "",
        matrixPercent: "",
        redoxPage: "",
        redoxHue: "",
        redoxValue: "",
        redoxChroma: "",
        redoxPercent: "",
        redoxType: "",
        redoxLocation: "",
        redoxDesc: "None",
        texture: "",
        comment: "",
      });
    },
    deleteCurrentWetlandSoilLayer: (state, action) => {
      state.currentForm.wetlandSoilLayers.splice(action.payload, 1);
    },
    addCurrentWetlandSoilColorItem: (state, action) => {
      // Needs matrixOrRedox, as well as the index of the soil layer the color item is being added to.
      const index = action.payload.index;
      const colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      state.currentForm.wetlandSoilLayers[index][colorItemsName].push({
        [`${action.payload.matrixOrRedox}Page`]: "",
        [`${action.payload.matrixOrRedox}Hue`]: "",
        [`${action.payload.matrixOrRedox}Value`]: "",
        [`${action.payload.matrixOrRedox}Chroma`]: "",
        [`${action.payload.matrixOrRedox}Percent`]: "",
      });
    },
    deleteCurrentWetlandSoilColorItem: (state, action) => {
      // If the last matrix or redox color item, instead of deleting the object, just set its values back to blank
      const index = action.payload.index;
      const colorItemIndex = action.payload.colorItemIndex;
      const colorItemsName = `${action.payload.matrixOrRedox}ColorItems`;
      if (state.currentForm.wetlandSoilLayers[index][colorItemsName].length === 1) {
        state.currentForm.wetlandSoilLayers[index][colorItemsName][0] = {
          [`${action.payload.matrixOrRedox}Page`]: "",
          [`${action.payload.matrixOrRedox}Hue`]: "",
          [`${action.payload.matrixOrRedox}Value`]: "",
          [`${action.payload.matrixOrRedox}Chroma`]: "",
          [`${action.payload.matrixOrRedox}Percent`]: "",
        };
      } else {
        state.currentForm.wetlandSoilLayers[index][colorItemsName].splice(colorItemIndex, 1);
      }
    },
    changeCurrentWetlandPhotoDirectionAtIndex: (state, action) => {
      const index = action.payload.index;
      state.currentForm.photos[index].metadataModified = true;
      state.currentForm.photos[index].direction = action.payload.value;
    },
    changeCurrentWetlandPhotoAttributeAtIndex: (state, action) => {
      const index = action.payload.index;
      const attribute = action.payload.attribute;
      state.currentForm.photos[index].metadataModified = true;
      state.currentForm.photos[index][attribute] = action.payload.value;
    },
    changeCurrentWetlandPhotoNoteAtIndex: (state, action) => {
      const index = action.payload.index;
      state.currentForm.photos[index].metadataModified = true;
      state.currentForm.photos[index].note = action.payload.value;
    },
    addCurrentWetlandPhotoReplacementAtIndex: (state, action) => {
      const index = action.payload.index;
      state.currentForm.photos[index].image = action.payload.image;
    },
    addCurrentWetlandPhotoItem: (state, action) => {
      if (state.currentForm.hasOwnProperty("photos")) {
        state.currentForm.photos = [
          ...state.currentForm?.photos,
          {
            image: action.payload.image,
            direction: "",
            latitude: "",
            longitude: "",
            note: "",
            include_in_report: true,
            photo_order_in_report: null,
            user: state.currentForm?.user.email,
            wetland: state.currentForm?.id,
          },
        ];
      } else {
        state.currentForm.photos = [
          {
            image: action.payload.image,
            direction: "",
            latitude: "",
            longitude: "",
            note: "",
            include_in_report: true,
            photo_order_in_report: null,
            user: state.currentForm?.user.email,
            wetland: state.currentForm?.id,
          },
        ];
      }
    },
    markCurrentWetlandPhotoForDeletionAtIndex: (state, action) => {
      const index = action.payload.index;
      state.currentForm.photos[index].shouldRemove = true;
    },
    setCurrentWetlandFormPhotoAtIndex: (state, action) => {
      const index = action.payload.index;
      const data = action.payload.data;
      state.currentForm.photos[index] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWetlandFormsAsync.pending, (state) => {
        state.status = formsLoadingStatus.LOADING;
      })
      .addCase(getAllWetlandFormsAsync.fulfilled, (state, action) => {
        state.status = formsLoadingStatus.SUCCESS;
        state.forms = action.payload;
      })
      .addCase(getAllWetlandFormsAsync.rejected, (state, action) => {
        state.status = formsLoadingStatus.ERROR;
        state.forms = null;
      })
      .addCase(getProjectWetlandFormsAsync.pending, (state) => {
        state.status = formsLoadingStatus.LOADING;
      })
      .addCase(getProjectWetlandFormsAsync.fulfilled, (state, action) => {
        state.status = formsLoadingStatus.SUCCESS;
        state.forms = action.payload;
      })
      .addCase(getProjectWetlandFormsAsync.rejected, (state, action) => {
        state.status = formsLoadingStatus.ERROR;
        state.forms = null;
      });
  },
});

export const {
  setAddCurrentWetlandFormNewValues,
  setAddCurrentWetlandFormProjectValues,
  resetFormsLoadingStatus,
  changeRegion,
  updateCurrentFormId,
  toggleWetlandFormSelected,
  setSelectedForms,
  resetSelectedForms,
  setWetlandFormGeneratePdfLoadingByFormId,
  setWetlandFormGeneratePdfCompleteByFormId,
  setWetlandFormActionType,
  setCurrentWetlandFormData,
  setCurrentWetlandFormPhotos,
  setCurrentWetlandFormPhotosLoading,
  changeCurrentWetlandFormValue,
  changeCurrentWetlandDate,
  setCurrentWetlandLatLong,
  changeCurrentWetlandFormSpatialValueWithErrorReset,
  addWetlandPlantToStratum,
  deleteWetlandPlant,
  setWetlandPlantName,
  setWetlandPlantIndicator,
  setWetlandPlantCover,
  toggleCurrentWetlandFormUseSubregionOverrides,
  calcDominantPlants,
  hydrophyticCalcs,
  toggleWetlandIndicator,
  changeCurrentWetlandFormSoilLayerItem,
  changeCurrentWetlandFormColorItem,
  changeCurrentWetlandFormPage,
  changeCurrentWetlandFormSoilLayerValue,
  changeCurrentWetlandFormSoilLayerChroma,
  changeCurrentWetlandFormSoilLayerHue,
  addCurrentWetlandSoilLayer,
  deleteCurrentWetlandSoilLayer,
  addCurrentWetlandSoilColorItem,
  deleteCurrentWetlandSoilColorItem,
  changeCurrentWetlandPhotoDirectionAtIndex,
  changeCurrentWetlandPhotoAttributeAtIndex,
  changeCurrentWetlandPhotoNoteAtIndex,
  addCurrentWetlandPhotoReplacementAtIndex,
  addCurrentWetlandPhotoItem,
  markCurrentWetlandPhotoForDeletionAtIndex,
  setCurrentWetlandFormPhotoAtIndex,
} = wetlandFormsSlice.actions;

export const selectWetlandFormsLoadingStatus = (state) => state.wetlandForms.status;
export const selectWetlandForms = (state) => state.wetlandForms.forms;
export const selectSelectedWetlandForms = (state) => state.wetlandForms.selectedForms;
export const selectSelectedWetlandFormIds = (state) => state.wetlandForms.selectedForms.map((item) => item.id);
export const selectCurrentWetlandFormActionType = (state) => state.wetlandForms.currentFormActionType;
export const selectCurrentWetlandForm = (state) => state.wetlandForms.currentForm;
export const selectCurrentWetlandFormId = (state) => state.wetlandForms.currentForm?.id;
export const selectCurrentWetlandFormSampleName = (state) => state.wetlandForms.currentForm?.sampleName;
export const selectCurrentWetlandFormUuid = (state) => state.wetlandForms.currentForm?.uuid;
export const selectCurrentWetlandFormRegion = (state) => state.wetlandForms.currentForm?.aceRegion;
export const selectCurrentWetlandFormProjectId = (state) => state.wetlandForms.currentForm?.project;
export const selectCurrentWetlandFormUser = (state) => state.wetlandForms.currentForm?.user;
export const selectCurrentWetlandFormShowFiveStrataToggle = (state) =>
  ["EMP", "AGCP"].includes(state.wetlandForms.currentForm?.aceRegion);
export const selectCurrentWetlandFormShowSubregionIndOverrideToggle = (state) =>
  ["NCNE", "AGCP"].includes(state.wetlandForms.currentForm?.aceRegion);
export const selectCurrentWetlandFormUseSubregionIndicatorIverrides = (state) => {
  const useOverrides = state.wetlandForms.currentForm?.useSubregionIndicatorOverrides;
  if (useOverrides) {
    return useOverrides;
  }
  return false;
};
export const selectCurrentFormShowFifthStrata = (state) => state.wetlandForms.currentForm?.useFiveStrata ?? false;
export const selectCurrentWetlandFormPlants = (state) => state.wetlandForms.currentForm?.wetlandPlants;
export const selectCurrentWetlandFormPrevIndTotal = (state) => {
  const total =
    parseFloat(state.wetlandForms.currentForm?.prevIndObl) +
    parseFloat(state.wetlandForms.currentForm?.prevIndFacw) * 2 +
    parseFloat(state.wetlandForms.currentForm?.prevIndFac) * 3 +
    parseFloat(state.wetlandForms.currentForm?.prevIndFacu) * 4 +
    parseFloat(state.wetlandForms.currentForm?.prevIndUpl) * 5;
  return total.toFixed(2);
};
export const selectCurrentWetlandFormCalculationItems = (state) => {
  return {
    rapidTest: state.wetlandForms.currentForm?.rapidTest,
    dominanceTest: state.wetlandForms.currentForm?.dominanceTest,
    prevalenceIndex: state.wetlandForms.currentForm?.prevalenceIndex,
    prevalenceIndexMet: state.wetlandForms.currentForm?.prevalenceIndex < 3.0 ? true : false,
    morphAdaptations: state.wetlandForms.currentForm?.morphAdaptations,
    vegNaturallyProb: state.wetlandForms.currentForm?.vegNaturallyProb,
    problematicHydrophyticVeg: state.wetlandForms.currentForm?.problematicHydrophyticVeg,
    wetlandVeg: state.wetlandForms.currentForm?.wetlandVeg,
  };
};
export const selectCurrentWetlandPrevIndexAsRoundedString = (state) => {
  if (state.wetlandForms.currentForm) {
    if (!isNaN(state.wetlandForms.currentForm?.prevalenceIndex)) {
      return Number(state.wetlandForms.currentForm?.prevalenceIndex).toFixed(3);
    }
  }
  return null;
};
export const selectCurrentWetlandVegComment = (state) => state.wetlandForms.currentForm?.vegComment;

export const selectIndicatorListItems = (state) => [
  ...state.wetlandForms.currentForm?.wetlandHydroIndicators,
  ...state.wetlandForms.currentForm?.wetlandSoilIndicators,
];
export const selectCurrentWetlandSoilLayers = (state) => state.wetlandForms.currentForm?.wetlandSoilLayers;
export const selectCurrentWetlandMatrixColorItems = (state, layerIndex) => state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].matrixColorItems;
export const selectCurrentWetlandRedoxColorItems = (state, layerIndex) => state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].redoxColorItems;
export const selectCurrentWetlandSoilLayerMatrixColorsLength = (state, layerIndex) => {
  if (!state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].matrixColorItems) return 0;
  return state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].matrixColorItems.length;
};
export const selectCurrentWetlandSoilLayerRedoxColorsLength = (state, layerIndex) => {
  if (!state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].redoxColorItems) return 0;
  return state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].redoxColorItems.length;
};
export const selectCurrentWetlandSoilLayerRedoxContrasts = (state, layerIndex, redoxColorIndex) => {
  // Given the layer index and the index of the redox color, return a concatenated string of the contrasts between the redox color and the matrix colors
  let contrasts = [];
  const redoxItem = state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].redoxColorItems[redoxColorIndex];
  // Though there should only ever be an appropriate number of redox items, on initial render this might be called for all 3 possible redox items.
  // If so, and no redox item is present in the layer at the given color index, checking for undefined prevents an error trying to read the redox item properties
  if (redoxItem !== undefined) {
    state.wetlandForms.currentForm?.wetlandSoilLayers[layerIndex].matrixColorItems.forEach((matrixItem) => {
      let contrast = getRedoxContrast(matrixItem, redoxItem);
      let matrixColorAsString = `${matrixItem.matrixHue} ${matrixItem.matrixValue}/${matrixItem.matrixChroma}`;
      contrasts.push(`${matrixColorAsString}: ${contrast}`);
    });
  }
  
  return contrasts.join(', ');
};
export const selectCurrentWetlandSoils = (state) => state.wetlandForms.currentForm?.wetlandSoils ?? false;
export const selectCurrentWetlandSoilsComment = (state) => state.wetlandForms.currentForm?.soilsComment;
export const selectCurrentWetlandFormPhotosLoading = (state) => state.wetlandForms.currentForm?.photosLoading ?? false;
export const selectCurrentWetlandFormPhotos = (state) => {
  if (!state.wetlandForms?.currentForm?.photos) return [];
  return state.wetlandForms?.currentForm?.photos;
};
export const selectCurrentWetlandFormWetlandIndicators = (state) => {
  return {
    wetlandVeg: state.wetlandForms.currentForm?.wetlandVeg,
    wetlandHydro: state.wetlandForms.currentForm?.wetlandHydro,
    wetlandSoils: state.wetlandForms.currentForm?.wetlandSoils,
  };
};

export default wetlandFormsSlice.reducer;
