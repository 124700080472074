import React, { Fragment } from "react";
import { format } from "date-fns";
import {
  Avatar,
  Box,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { colorGreenStandard, colorGreyLight } from "../../../utils/colors";
import InviteDialog from "./InviteDialog";
import { useSelector } from "react-redux";
import { selectAccessibleOrgMembers } from "../../../api/services/user/userSlice";
import OrganizationMemberItemSecondaryActions from "./OrganizationMemberItemSecondaryActions";

const userRoleForDisplay = {
  regular: "Standard User",
  orgManager: "Organization Manager",
};

export default function OrganizationMembersSection({ inviteStatus, userRole = "regular" }) {
  const accessibleOrgMembers = useSelector(selectAccessibleOrgMembers);

  // This content is only rendered if the logged in user is an organization manager
  return (
    <Card sx={{ p: 2, minHeight: 240 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Organization Members
      </Typography>
      {(accessibleOrgMembers && inviteStatus === "approved") ? (
        <Fragment>
          <List>
            {accessibleOrgMembers.map((userItem, index) => {
              return (
                <ListItem
                  key={`org-member-list-item-${index}`}
                  secondaryAction={
                    userRole === "orgManager" && <OrganizationMemberItemSecondaryActions userItem={userItem} />
                  }
                >
                  <Tooltip
                    title={
                      userItem.has_good_subscription === true
                        ? `Subscribed Until ${format(new Date(userItem.subscribed_until_date), "MM/dd/yyyy")}`
                        : "Not Subscribed"
                    }
                  >
                    <ListItemAvatar>
                      {userItem.has_good_subscription === true ? (
                        <Avatar sx={{ bgcolor: colorGreenStandard }}>
                          <HowToRegIcon />
                        </Avatar>
                      ) : (
                        <Avatar>
                          <MoneyOffIcon />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={userItem.email || ""} secondary={userRoleForDisplay[userItem.org_user_role]} />
                </ListItem>
              );
            })}
          </List>
          {userRole === 'orgManager' && <InviteDialog />}
        </Fragment>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" py={6}>
          <Typography color={colorGreyLight}>No Organization Members</Typography>
        </Box>
      )}
    </Card>
  );
}
