import {
  Box,
  CircularProgress,
  Stack,
  Toolbar,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  List,
  ListSubheader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProject,
  getProjects,
  selectProjects,
  selectSelectedProject,
  selectSelectedProjectLoadingStatus,
  setProjectsLoadingStatus,
} from "../../api/services/projects/projectsSlice";
import Footer from "../../features/footer/Footer";
import Header from "../../features/header/Header";
import { requestStatuses } from "../../utils/constants";
import FormsList from "../../features/formslist/FormsList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderIcon from "@mui/icons-material/Folder";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { selectUser } from "../../api/services/user/userSlice";
import ProjectInfoSheet from "../../features/projects/ProjectInfoSheet";
import { resetFormsLoadingStatus } from "../../api/services/wetlandforms/wetlandFormsSlice";
import ActionButtonsToolbar from "../Home/components/ActionButtonsToolbar";
import { userAssignmentPermissionOptions } from "../../features/projects/userAssignmentPermissionOptions";

export default function ProjectPage() {
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);
  const projectLoadingStatus = useSelector(selectSelectedProjectLoadingStatus);
  const location = useLocation();
  const navigate = useNavigate();
  const projects = useSelector(selectProjects);
  const user = useSelector(selectUser);

  const [projectsDrawerItems, setProjectsDrawerItems] = useState([]);

  useEffect(() => {
    dispatch(resetFormsLoadingStatus());
    // In the future, might change to just one request to get all projects. Then, match the slug and id with the url to select the active project
    // For now, only get projects if there is no project data in the redux state
    if (!projects) {
      dispatch(getProjects());
    }

    // Whenever this page is navigated to from any source, sets the selected project to the project's data (either immediately or when loaded) as
    // well. Note that this is done within the getProject async function
    // If the project data is null (user has gone to URL directly, is refreshing page, etc.), load the project data and get the forms associated with it
    if (location.state) {
      // If going directly to this page, initial project data won't be passed
      if (location.state.initialProjectData) {
        dispatch(
          getProject({ slug: location.state.initialProjectData.slug, id: location.state.initialProjectData.id })
        );
      }
    } else {
      // Extract the slug and uuid from the URL, use that to query
      const slugAndUuid = location.pathname.split("/projects/")[1];
      // UUID has 4 dashes in it
      const splitSlugUuid = slugAndUuid.split("-");
      const slugSlice = splitSlugUuid.slice(0, -5);
      const uuidSlice = splitSlugUuid.slice(-5);
      const slug = slugSlice.join("-");
      const uuid = uuidSlice.join("-");
      dispatch(getProject({ slug: slug, id: uuid }));
    }
  }, [dispatch, location]);

  const dynamicIconByAccessRole = (userRole) => {
    if (!userRole) return <NoAccountsIcon />
    if (userRole.permission_level === 'ProjectEditor') return <AdminPanelSettingsIcon color="secondary" />
    if (userRole.permission_level === 'ReadWriteAll') return <SupervisedUserCircleIcon color="secondary" />
    if (userRole.permission_level === 'ReadAllWriteOwn') return <SupervisedUserCircleIcon color="secondary" />
    return <AccountCircleIcon color="secondary" />
  }

  useEffect(() => {
    // Also, when page loads, and when the projects state is refreshed (after request) set the drawer items based on this data
    if (projects) {
      if (user) {
        // When the user data is also loaded, modify the projects state to include the assignment of the logged-in user to each project
        const projectsModified = [
          ...projects.map((project) => ({
            ...project,
            user_role: project.assignment_projects.find((assign) => assign.user.email === user.email),
          })),
        ];
        console.log(projectsModified);
        // If user_role is undefined, the user is just not assigned to the project in any manner (can view the project, but isn't assigned. Might be able to edit if org manager)
        setProjectsDrawerItems([
          ...projectsModified.map((projItem) => ({
            name: projItem.name,
            icon: dynamicIconByAccessRole(projItem.user_role),
            enabled: true,
            ...projItem,
          })),
        ]);
      } else {
        setProjectsDrawerItems([
          ...projects.map((projItem) => ({ name: projItem.name, icon: <FolderIcon />, enabled: true, ...projItem })),
        ]);
      }
    }
  }, [dispatch, location, projects, user]);

  // TODO: make FormsList reusable, fetch project's data forms only and display here (all regions, include ACE Region as a column in data table)

  const handleBackToProjectsClick = () => {
    // Navigate back to home (not just -1, because that may not guarantee going back to the home page.)
    // Also make sure that the "projects" tab is the selected one.
    localStorage.setItem("activeTabIndex", 0); // Just set the local storage active tab, because the home page will check for this when rendering
    // Set the projects loading status back to idle, so that navigating to the projects page triggers a re-fetch of project data
    dispatch(setProjectsLoadingStatus(requestStatuses.IDLE));
    navigate("/");
  };

  const handleDrawerItemClick = (index, item) => {
    // Switch to the selected project.
    navigate(`/projects/${item.slug}-${item.id}`, { state: { initialProjectData: item } });
  };

  const userRoleToString = (project) => {
    // Based on the user_role property that might be appended to the project, return the appropriate text describing the user's role
    if (project.user_role) {
      if (project.user_role.permission_level === "ProjectEditor") {
        return "Project Editor";
      } else {
        const permissionLevel = userAssignmentPermissionOptions.find(
          (option) => option.value === project.user_role.permission_level
        );
        if (permissionLevel) return permissionLevel.displayName;
        // return project.user_role.permission_level;
        return "Standard User";
      }
    }
    return "None";
  };

  const activeProjectsDrawerElements = projectsDrawerItems
  .filter((item) => item.status === 'Active')
  .map((item, index) => (
    <ListItemButton
      selected={item?.id === selectedProject?.id}
      key={`${item.name}-${index}`}
      onClick={() => handleDrawerItemClick(index, item)}
      disabled={!item.enabled}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} secondary={`Role: ${userRoleToString(item)}`} />
    </ListItemButton>
  ));

  const nonActiveProjectsDrawerElements = projectsDrawerItems
  .filter((item) => item.status !== 'Active')
  .map((item, index) => (
    <ListItemButton
      selected={item?.id === selectedProject?.id}
      key={`${item.name}-${index}`}
      onClick={() => handleDrawerItemClick(index, item)}
      disabled={!item.enabled}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} secondary={`Role: ${userRoleToString(item)}`} />
    </ListItemButton>
  ));

  return (
    <Header
      customDrawerListComponent={
        <List component="nav" aria-label="list of projects" sx={{ width: 240 }}>
          <ListItemButton key="back-to-projects-button" onClick={() => handleBackToProjectsClick()}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back to Projects" />
          </ListItemButton>
          <List
            component="div"
            disablePadding
            subheader={
              <ListSubheader component="div" id="active-projects-list-subheader">
                Active Projects
              </ListSubheader>
            }
          >
            {activeProjectsDrawerElements}
          </List>
          <List
            component="div"
            disablePadding
            subheader={
              <ListSubheader component="div" id="nonactive-projects-list-subheader">
                Non-Active Projects
              </ListSubheader>
            }
          >
            {nonActiveProjectsDrawerElements}
          </List>
        </List>
      }
    >
      <Toolbar />
      <Box sx={{ minHeight: "90vh", px: 2 }}>
        {projectLoadingStatus === requestStatuses.ERROR && (
          <Box justifyContent="center" sx={{ display: "flex", px: 4, py: 12 }}>
            <div>Project not found. The project slug has been updated, or the project has been deleted.</div>
          </Box>
        )}
        {projectLoadingStatus === requestStatuses.LOADING && (
          <Box justifyContent="center" sx={{ display: "flex", p: 12 }}>
            <CircularProgress color="secondary" />
          </Box>
        )}
        {projectLoadingStatus === requestStatuses.SUCCESS && selectedProject && (
          <Stack>
            <ProjectInfoSheet selectedProjectData={selectedProject} />
            <Box sx={{ mb: 2 }}></Box>
            <ActionButtonsToolbar />
            <FormsList useProjectView={true} project_id={selectedProject.id} />
          </Stack>
        )}
      </Box>

      <Footer />
    </Header>
  );
}
