import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import {
  changeCurrentWetlandFormSoilLayerItem,
  selectCurrentWetlandSoilLayers,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import { colorGreyLight } from "../../../../utils/colors";

const soilTextures = {
  'C': ' Clay',
  'CL': ' Clay Loam',
  'COS': ' Coarse Sand',
  'COSL': ' Coarse Sandy Loam',
  'FS': ' Fine Sand',
  'FSL': ' Fine Sandy Loam',
  'L': ' Loam',
  'LCOS': ' Loamy Coarse Sand',
  'LFS': ' Loamy Fine Sand',
  'LS': ' Loamy Sand',
  'LVFS': ' Loamy Very Fine Sand',
  'MUCK': ' Muck',
  'MMI': ' Mucky Mineral',
  'MPT': ' Mucky Peat',
  'PEAT': ' Peat',
  'S': ' Sand',
  'SC': ' Sandy Clay',
  'SCL': ' Sandy Clay Loam',
  'SI': ' Silt',
  'SIC': ' Silty Clay',
  'SICL': ' Silty Clay Loam',
  'SIL': ' Silt Loam',
  'SL': ' Sandy Loam',
  'VFS': ' Very Fine Sand',
  'VFSL': ' Very Fine Sandy Loam',
};

export default function SoilLayerTextureCommentRow({ index }) {
  const soilLayers = useSelector(selectCurrentWetlandSoilLayers);
  const layer = soilLayers[index];
  const dispatch = useDispatch();

  const handleSoilLayerStandardItemChange = (e) => {
    if (e !== null) {
      dispatch(changeCurrentWetlandFormSoilLayerItem({ index: index, name: e.target.name, value: e.target.value }));
    }
  };

  const handleSoilTextureChange = (e) => {
    if (e !== null) {
      dispatch(changeCurrentWetlandFormSoilLayerItem({ index: index, name: 'texture', value: e.target.value }));
    }
  };

  const handleSoilLayerTextureSelect = (e, newValue) => {
    dispatch(changeCurrentWetlandFormSoilLayerItem({ index: index, name: 'texture', value: newValue }));
  };

  return (
    <Fragment>
      <Grid item xs={4} md={4} alignSelf="center">
        {/* <TextField
          id={`soil-layer-texture-${index}`}
          name="texture"
          label="Texture"
          value={layer.texture ?? ""}
          onChange={handleSoilLayerStandardItemChange}
          fullWidth
        /> */}
        <Autocomplete
          id={`soil-layer-texture-${index}`}
          name="texture"
          value={layer.texture ?? ""}
          freeSolo
          openOnFocus
          isOptionEqualToValue={(option, value) => option === value}
          options={Object.keys(soilTextures)}
          filterOptions={(x) => x}
          onChange={handleSoilLayerTextureSelect} // onChange here is more akin to an onSelect
          onInputChange={handleSoilTextureChange}
          renderInput={(params) => <TextField {...params} label="Texture" style={{ textOverflow: "none" }} />}
          disableClearable
          renderOption={(props, option) => {
            return (
              <Box {...props}>
                <Typography>{option}</Typography>
                {option && <Typography color={colorGreyLight}>&nbsp; - {soilTextures[option]}</Typography>}
              </Box>
            );
          }}
        />
      </Grid>
      <Grid item xs={8} md={8} alignSelf="center">
        <TextField
          id={`soil-layer-comment-${index}`}
          name="comment"
          label="Comment"
          value={layer.comment ?? ""}
          onChange={handleSoilLayerStandardItemChange}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
}
