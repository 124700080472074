import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Tabs,
  Tab,
  Typography,
  Stack,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Snackbar,
  Alert,
  Toolbar,
} from "@mui/material";
import {
  getWetlandForm,
  selectCurrentWetlandFormSampleName,
  selectCurrentWetlandFormRegion,
  changeCurrentWetlandFormValue,
  getAllWetlandFormsAsync,
  saveCurrentWetlandForm,
  getWetlandFormPhotos,
  syncWetlandPhotos,
  selectCurrentWetlandFormWetlandIndicators,
  selectCurrentWetlandFormActionType,
  setAddCurrentWetlandFormNewValues,
  resetFormsLoadingStatus,
  setAddCurrentWetlandFormProjectValues,
  setCurrentWetlandLatLong,
  selectCurrentWetlandFormUser,
  selectCurrentWetlandFormProjectId,
  selectCurrentWetlandFormUuid,
} from "../../api/services/wetlandforms/wetlandFormsSlice";
import { formActionType } from "../../utils/constants";
import GeneralsTab from "./GeneralsTab/GeneralsTab";
import { useTheme } from "@mui/material/styles";
import VegetationTab from "./VegetationTab/VegetationTab";
import HydrologyTab from "./HydrologyTab/HydrologyTab";
import SoilsTab from "./SoilsTab/SoilsTab";
import SummaryTab from "./SummaryTab/SummaryTab";
import PhotosTab from "./PhotosTab/PhotosTab";
import { getUser, selectUser } from "../../api/services/user/userSlice";
import Header from "../../features/header/Header";
import Footer from "../../features/footer/Footer";
import { getProjects, selectProjects } from "../../api/services/projects/projectsSlice";
import { selectUseCurrentLocationForNewFormLatLng } from "../../api/services/settings/settingsSlice";

const wetlandTabs = {
  GENERAL: "general",
  VEGETATION: "vegetation",
  HYDROLOGY: "hydrology",
  SOILS: "soils",
  SUMMARY: "summary",
  PHOTOS: "photos",
};

function tabItemProps(index) {
  return {
    id: `wetland-tab-${index}`,
    "aria-controls": `wetland-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wetland-tabpanel-${index}`}
      aria-labelledby={`wetland-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 2, py: 4 }}>{children}</Box>}
    </div>
  );
}

// function LeavePageDialogPopup(props) {
//   const { open, handleClose, onLeaveWithoutSaving } = props;
//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>Leave Page?</DialogTitle>
//       <DialogContent>
//         <DialogContentText>Leaving page without saving will discard all unsaved changes.</DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>
//         <Button variant="contained" color="error" onClick={onLeaveWithoutSaving}>
//           Leave Without Saving
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }

export default function WetlandForm({ ...props }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  // const linkState = useParams();
  const dispatch = useDispatch();
  const currentFormActionType = useSelector(selectCurrentWetlandFormActionType);
  const useCurrentLocationForNewFormLatLng = useSelector(selectUseCurrentLocationForNewFormLatLng);
  const sampleName = useSelector(selectCurrentWetlandFormSampleName);
  const uuid = useSelector(selectCurrentWetlandFormUuid);
  const allForms = useSelector((state) => state.wetlandForms.forms);
  const aceRegion = useSelector(selectCurrentWetlandFormRegion);
  const user = useSelector(selectUser);
  const storedRegion = useSelector((state) => state.wetlandForms.region);
  const projects = useSelector(selectProjects);
  const currentFormProjectId = useSelector(selectCurrentWetlandFormProjectId);
  const currentFormUser = useSelector(selectCurrentWetlandFormUser);
  const [userProjectAssignment, setUserProjectAssignment] = useState(null); // Used to determine if the "save" button should be clicable

  const triggerGeolocationFillCoordinates = () => {
    // Note - geolocation might be blocked in testing due to not using https. In testing, run with HTTPS=true
    function geolocationSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      dispatch(setCurrentWetlandLatLong({ latitude: latitude, longitude: longitude }));
    }
  
    function geolocationError() {
      console.log("Unable to retrieve your location");
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geolocationSuccess, geolocationError, {timeout: 15000, enableHighAccuracy: true});
    } else {
      console.log('Geolocation not supported');
    }
  }
  

  // const [leavePageConfirmOpen, setLeavePageConfirmOpen] = useState(false);

  // const handleCloseLeavePageConfirmDialog = () => {
  //   setLeavePageConfirmOpen(false);
  // };

  // const handleOpenLeavePageConfirmDialog = () => {
  //   setLeavePageConfirmOpen(true);
  // };

  // const handleLeavePageWithoutSaving = () => {
  //   setLeavePageConfirmOpen(false);
  // };

  // useEffect(() => {
  //   return function onCleanup() {
  //     // Ask if user wants to save before leaving, leave without saving, or cancel
  //     // const currData = currentForm;
  //     console.log("useEffect cleanup called!");
  //     // TODO: dispatch event to redux state, show popup over home screen (or header?) asking for save current form data or not.
  //   };
  // }, []);

  const setProjectAssignmentLocalStateFromProjectData = (projectData) => {
    // Given the project object, find the assignment matching the user and set the assignment in local state.
    if (projectData && user) {
      const userAssignment = projectData.assignment_projects.find(assignment => assignment.user.email === user.email);
      if (userAssignment) {
        setUserProjectAssignment(userAssignment);
      }
    }
  }

  const userHasSavePermission = () => {
    if (!userProjectAssignment) return true;
    // TODO: user is project creator
    if (projects) {
      const projectData = projects.find(proj => proj.id === currentFormProjectId);
      if (projectData) {
        if (projectData.created_by.email === user.email) return true;
      }
    }
    if (userProjectAssignment.permission_level === "ProjectEditor" || userProjectAssignment.permission_level === "ReadWriteAll") return true;
    if (userProjectAssignment.permission_level === 'ReadAllWriteOwn' && currentFormUser.email === user.email) return true;
    if (userProjectAssignment.permission_level === 'ReadWriteOwn' && currentFormUser.email === user.email) return true;
    return false;
  }

  useEffect(() => {
    // Reset scroll to top of page
    window.scrollTo(0, 0);
    // If forms are null (user has gone to this URL directly, or is refreshing the page, not from the home page), load forms
    if (!allForms) {
      // There is no guarantee that formActionType will actually be edit, at this point just assume based on sample name if adding or editing.
      dispatch(getAllWetlandFormsAsync({ filterByCurrentRegion: false })).then((res) => {
        let sampleNameFromUrl = location.pathname.split("wetland/")[1];
        let cleanedSampleNameFromUrl = decodeURIComponent(sampleNameFromUrl);
        const matchingForm = res?.payload?.find((form) => form.sampleName === cleanedSampleNameFromUrl);
        dispatch(getUser()).then((userRes) => {
          if (matchingForm) {
            dispatch(getWetlandFormPhotos(matchingForm?.id));
          } else {
            dispatch(setAddCurrentWetlandFormNewValues({ user: userRes.payload, region: storedRegion }));
            // This is the only point at which the geolocation should be triggered as well
            if (useCurrentLocationForNewFormLatLng) {
              triggerGeolocationFillCoordinates();
            }
          }
        });
      });
    }
    // If editing an existing form, make a new GET request to get the most updated version of that form's data
    if (location.state) {
      if (location.state.actionType === formActionType.EDIT) {
        dispatch(getWetlandForm(location.state.formId)).then((res) => {
          // After getting the wetland form data, if it has a non-null project ID, load that project data (either from Redux state if existing there, or make a GET request for specific project)
          if (res?.payload?.project) {
            // Attempt to find in redux state of projects
            if (projects) {
              const projectData = projects.find(proj => proj.id === res.payload.project);
              if (projectData) {
                setProjectAssignmentLocalStateFromProjectData(projectData);
                dispatch(setAddCurrentWetlandFormProjectValues(projectData));
              }
            } else {
              dispatch(getProjects()).then((projectsResponse) => {
                if (projectsResponse.payload) {
                  const projectData = projectsResponse.payload.find(proj => proj.id === res.payload.project);
                  if (projectData) {
                    setProjectAssignmentLocalStateFromProjectData(projectData);
                    dispatch(setAddCurrentWetlandFormProjectValues(projectData));
                  }
                }
              });
            }
          }
        });
        dispatch(getWetlandFormPhotos(location.state.formId));
      } else {
        // Adding a new form, trigger attempt to fill coordinates from geolocation
        if (useCurrentLocationForNewFormLatLng) {
          triggerGeolocationFillCoordinates();
        }
      }
    }
  }, [location.state, dispatch, location.pathname]);

  const { wetlandVeg, wetlandHydro, wetlandSoils } = useSelector(selectCurrentWetlandFormWetlandIndicators);
  // Listen to the veg, hydro, and soil indicator booleans, and set isWetland based on that
  useEffect(() => {
    if (wetlandVeg === true && wetlandHydro === true && wetlandSoils === true) {
      dispatch(changeCurrentWetlandFormValue({ name: "isWetland", value: true }));
    } else if (wetlandVeg === false || wetlandHydro === false || wetlandSoils === false) {
      dispatch(changeCurrentWetlandFormValue({ name: "isWetland", value: false }));
    }
  }, [dispatch, wetlandVeg, wetlandHydro, wetlandSoils]);

  const [tab, setTab] = useState(wetlandTabs.GENERAL);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleChangeRegion = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: "aceRegion", value: e.target.value }));
  };

  const [snackbarInfo, updateSnackbarInfo] = useState({
    showSnackbar: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    updateSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    updateSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  const [photosSnackbarInfo, updatePhotosSnackbarInfo] = useState({
    showSnackbar: false,
    severity: "success",
    uploadMessage: "",
    removesMessage: "",
  });

  const handleOpenPhotosSnackbar = (severity, uploadMessage, removesMessage) => {
    updatePhotosSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      uploadMessage: uploadMessage,
      removesMessage: removesMessage,
    });
  };

  const handleClosePhotosSnackbar = () => {
    updatePhotosSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  const handleNavigateBackToHomeScreen = (e) => {
    e.preventDefault();
    dispatch(resetFormsLoadingStatus()); // Set the forms loading status back to IDLE to trigger a new fetch of all the ACE forms
    navigate(-1); // Navigate back to the previous page, whether that is the home page or the project page
  };

  // const handleSaveAndNavigateBackToHomeScreen = (e) => {
  //   e.preventDefault();
  //   handleSaveCurrentForm(e).then((_) => {
  //     dispatch(resetFormsLoadingStatus()); // Set the forms loading status back to IDLE to trigger a new fetch of all the ACE forms
  //     navigate("/");
  //   });
  // };

  const handleSaveCurrentForm = (e) => {
    e.preventDefault();
    dispatch(saveCurrentWetlandForm()).then((res) => {
      let severity = "success";
      let message = "Form saved successfully";
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        severity = "success";
        message = "Form saved successfully";
        dispatch(syncWetlandPhotos()).then((photosRes) => {
          if (photosRes.status === 200 || photosRes.status === 201) {
            const payload = photosRes.payload;
            let photosSuccessesMessage = `Successfully uploaded ${payload.uploads.successes.length} photos. Failed to upload ${payload.uploads.failures.length} photos.`;
            let photosFailuresMessage = `Successfully removed ${payload.removes.successes.length} photos. Failed to remove ${payload.removes.failures.length} photos.`;
            let photosSeverity =
              payload.uploads.failures.length === 0 && payload.removes.failures.length === 0 ? "success" : "error";
            handleOpenPhotosSnackbar(photosSeverity, photosSuccessesMessage, photosFailuresMessage);
          }
        });
      } else {
        // console.log(JSON.stringify(currentForm, null, " "));
        // console.log(res.error);
        // console.log(Object.getOwnPropertyNames(res.error));
        // console.log(res.error.message);
        severity = "error";
        message = `Error saving form: ${res.error.message}`;
      }
      handleOpenSnackbar(severity, message);
    });
  };

  function SampleNameTitleRow() {
    let prefix = "Edit";
    if (location.state) {
      prefix = location.state.actionType === formActionType.ADD ? "Add" : "Edit";
    }
    return (
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center"
        sx={{ mx: 2 }}
      >
        <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
          {prefix}
        </Typography>
        <Typography variant="h5" component="h1">
          {sampleName}
        </Typography>
        <FormControl sx={{ minWidth: 160 }} error={aceRegion === "Unspecified"}>
          <InputLabel id="ace-region-select-label">ACE Region</InputLabel>
          <Select
            labelId="ace-region-select-label"
            id="aceRegion"
            value={aceRegion ?? "NCNE"}
            label="ACE Region"
            onChange={handleChangeRegion}
            sx={{ color: aceRegion === "Unspecified" ? theme.palette.text.error : theme.palette.text.primary }}
          >
            <MenuItem value={"AGCP"}>AGCP</MenuItem>
            <MenuItem value={"AK"}>AK</MenuItem>
            <MenuItem value={"AW"}>AW</MenuItem>
            <MenuItem value={"CB"}>CB</MenuItem>
            <MenuItem value={"EMP"}>EMP</MenuItem>
            <MenuItem value={"GP"}>GP</MenuItem>
            <MenuItem value={"HI"}>HI</MenuItem>
            <MenuItem value={"MW"}>MW</MenuItem>
            <MenuItem value={"NCNE"}>NCNE</MenuItem>
            <MenuItem value={"WMVC"}>WMVC</MenuItem>
            <MenuItem value={"Unspecified"}>Unspecified</MenuItem>
          </Select>
        </FormControl>
        <Typography sx={{ 
          color: theme.palette.text.secondary, 
          minWidth: 200,
          wordBreak: "break-all",
        }}>
          Wetland ID: {uuid}
        </Typography>
      </Stack>
    );
  }

  return (
    <Header 
      showDrawer={false}
      useFormEditPageLayout
      showBackAndSaveButtons
      hasSavePermission={userHasSavePermission()}
      onBackClick={handleNavigateBackToHomeScreen}
      onSaveClick={handleSaveCurrentForm}
    >
      <Box sx={{ px: 2 }}>
        {/* <LeavePageDialogPopup
          open={leavePageConfirmOpen}
          handleClose={handleCloseLeavePageConfirmDialog}
          onLeaveWithoutSaving={handleLeavePageWithoutSaving}
        /> */}
        <Snackbar open={snackbarInfo.showSnackbar} autoHideDuration={10000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarInfo.severity} variant="filled" sx={{ width: "100%" }}>
            {snackbarInfo.message}
          </Alert>
        </Snackbar>
        <Snackbar open={photosSnackbarInfo.showSnackbar} autoHideDuration={10000} onClose={handleClosePhotosSnackbar}>
          <Alert
            onClose={handleClosePhotosSnackbar}
            severity={photosSnackbarInfo.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            <Typography>{photosSnackbarInfo.uploadMessage}</Typography>
            <Typography>{photosSnackbarInfo.removesMessage}</Typography>
          </Alert>
        </Snackbar>
        <Toolbar />
        <Grid container justifyContent="space-between" alignItems="center" spacing={0} sx={{ mb: 2}}>
          <Grid item sm={12} md={7}>
            {SampleNameTitleRow()}
          </Grid>
          {/* <Grid item sm={6} md={5}>
            <Card raised sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-around">
                <Button variant="contained" color="warning" onClick={handleNavigateBackToHomeScreen}>
                  <ArrowBackIcon />
                  <Typography ml={1}>Back</Typography>
                </Button>
                <Button variant="contained" color="info" onClick={handleSaveCurrentForm}>
                  <SaveIcon />
                  <Typography ml={1}>Save Changes</Typography>
                </Button>
              </Stack>
            </Card>
          </Grid> */}
        </Grid>
        <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" aria-label="wetland form tabs">
          <Tab value={wetlandTabs.GENERAL} label="General/Spatial" {...tabItemProps[0]} />
          <Tab value={wetlandTabs.VEGETATION} label="Vegetation" {...tabItemProps[1]} />
          <Tab value={wetlandTabs.HYDROLOGY} label="Hydrology" {...tabItemProps[2]} />
          <Tab value={wetlandTabs.SOILS} label="Soils" {...tabItemProps[3]} />
          <Tab value={wetlandTabs.SUMMARY} label="Summary/QAQC" {...tabItemProps[4]} />
          <Tab value={wetlandTabs.PHOTOS} label="Photos" {...tabItemProps[5]} />
        </Tabs>
        <TabPanel value={tab} index={wetlandTabs.GENERAL}>
          <GeneralsTab />
        </TabPanel>
        <TabPanel value={tab} index={wetlandTabs.VEGETATION}>
          <VegetationTab />
        </TabPanel>
        <TabPanel value={tab} index={wetlandTabs.HYDROLOGY}>
          <HydrologyTab />
        </TabPanel>
        <TabPanel value={tab} index={wetlandTabs.SOILS}>
          <SoilsTab />
        </TabPanel>
        <TabPanel value={tab} index={wetlandTabs.SUMMARY}>
          <SummaryTab />
        </TabPanel>
        <TabPanel value={tab} index={wetlandTabs.PHOTOS}>
          <PhotosTab />
        </TabPanel>
      </Box>
      <Footer />
    </Header>
  );
}
