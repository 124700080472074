import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  ImageListItem,
  ImageListItemBar,
  Button,
  FormGroup,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { addCurrentWetlandPhotoReplacementAtIndex, changeCurrentWetlandPhotoDirectionAtIndex, changeCurrentWetlandPhotoAttributeAtIndex, changeCurrentWetlandPhotoNoteAtIndex, markCurrentWetlandPhotoForDeletionAtIndex } from "../../../../api/services/wetlandforms/wetlandFormsSlice";

const Input = styled("input")({
  display: "none",
});

export default function PhotoCard({ photo, index }) {
  const dispatch = useDispatch();

  const handleChangePhotoDirection = (e, index) => {
    e.preventDefault();
    dispatch(changeCurrentWetlandPhotoDirectionAtIndex({ index: index, value: e.target.value }));
  };

  const handleChangePhotoAttribute = (e, index, attribute) => {
    e.preventDefault();
    dispatch(changeCurrentWetlandPhotoAttributeAtIndex({ index: index, attribute: attribute, value: e.target.value }));
  };

  const handleChangePhotoInReport = (e, index, attribute) => {
    e.preventDefault();
    dispatch(changeCurrentWetlandPhotoAttributeAtIndex({ index: index, attribute: attribute, value: e.target.checked }));
  };

  const handleChangePhotoOrderInReport = (e, index, attribute) => {
    e.preventDefault();
    let parsedNumber = null;
    if (!isNaN(parseInt(e.target.value))) {
      parsedNumber = parseInt(e.target.value);
    }
    dispatch(changeCurrentWetlandPhotoAttributeAtIndex({ index: index, attribute: attribute, value: parsedNumber }));
  };

  const handleChangePhotoNote = (e, index) => {
    e.preventDefault();
    dispatch(changeCurrentWetlandPhotoNoteAtIndex({ index: index, value: e.target.value }));
  };

  const handleSelectImage = (e, index) => {
    e.preventDefault();
    // Selected image is added to the photo at the index to "replace" it, but this replace is only triggered when the user saves the form
    if (e.target.files[0]) {
      dispatch(
        addCurrentWetlandPhotoReplacementAtIndex({ index: index, image: URL.createObjectURL(e.target.files[0]) })
      );
    }
  };

  const handleDeletePhoto = (e, index) => {
    e.preventDefault();
    // Add a 'delete this item' flag, but don't actually delete it until the user saves the changes to the wetland form
    dispatch(markCurrentWetlandPhotoForDeletionAtIndex({ index: index }));
  };

  return (
    <Card raised>
      <React.Fragment>
        <CardContent>
          <ImageListItem>
            <img
              src={`${photo.image}`}
              // srcSet={`${photo.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={`Attached observation ${index + 1}`}
              loading="lazy"
              style={{ objectFit: 'contain', maxHeight: 400 }}
            />
            <ImageListItemBar
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
              }}
              actionIcon={
                <label htmlFor={`upload-image-${index}`}>
                  <Input
                    accept="image/*"
                    id={`upload-image-${index}`}
                    type="file"
                    hidden
                    onChange={(e) => handleSelectImage(e, index)}
                  />
                  <Button
                    component="span"
                    aria-label={`upload new photo to replace item at ${photo.id}`}
                    startIcon={<PhotoIcon />}
                    sx={{ color: "white" }}
                  >
                    Choose New Photo
                  </Button>
                </label>
              }
              position="top"
              actionPosition="left"
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
              <TextField
                id={`photo-direction-${photo.id}`}
                label="Direction"
                fullWidth
                value={photo.direction ?? ""}
                onChange={(e) => handleChangePhotoAttribute(e, index, 'direction')}
                size="small"
              />
              <TextField
                id={`photo-latitude-${photo.id}`}
                label="Latitude"
                fullWidth
                value={photo.latitude ?? ""}
                onChange={(e) => handleChangePhotoAttribute(e, index, 'latitude')}
                size="small"
              />
              <TextField
                id={`photo-longitude-${photo.id}`}
                label="Longitude"
                fullWidth
                value={photo.longitude ?? ""}
                onChange={(e) => handleChangePhotoAttribute(e, index, 'longitude')}
                size="small"
              />
            </Stack>
            <TextField
              id={`photo-note-${photo.id}`}
              label="Note"
              fullWidth
              value={photo.note ?? ""}
              onChange={(e) => handleChangePhotoNote(e, index)}
              size="small"
              sx={{ mt: 2 }}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
              <FormGroup sx={{ ml: 1 }}>
                <FormControlLabel 
                  control={<Switch 
                    size="small" 
                    id={`photo-in-report-${photo.id}`}
                    checked={photo.include_in_report ?? true}
                    onChange={(e) => handleChangePhotoInReport(e, index, 'include_in_report')} />
                    } 
                  label="Include in Report?" />
              </FormGroup>
              <TextField
                id={`photo-order-in-report-${photo.id}`}
                label="Photo Order"
                value={photo.photo_order_in_report ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  let isnum = /^\d+$/.test(value);
                  if (isnum || value === '') {
                    handleChangePhotoOrderInReport(e, index, 'photo_order_in_report');
                  }
                }}
                type="number"
                InputProps={{
                  inputProps: { min: 1 }
                }}
                size="small"
                sx={{ "maxWidth": 120 }}
              />
            </Stack>
          </ImageListItem>
        </CardContent>
        <CardActions>
          <Button
            component="span"
            aria-label={`Delete photo item with id: ${photo.id}`}
            startIcon={<DeleteIcon />}
            color="error"
            sx={{ color: "error" }}
            onClick={(e) => handleDeletePhoto(e, index)}
          >
            Delete
          </Button>
        </CardActions>
      </React.Fragment>
    </Card>
  );
}
