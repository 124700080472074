import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, TextField } from "@mui/material";
import { setAccessibleOrgMembers, setOrganization, userLeaveOrganization } from "../../../api/services/user/userSlice";

export default function LeaveOrganizationDialog({ email, showSnackbar }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [confirmText, setConfirmText] = useState("");

  const changeConfirmText = (e) => {
    setConfirmText(e.target.value);
  };

  const handleConfirmRemoveSelfFromgOrganization = (event) => {
    event.preventDefault();
    // Confirm text state must equal 'confirm'
    if (confirmText === "confirm" && email !== null) {
      dispatch(userLeaveOrganization(email)).then((res) => {
        // If the request was successful, update the list of accessible users, as well as the organization info itself.
        if (res.payload?.status === 200) {
          dispatch(setAccessibleOrgMembers(res.payload.data));
          showSnackbar("success", `Removed user with email address ${email} from the organization.`);
          dispatch(setOrganization(null));
          handleClose();
        } else {
          showSnackbar("error", `Error removing user from organization`);
        }
      });
    }
  };

  return (
    <Fragment>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Leave Organization
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Leave Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove you from your current organization. Note that this will require an organization manager to
            approve you to re-join the organization in the future, and if you are the last remaining organization
            manager, the organization will be deleted!
          </DialogContentText>
          <TextField
            autoFocus
            id="confirmLeaveOrganizationField"
            label="Type 'confirm' to leave organization"
            fullWidth
            margin="normal"
            value={confirmText}
            onChange={changeConfirmText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            disabled={confirmText !== "confirm"}
            onClick={handleConfirmRemoveSelfFromgOrganization}
          >
            Leave Organization
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
