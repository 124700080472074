import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CircularProgress } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PhotoIcon from '@mui/icons-material/Photo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { generateWetlandPhotosFromSelection, selectPhotosDownloadStatusLoading, setShouldCancelPhotosDownload } from "../../../api/services/filedownloads/fileDownloadsSlice";
import { selectSelectedWetlandFormIds } from "../../../api/services/wetlandforms/wetlandFormsSlice";

export default function GeneratePhotosButton() {
  const dispatch = useDispatch();
  const loading = useSelector(selectPhotosDownloadStatusLoading);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (loading) {
      dispatch(setShouldCancelPhotosDownload(true));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGeneratePhotos = (event, useOverlay) => {
    dispatch(generateWetlandPhotosFromSelection({ ids: selectedFormIds, useOverlay: useOverlay }));
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
    <Box sx={{ position: "relative" }}>
        <Button
          disabled={!selectedFormIds.length}
          edge="end"
          aria-label="generate photos from selection"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          color={(loading && selectedFormIds.length > 1) ? "warning" : null}
          onClick={handleClick}
          startIcon={(loading && selectedFormIds.length > 1) ? <CircularProgress
            size={18}
            sx={{
              color: "white",
              top: "50%",
              left: "50%",
            }}
          /> : <PhotoIcon />}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {(loading && selectedFormIds.length > 1) ? 'Cancel' : 'Photos'}
        </Button>
        {/* {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )} */}
      </Box>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleGeneratePhotos(e, false)} disableRipple>
          Raw Photos
        </MenuItem>
        <MenuItem onClick={(e) => handleGeneratePhotos(e, true)} disableRipple>
          With Stamped Overlay Info
        </MenuItem>
      </Menu>
    </React.Fragment>
    
  )
}
