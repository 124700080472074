import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Stack, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { performBulkUpdateFromCsv, queryBulkUpdateAffectedDataCheck } from "../../api/services/wetlandforms/wetlandFormsSlice";

const LoadingStates = Object.freeze({
  IDLE: 0,
  LOADING: 1,
  ERROR: 2,
  SUCCESS: 3
});

BatchUploadPopupButton.propTypes = {
  selectedFiles: PropTypes.array.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
};

export default function BatchUploadPopupButton(props) {
  const { selectedFiles, buttonDisabled } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStates.IDLE); // Use one single loading state for both the data checks and the actual data submission, as only one should be running at a time
  const [batchRequestCompleted, setBatchRequestCompleted] = useState(false);
  const [proposedDataChanges, setProposedDataChanges] = useState(undefined);
  const [confirmedDataChanges, setConfirmedDataChanges] = useState(undefined);
  const [bulkUpdateResponseMessage, setBulkUpdateResponseMessage] = useState(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPopup = (event) => {
    event.preventDefault();
    setOpen(true);
    setLoadingStatus(LoadingStates.LOADING);
    setConfirmedDataChanges(undefined);
    setBatchRequestCompleted(false);
    // This also dispatches a request to validate the CSV, and determine the extent of data changes
    dispatch(queryBulkUpdateAffectedDataCheck(selectedFiles[0])).then((res) => {
      if (res.payload?.status === 200) {
        setLoadingStatus(LoadingStates.SUCCESS);
        setProposedDataChanges(res.payload.data);
        setBulkUpdateResponseMessage(undefined);
      } else {
        setLoadingStatus(LoadingStates.ERROR);
        setProposedDataChanges(undefined);
        setBulkUpdateResponseMessage(res.payload.data);
      }
    });
  };

  const handleUploadFile = (event) => {
    event.preventDefault();
    setLoadingStatus(LoadingStates.LOADING);
    dispatch(performBulkUpdateFromCsv(selectedFiles[0])).then((res) => {
      if (res.payload.status === 200) {
        setLoadingStatus(LoadingStates.SUCCESS);
        setConfirmedDataChanges(res.payload.data);
        setBulkUpdateResponseMessage(`Successfully updated ${res.payload.data.length} data forms.`);
      } else {
        setLoadingStatus(LoadingStates.ERROR);
        setConfirmedDataChanges(undefined);
        setBulkUpdateResponseMessage(`Error updating data forms: ${res.payload.data}`);
      }
      setProposedDataChanges(undefined);
      setBatchRequestCompleted(true);
    });
  };

  const attributeValueDynamicColor = (value) => {
    if (typeof value === 'string' || value instanceof String) {
      return value.startsWith('Invalid ') ? 'text.error' : null;
    }
    return null;
  };

  const dataChangeItems = proposedDataChanges?.map(item => {
    const {uuid: _, ...itemNoUuid} = item;
    if (itemNoUuid.hasOwnProperty('sampleName')) {
      if (itemNoUuid['sampleName'][0] === itemNoUuid['sampleName'][1]) {
        delete itemNoUuid.sampleName;
      }
    }
    return (
      <Stack key={item.uuid}>
        <Stack direction="row" spacing={1}>
          <Typography>
            {item.sampleName[0]}
          </Typography>
          <Typography color="text.secondary">
            ({item.uuid})
          </Typography>
        </Stack>
        {Object.entries(itemNoUuid).map(([key, value]) => <Stack key={`${item.uuid}-${key}`} direction="row" spacing={1} ml={4}>
          <Typography color="text.secondary">
            {key}:
          </Typography>
          <Typography>
            {value[0] === '' ? '(blank)' : value[0].toString()}
          </Typography>
          <ChevronRightIcon />
          <Typography color={attributeValueDynamicColor(value[1])}>
            {value[1] === '' ? '(blank)' : value[1].toString()}
          </Typography>
        </Stack>)}
      </Stack>
    );
  });

  const dataChangeConfirmationItems = confirmedDataChanges?.map(item => {
    return (
      <Stack key={item.uuid} direction="row" spacing={1}>
        <Typography color="text.success">
          {item.sampleName}
        </Typography>
        <Typography color="text.success">
          ({item.uuid})
        </Typography>
      </Stack>
    );
  });

  const renderDialogContent = () => {
    switch(loadingStatus) {
      case LoadingStates.LOADING:
        return <CircularProgress size={32} color="info" sx={{ m: 8, alignSelf: 'center' }} />;
      case LoadingStates.SUCCESS:
        return (
          <DialogContentText color="text.success" pb={2}>
            {bulkUpdateResponseMessage !== undefined && bulkUpdateResponseMessage}
            {proposedDataChanges?.length === 0 && 'No changes detected.'}
          </DialogContentText>
        );
      case LoadingStates.ERROR:
        return (
          <DialogContentText color="text.error" pb={2}>
            {proposedDataChanges !== undefined && proposedDataChanges}
            {bulkUpdateResponseMessage !== undefined && bulkUpdateResponseMessage}
          </DialogContentText>
        );
      case LoadingStates.IDLE:
      default:
        return (
          <DialogContentText color="text.error" pb={2}>
            {proposedDataChanges !== undefined && `${proposedDataChanges?.length} form(s) will be permanently updated. These changes cannot be reversed.`}
          </DialogContentText>
        );
    }
  }

  return (
    <React.Fragment>
      <Button
        color="secondary"
        variant="contained"
        disabled={buttonDisabled}
        edge="end"
        aria-label="open the popup dialog to confirm batch update changes"
        aria-controls={open ? "batch-update-data-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenPopup}
        endIcon={<CloudUploadIcon /> }
      >
        Upload File
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Batch Update Form(s)</DialogTitle>
        {<DialogContent>
          {renderDialogContent()}
          {dataChangeItems}
          {dataChangeConfirmationItems}
        </DialogContent>}
        
        <DialogActions>
          {batchRequestCompleted ? <Button color="secondary" onClick={handleClose}>Close</Button> : <Fragment>
            <Button color="secondary" onClick={handleClose}>Cancel</Button>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleUploadFile} 
              disabled={loadingStatus === LoadingStates.LOADING || proposedDataChanges?.length === 0}
            >
              Perform Update
            </Button>
          </Fragment>}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
