import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Switch, FormGroup, FormControlLabel } from "@mui/material";
import { selectUseCurrentLocationForNewFormLatLng, selectUseStampedOverlayInPdfPhotos, setUseCurrentLocationForCoords, setUseStampedOverlayInPdfPhotos } from '../../../api/services/settings/settingsSlice';

export default function DataFormSettings() {
  const dispatch = useDispatch();
  const useCurrentLocationForNewFormLatLng = useSelector(selectUseCurrentLocationForNewFormLatLng);
  const useStampedOverlayInPdfPhotos = useSelector(selectUseStampedOverlayInPdfPhotos);

  const handleUseCurrentLocationChanged = (e) => {
    dispatch(setUseCurrentLocationForCoords(e.target.checked));
  };

  const handleSetUseStampedOverlayInPdfPhotos = (e) => {
    dispatch(setUseStampedOverlayInPdfPhotos(e.target.checked));
  };

  return (
    <Stack spacing={2}>
      <Typography component="h2" variant="h4">
        Data Form Settings
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="settingsUseCurrentLocationForNewFormLatLng"
              checked={useCurrentLocationForNewFormLatLng}
              onChange={handleUseCurrentLocationChanged}
              inputProps={{ "aria-label": "Automatically use your device's current location for latitude and longitude when collecting a new data form" }}
            />
          }
          label="Use your device's current location for latitude/longitude when adding a new data form"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="settingsUseStampedOverlayInPdfPhotos"
              checked={useStampedOverlayInPdfPhotos}
              onChange={handleSetUseStampedOverlayInPdfPhotos}
              inputProps={{ "aria-label": "Use a stamped overlay of relevant data overtop of photos in PDFs" }}
            />
          }
          label="Use stamped overlay of relevant data on photos in PDF outputs"
        />
      </FormGroup>
    </Stack>
  )
}
