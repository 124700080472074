import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid";
import {
  deleteWetlandForms,
  selectSelectedWetlandFormIds,
  setAddCurrentWetlandFormNewValues,
  setAddCurrentWetlandFormProjectValues,
  setWetlandFormActionType,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import DeleteFormsButton from "./DeleteFormsButton";
import { Button } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from "@mui/icons-material/Add";
import { formActionType } from "../../../utils/constants";
import { useNavigate } from "react-router";
import { selectUser } from "../../../api/services/user/userSlice";
import { selectSelectedProject, setSelectedProject } from "../../../api/services/projects/projectsSlice";
import AssignFormsToProjectButton from "./AssignFormsToProjectButton";

export default function CustomToolbar() {
  const region = useSelector((state) => state.wetlandForms.region);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  const user = useSelector(selectUser);
  const selectedProject = useSelector(selectSelectedProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddNewForm = (event) => {
    event.preventDefault();
    dispatch(setWetlandFormActionType(formActionType.ADD));
    dispatch(setAddCurrentWetlandFormNewValues({ user: user, region: region }));
    // If adding a new form from a specific project's list of forms, set that project's values as part of the new form defaults
    const activeTabIndex = parseInt(localStorage.getItem("activeTabIndex")) ?? 0; // Check the active tab index, and if not in the projects tab (tab 0), make sure the selected project is null
    if (activeTabIndex !== 0 && selectedProject !== null) {
      dispatch(setSelectedProject(null));
    }
    if (selectedProject) {
      dispatch(setAddCurrentWetlandFormProjectValues(selectedProject));
    }
    navigate(`/wetland/new`, { state: { actionType: formActionType.ADD, formId: null } });
  };

  const handleAddNewFormWithOverriddenRegion = (event, chosenRegion) => {
    event.preventDefault();
    dispatch(setWetlandFormActionType(formActionType.ADD));
    dispatch(setAddCurrentWetlandFormNewValues({ user: user, region: chosenRegion }));
    // If adding a new form from a specific project's list of forms, set that project's values as part of the new form defaults
    const activeTabIndex = parseInt(localStorage.getItem("activeTabIndex")) ?? 0; // Check the active tab index, and if not in the projects tab (tab 0), make sure the selected project is null
    if (activeTabIndex !== 0 && selectedProject !== null) {
      dispatch(setSelectedProject(null));
    }
    if (selectedProject) {
      dispatch(setAddCurrentWetlandFormProjectValues(selectedProject));
    }
    navigate(`/wetland/new`, { state: { actionType: formActionType.ADD, formId: null } });
  };

  const handleDeleteSelected = (event) => {
    event.preventDefault();
    dispatch(deleteWetlandForms(selectedFormIds));
  };

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <AssignFormsToProjectButton />
      </div>
      <div>
        {(region !== "All" && !selectedProject) && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={<AddIcon />}
            onClick={handleAddNewForm}
            sx={{ marginRight: "16px" }}
          >
            Add
          </Button>
        )}
        {(region === 'All' || selectedProject) && (
          <React.Fragment>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<AddIcon />}
              onClick={handleClick}
              sx={{ marginRight: "16px" }}
            >
              Add
            </Button>
          <Menu
            id="generate-pdfs-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'generate-pdfs-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'AGCP')} disableRipple>
              Atlantic and Gulf Coastal Plain (AGCP)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'AK')} disableRipple>
              Alaska (AK)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'AW')} disableRipple>
              Arid West (AW)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'CB')} disableRipple>
              Caribbean Islands
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'EMP')} disableRipple>
              Eastern Mountains and Piedmont (EMP)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'GP')} disableRipple>
              Great Plains (GP)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'HI')} disableRipple>
              Hawaii and the Pacific Islands (HI)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'MW')} disableRipple>
              Midwest (MW)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'NCNE')} disableRipple>
              Northcentral and Northeast (NCNE)
            </MenuItem>
            <MenuItem onClick={(e) => handleAddNewFormWithOverriddenRegion(e, 'WMVC')} disableRipple>
              Western Mountains, Valleys, and Coasts (WMVC)
            </MenuItem>
          </Menu>
          </React.Fragment>
        )}
        <DeleteFormsButton
          numberOfFormsToDelete={selectedFormIds.length}
          buttonDisabled={selectedFormIds.length === 0}
          onConfirm={handleDeleteSelected}
        />
      </div>
    </GridToolbarContainer>
  );
}
