import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import {
  Button,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import LinkIcon from "@mui/icons-material/Link";
import MapIcon from "@mui/icons-material/Map";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FolderIcon from '@mui/icons-material/Folder';
import UpdateIcon from '@mui/icons-material/Update';
import BioAppLogo from "../../resources/BioApp_Logo.svg";
import BioAppLogoWhite from "../../resources/BioApp_Logo_White.svg";
import { useIsAuthenticated } from "../../utils/hooks/useIsAuthenticated";
import axiosInstance, { logoutBlacklistTokenRelURL } from "../../api/auth";
import { resetAuthTokens, selectUser } from "../../api/services/user/userSlice";
import { useFetchUser } from "../../utils/hooks/useFetchUser";
import { resetFormsLoadingStatus } from "../../api/services/wetlandforms/wetlandFormsSlice";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

// Important - some navigation functions (e.g. navigating back from a specific project to the home page)  will control the active tab. If adding tabs or 
// changing their order, search navigation occurrences and instances where localStorage of activeTabIndex is changed.
export const defaultDrawerItems = [
  { name: "Projects", icon: <FolderIcon />, enabled: true },
  { name: "All Forms", icon: <ListAltIcon />, enabled: true },
  { name: "Map", icon: <MapIcon />, enabled: true },
  { name: "Batch Update", icon: <UploadFileIcon />, enabled: true },
  { name: "Account/Organization", icon: <GroupIcon />, enabled: true },
  // { name: "Downloads", icon: <DownloadIcon />, enabled: false },
  { name: "Settings", icon: <SettingsIcon />, enabled: true },
  { name: "Resources", icon: <LinkIcon />, enabled: true },
  { name: "Changelog", icon: <UpdateIcon />, enabled: true },
];

export default function Header({ showDrawer=true, drawerItems, customDrawerListComponent, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  // const pathname = useLocation().pathname;
  // const showAppBarButtons = pathname !== '/login';
  const isAuthenticated = useIsAuthenticated();
  const user = useSelector(selectUser);
  const isLoading = useFetchUser(user);
  // const organization = useSelector(selectOrganization);
  // const orgMembership = useSelector(selectOrgMembership);
  // const [orgInfoLoading, setOrgInfoLoading] = useState({ organization: true, orgMembership: true });

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  // TODO: check if this is needed to fetch org and membership
  // useEffect(() => {
  //   // If organization info is not in redux state, fetch it
  //   if (!organization) {
  //     dispatch(getUserOrganization()).then((res) => {
  //       if (res.payload?.status === 200) {
  //         dispatch(setOrganization(res.payload.data));
  //       }
  //       setOrgInfoLoading({ organization: false, ...orgInfoLoading });
  //     });
  //   }
  //   if (!orgMembership) {
  //     dispatch(getUserMembership()).then((res) => {
  //       if (res.payload?.status === 200) {
  //         dispatch(setOrgMembership(res.payload.data));
  //       }
  //       setOrgInfoLoading({ orgMembership: false, ...orgInfoLoading });
  //     });
  //   }
  // }, []);

  // Note: this useEffect was only used before abstracting into custom hook
  // useEffect(() => {
  //   dispatch(getUser()).then((res) => {
  //     dispatch(setUser(res.payload));
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }, [dispatch]);

  const handleClickHeaderName = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const refresh_token = localStorage.getItem("refresh_token");
    axiosInstance
      .post(logoutBlacklistTokenRelURL, {
        refresh_token: refresh_token,
      })
      .then((res) => {
        dispatch(resetAuthTokens());
        dispatch(resetFormsLoadingStatus());
        navigate("/login/");
      });
  };

  function LogoutButton(shouldRender = true) {
    if (shouldRender) {
      return (
        <Button variant="contained" color="error" onClick={handleLogout}>
          Logout
        </Button>
      );
    }
  }

  const drawer = (
    <React.Fragment>
      <Toolbar>
        <Box sx={{ width: 120 }}>
          <div
            onClick={handleClickHeaderName}
            style={{ width: "120px", borderRadius: "120px", overflow: "hidden", zIndex: 1 }}
          >
            <object data={BioAppLogo} type="image/svg+xml" style={{ zIndex: -1, pointerEvents: "none" }}>
              BioApp logo
            </object>
          </div>
        </Box>
      </Toolbar>
      <Divider />
      {customDrawerListComponent ?? <List component="nav" aria-label="main dashboard items" sx={{ width: 240 }}>
        {drawerItems ? drawerItems : defaultDrawerItems.map((item, index) => (
          <ListItemButton
            selected={props.selectedDrawerTab === index}
            key={item.name}
            onClick={() => props.onDrawerItemClick(index)}
            disabled={!item.enabled}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} secondary={!item.enabled ? "(Coming Soon)" : null} />
          </ListItemButton>
        ))}
      </List>}
    </React.Fragment>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={
          props.useFormEditPageLayout
            ? null
            : {
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
              }
        }
      >
        <Toolbar>
          {props.useHomePageLayout && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {props.showBackAndSaveButtons && (
            <Button
              aria-label="return to home page without saving"
              variant="contained"
              color="warning"
              edge="start"
              onClick={props.onBackClick}
              sx={{ mr: 2 }}
            >
              <ArrowBack />
              <Typography ml={1}>Back</Typography>
            </Button>
          )}
          {props.showBackAndSaveButtons && (
            <Button
              aria-label="save data form"
              variant="contained"
              color="info"
              edge="start"
              disabled={!props.hasSavePermission}
              onClick={props.onSaveClick}
              sx={{ mr: 2 }}
            >
              <SaveIcon />
              <Typography ml={1}>Save</Typography>
            </Button>
          )}
          {!props.showBackAndSaveButtons && !mdBreakpoint && (
            <Box sx={{ width: 120 }}>
              <div
                onClick={handleClickHeaderName}
                style={{ width: "120px", borderRadius: "120px", overflow: "hidden", zIndex: 1 }}
              >
                <object data={BioAppLogoWhite} type="image/svg+xml" style={{ zIndex: -1, pointerEvents: "none" }}>
                  BioApp logo
                </object>
              </div>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}></Box>
          {LogoutButton(isAuthenticated)}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={props.useFormEditPageLayout ? null : { width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="menu tabs"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        {/* Don't display the permanent drawer if in the "Edit Form" page */}
        {showDrawer && (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>
      <Box component="main" sx={{ flexGrow: 1, px: 0, py: 2, width: { md: `calc(100% - ${drawerWidth}px)` } }}>
        {props.children}
      </Box>
    </Box>
  );
}

Header.propTypes = {
  useHomePageLayout: PropTypes.bool,
  selectedDrawerTab: PropTypes.number,
  onDrawerItemClick: PropTypes.func,
  useFormEditPageLayout: PropTypes.bool,
  showBackAndSaveButtons: PropTypes.bool,
  hasSavePermission: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSaveClick: PropTypes.func,
};
