export const hydroIndicators = {
  'Unspecified': [],
  'AGCP': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'B15', name: 'Marl deposits', category: 'Primary', applicableLRRs: ['U']},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'B16', name: 'Moss Trim Lines', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season water table', category: 'Secondary'},
    {symbol: 'C8', name: 'Crayfish burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
    {symbol: 'D8', name: 'Sphagnum moss', category: 'Secondary', applicableLRRs: ['T', 'U']},
  ],
  'AK': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B6', name: ' Surface soil cracks', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Primary'},
    {symbol: 'B15', name: 'Marl deposits', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C2', name: 'Dry-season water table', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage patterns', category: 'Secondary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Secondary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Secondary'},
    {symbol: 'C5', name: 'Salt deposits', category: 'Secondary'},
    {symbol: 'D1', name: 'Stunted or stressed plants', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D4', name: 'Microtopographic relief', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'AW': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary', note: 'Nonriverine'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary', note: 'Nonriverine'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary', note: 'Nonriverine'},
    {symbol: 'B6', name: 'Surface soil cracks', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B11', name: 'Salt crust', category: 'Primary'},
    {symbol: 'B12', name: 'Biotic crust', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic invertebrates', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Secondary', note: 'Riverine'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Secondary', note: 'Riverine'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Secondary', note: 'Riverine'},
    {symbol: 'B10', name: 'Drainage patterns', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-season water table', category: 'Secondary'},
    {symbol: 'C8', name: 'Crayfish burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'CB': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'C10', name: 'Fiddler crab burrows', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season water table', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'EMP': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'B14', name: 'True aquatic plants', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'B16', name: 'Moss trim lines', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season water table', category: 'Secondary'},
    {symbol: 'C8', name: 'Crayfish burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D1', name: 'Stunted or stressed plants', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D4', name: 'Microtopographic relief', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'GP': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B11', name: 'Salt crust', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C2', name: 'Dry-season water table', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary', note: 'where not tilled'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Secondary', note: 'where tilled'},
    {symbol: 'C8', name: 'Crayfish burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
    {symbol: 'D7', name: 'Frost-heave hummocks', category: 'Secondary', applicableLRRs: ['F']},
  ],
  'HI': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'B17', name: 'Tilapia nests', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'C10', name: 'Fiddler crab burrows', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season water table', category: 'Secondary'},
    {symbol: 'C5', name: 'Salt deposits', category: 'Secondary'},
    {symbol: 'D1', name: 'Stunted or stressed plants', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow aquitard', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'NCNE': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'B15', name: 'Marl deposits', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Primary'},
    {symbol: 'B6', name: 'Surface Soil Cracks', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'B16', name: 'Moss Trim Lines', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season Water Table', category: 'Secondary'},
    {symbol: 'C8', name: 'Crayfish Burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation Visible on Aerial Imagery', category: 'Secondary'},
    {symbol: 'D1', name: 'Stunted or Stressed Plants', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic Position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow Aquitard', category: 'Secondary'},
    {symbol: 'D4', name: 'Microtopographic Relief', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-Neutral Test', category: 'Secondary'},
  ],
  'MW': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'B14', name: 'True aquatic plants', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'C7', name: 'Thin muck surface', category: 'Primary'},
    {symbol: 'D9', name: ' Gauge or well data', category: 'Primary'},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Primary'},
    {symbol: 'B6', name: 'Surface soil cracks', category: 'Secondary'},
    {symbol: 'B10', name: 'Drainage patterns', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season water table', category: 'Secondary'},
    {symbol: 'C8', name: 'Crayfish burrows', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation visible on aerial imagery', category: 'Secondary'},
    {symbol: 'D1', name: 'Stunted or stressed plants', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic position', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-neutral test', category: 'Secondary'},
  ],
  'WMVC': [
    {symbol: 'A1', name: 'Surface water', category: 'Primary'},
    {symbol: 'A2', name: 'High water table', category: 'Primary'},
    {symbol: 'A3', name: 'Saturation', category: 'Primary'},
    {symbol: 'B1', name: 'Water marks', category: 'Primary'},
    {symbol: 'B2', name: 'Sediment deposits', category: 'Primary'},
    {symbol: 'B3', name: 'Drift deposits', category: 'Primary'},
    {symbol: 'B4', name: 'Algal mat or crust', category: 'Primary'},
    {symbol: 'B5', name: 'Iron deposits', category: 'Primary'},
    {symbol: 'B6', name: 'Surface soil cracks', category: 'Primary'},
    {symbol: 'B7', name: 'Inundation visible on aerial imagery', category: 'Primary'},
    {symbol: 'B8', name: 'Sparsely vegetated concave surface', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves', category: 'Primary', excludedMLRAs: ['1', '2', '4A', '4B']},
    {symbol: 'B11', name: 'Salt crust', category: 'Primary'},
    {symbol: 'B13', name: 'Aquatic fauna', category: 'Primary'},
    {symbol: 'C1', name: 'Hydrogen sulfide odor', category: 'Primary'},
    {symbol: 'C3', name: 'Oxidized rhizospheres along living roots', category: 'Primary'},
    {symbol: 'C4', name: 'Presence of reduced iron', category: 'Primary'},
    {symbol: 'C6', name: 'Recent iron reduction in tilled soils', category: 'Primary'},
    {symbol: 'D1', name: 'Stunted or stressed plants', category: 'Primary', applicableLRRs: ['A']},
    {symbol: 'Other', name: 'Other (Explain in Remarks)', category: 'Primary'},
    {symbol: 'B9', name: 'Water-stained leaves (MLRA 1, 2, 4A, and 4B)', category: 'Secondary', excludedMLRAs: ['1', '2', '4A', '4B']},
    {symbol: 'B10', name: 'Drainage Patterns', category: 'Secondary'},
    {symbol: 'C2', name: 'Dry-Season Water Table', category: 'Secondary'},
    {symbol: 'C9', name: 'Saturation Visible on Aerial Imagery', category: 'Secondary'},
    {symbol: 'D2', name: 'Geomorphic Position', category: 'Secondary'},
    {symbol: 'D3', name: 'Shallow Aquitard', category: 'Secondary'},
    {symbol: 'D5', name: 'FAC-Neutral Test', category: 'Secondary'},
    {symbol: 'D6', name: 'Raised ant mounds (LRR A)', category: 'Secondary', applicableLRRs: ['A']},
    {symbol: 'D7', name: 'Frost-heave hummocks', category: 'Secondary'},
  ],
}