import React from 'react';
import {
  CssBaseline,
  Container,
  Typography,
} from "@mui/material";
import { colorError } from '../../utils/colors';

export default function PasswordResetError() {
  return (
    <Container component="main">
      <CssBaseline />
      <Container maxWidth="xs" sx={{ p: 4 }}>
        <Typography color={colorError}>
          Error resetting password. Contact help@bioapp.us if problem persists.
        </Typography>
      </Container>
    </Container>
  )
}
