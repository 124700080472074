import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Snackbar,
  Alert,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  getUserSubscriptionEvents,
  selectPurchaseEvents,
  setPurchaseEvents,
} from "../../../api/services/user/userSlice";
import SubscriptionReceiptList from "./SubscriptionReceiptList";
import { generateReceiptPdf } from "../../../api/services/filedownloads/fileDownloadsSlice";

export default function SubscriptionReceipts() {
  const dispatch = useDispatch();
  const purchaseEvents = useSelector(selectPurchaseEvents);

  const [open, setOpen] = useState(false);
  const [purchaseEventsLoading, setPurchaseEventsLoading] = useState(true);

  useEffect(() => {
    // Only trigger once, the first time the dropdown is opened
    if (open && !purchaseEvents) {
      dispatch(getUserSubscriptionEvents()).then((res) => {
        setPurchaseEventsLoading(false);
        if (res.payload.status === 200) {
          dispatch(setPurchaseEvents(res.payload.data));
        } else if (res.payload.status !== 204) {
          // 204 no content response is not an error, but no organization membership data to display
          handleOpenSnackbar("error", `Error loading subscription purchases`);
        }
      });
    }
  }, [dispatch, open, purchaseEvents]);

  const [snackbarInfo, updateSnackbarInfo] = useState({
    showSnackbar: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    updateSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    updateSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  const handleGenerateReceiptPdf = (event, purchaseEvent) => {
    event.preventDefault();
    // If the user is a regular user, promote them. Otherwise, demote them to regular user. This option is only available to org managers.
    dispatch(generateReceiptPdf({ purchaseEvent })).then((res) => {
      if (res.payload?.status !== 200) {
        handleOpenSnackbar("error", `Error generating receipt PDF. Contact help@bioapp.us to obtain your receipt.`);
      }
    });
  };

  return (
    <Fragment>
      <Snackbar open={snackbarInfo.showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarInfo.severity} variant="filled" sx={{ width: "100%" }}>
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      {/* <Box mx={4} mt={2} mb={0}>
        <Card sx={{ p: 2 }}>
          <CardHeader
            title="Purchase Receipts"
            titleTypographyProps={{
              variant: "h6",
            }}
            action={
              <IconButton onClick={() => setOpen(!open)} aria-label="expand" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
          ></CardHeader>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubscriptionReceiptList purchaseEvents={purchaseEvents} loading={purchaseEventsLoading} handleGenerateReceiptPdf={handleGenerateReceiptPdf} />
          </Collapse>
        </Card>
      </Box> */}
      <Stack direction="row" spacing={2} mt={4} mb={2}>
        <Typography variant="h6">
          App Store Purchase Receipts
        </Typography>
        <Button 
          variant="text" 
          color="secondary" 
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={() => setOpen(!open)} aria-label="expand" 
          size="medium"
          >
            {open ? 'Hide' : 'Show'}
         </Button>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SubscriptionReceiptList purchaseEvents={purchaseEvents} loading={purchaseEventsLoading} handleGenerateReceiptPdf={handleGenerateReceiptPdf} />
      </Collapse>
    </Fragment>
  );
}
