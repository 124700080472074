import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export function useRedirectIfAuthenticated(navigateTo='/') {
  const navigate = useNavigate();
  useEffect(() => {
    // On initial login page load, if credentials are already stored, and refresh token is still valid, redirect to home page.
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
      const now = Math.ceil(Date.now() / 1000);  // Divide by 1000 because refresh token is in milliseconds
      if (tokenParts.exp > now) {
        navigate(navigateTo);
      }
    }
  }, [navigateTo, navigate]);
}