export const mapColorsNwiClasses = {
  'M': {},
  'E': {},
  'R': {
    '1': '#3ab6c7',
    '2': '#3086db',
    '3': '#2362a1',
    '4': '#6dc1e8',
    'default': '#70899e',
  },
  'L': {},
  'P': {
    'FO': '#275c2b',
    'SS': '#759920',
    'EM': '#1dbf29',
    'default': '#76a655',
  },
};