import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import * as serviceWorker from "./serviceWorker";
import 'core-js/es/array';
import 'core-js/es/object';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { theme } from "./utils/theme";
import SignIn from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import WetlandForm from "./pages/WetlandForm/WetlandForm";
import { formActionType } from "./utils/constants";
import CookiesBanner from "./features/cookiesBanner/CookiesBanner";
import ErrorFallback from "./pages/ErrorFallback/ErrorFallback";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import PasswordResetError from "./pages/PasswordReset/PasswordResetError";
import PasswordResetSuccess from "./pages/PasswordReset/PasswordResetSuccess";
import ProjectPage from "./pages/Project/ProjectPage";
import MessageSnackbars from "./features/messageSnackbars/MessageSnackbars";

if (window.location.origin !== "http://localhost:3000") {
  Sentry.init({
    dsn: "https://d19a7f7a126f4c06ab11438da7e429d4@o920946.ingest.sentry.io/6061684",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
          <Provider store={store}>
            <MessageSnackbars />
            <CookiesBanner />
            <Routes>
              <Route
                path="password_reset/confirm/:resetUid/:resetToken"
                element={
                  <PasswordReset />
                }
              />
              <Route
                path="password_reset/success"
                element={
                  <PasswordResetSuccess />
                }
              />
              <Route
                path="password_reset/error"
                element={
                  <PasswordResetError />
                }
              />
              <Route
                path="wetland/:wetlandSampleName"
                element={
                  <PrivateRoute>
                    <WetlandForm actionType={formActionType.EDIT} />
                  </PrivateRoute>
                }
              />
              <Route
                path="wetland/new"
                element={
                  <PrivateRoute>
                    <WetlandForm actionType={formActionType.ADD} />
                  </PrivateRoute>
                }
              />
              <Route
                path="projects/:projectSlugAndUUID"
                element={
                  <PrivateRoute>
                    <ProjectPage />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route exact path="/login" element={<SignIn />} />
              {/* <Route
                exact
                path="/user-details"
                element={
                  <PrivateRoute>
                    <UserDetails />
                  </PrivateRoute>
                }
              /> */}
            </Routes>
            {/* <Footer /> */}
          </Provider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
