import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  DialogContentText,
  Grid,
  ListItemAvatar,
  Avatar,
  Tooltip,
  ListItemButton,
  IconButton,
} from "@mui/material";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { format } from "date-fns";
import {
  attemptPromoteOrDemoteUser,
  getUserMembership,
  getUserOrganization,
  listOrganizationMembers,
  respondToOrganizationInvite,
  selectAccessibleOrgMembers,
  selectOrganization,
  selectOrgMembership,
  selectUser,
  setAccessibleOrgMembers,
  setOrganization,
  setOrgMembership,
  userLeaveOrganization,
} from "../../api/services/user/userSlice";
import { colorError, colorGreenStandard, colorGreyLight } from "../../utils/colors";
import InviteDialog from "./components/InviteDialog";
import JoinOrganizationDialog from "./components/JoinOrganizationDialog";
import LeaveOrganizationDialog from "./components/LeaveOrganizationDialog";
import CreateOrganizationDialog from "./components/CreateOrganizationDialog";
import InfoDialogButton from "../infoDialogButton/InfoDialogButton";
import OrganizationMembersSection from "./components/OrganizationMembersSection";

const userRoleForDisplay = {
  regular: "Standard User",
  orgManager: "Organization Manager",
};

export default function OrganizationInfo() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const orgMembership = useSelector(selectOrgMembership);
  const accessibleOrgMembers = useSelector(selectAccessibleOrgMembers);

  const [snackbarInfo, updateSnackbarInfo] = useState({
    showSnackbar: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    updateSnackbarInfo({
      showSnackbar: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    updateSnackbarInfo({
      ...snackbarInfo,
      showSnackbar: false,
    });
  };

  useEffect(() => {
    dispatch(getUserMembership()).then((res) => {
      // Note: other HTTP requests are done after receiving data successfully from the first endpoint. This is done so that multiple token refreshes aren't triggered at the same time.
      if (res.payload.status === 200) {
        dispatch(setOrgMembership(res.payload.data));
      } else if (res.payload.status !== 204) {
        // 204 no content response is not an error, but no organization membership data to display
        handleOpenSnackbar("error", `Error loading organization membership data`);
      }
    });
    dispatch(listOrganizationMembers()).then((res) => {
      if (res.payload?.status === 200) {
        dispatch(setAccessibleOrgMembers(res.payload.data));
      } else {
        handleOpenSnackbar("error", `Error loading organization members`);
      }
    });
    dispatch(getUserOrganization()).then((res) => {
      if (res.payload.status === 204) {
        // Response code if no organization associated with the user
        dispatch(setOrganization(null));
      } else if (res.payload.status === 200) {
        dispatch(setOrganization(res.payload.data));
      } else {
        handleOpenSnackbar("error", `Error loading user organization`);
      }
    });
  }, [dispatch]);

  const handleAttemptRemoveFromOrganization = (event, email) => {
    event.preventDefault();
    // If the user is attempting to remove themself from the organization, show confirm popup and ensure they want to leave, and know that this will delete their org if they are the last remaining org manager.

    dispatch(userLeaveOrganization(email)).then((res) => {
      // If the request was successful, update the list of accessible users, as well as the organization info itself.
      if (res.payload?.status === 200) {
        dispatch(setAccessibleOrgMembers(res.payload.data));
        handleOpenSnackbar(
          "success",
          `Removed user with email address ${email} from the organization. Refresh the page to see changes reflected in the user list.`
        );
        dispatch(setOrganization(null));
      } else {
        handleOpenSnackbar("error", `Error removing user from organization`);
      }
    });
  };

  const handleRespondToInvite = (event, userResponse) => {
    event.preventDefault();
    dispatch(respondToOrganizationInvite(userResponse)).then((inviteRes) => {
      if (inviteRes.payload.status === 204) {
        // 204 is used for both accepting and rejecting invites. Use the input param to determine the snackbar to display
        if (userResponse.response === "approved") {
          handleOpenSnackbar("success", `Accepted organization invite`);
        } else {
          handleOpenSnackbar("error", `Declined organization invite`);
        }
      } else {
        handleOpenSnackbar("error", `Error processing oorganization invite response`);
      }
      // No matter the result, re-fetch the user organization info and users list, as well as membership status.
      dispatch(getUserOrganization()).then((res) => {
        // console.log(res.payload);
        if (res.payload.status === 204) {
          // Response code if no organization associated with the user
          dispatch(setOrganization(null));
        } else if (res.payload.status === 200) {
          dispatch(setOrganization(res.payload.data));
        } else {
          handleOpenSnackbar("error", `Error loading user organization`);
        }
      });
      dispatch(listOrganizationMembers()).then((res) => {
        // console.log(res.payload);
        if (res.payload?.status === 200) {
          dispatch(setAccessibleOrgMembers(res.payload.data));
        } else {
          handleOpenSnackbar("error", `Error loading organization members`);
        }
      });
      dispatch(getUserMembership()).then((res) => {
        if (res.payload.status === 200) {
          dispatch(setOrgMembership(res.payload.data));
        } else if (res.payload.status !== 204) {
          // 204 no content response is not an error, but no organization membership data to display
          handleOpenSnackbar("error", `Error loading organization membership data`);
        }
      });
    });
  };

  function OrganizationContent() {
    // If the user is an organization manager, get and show the organization members and invite statuses.
    // If the user is a regular user, just show the organization info.
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography>Name:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{organization?.name || ""}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Unique Organization Identifier:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{organization?.id || ""}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Created by:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{organization?.created_by_email || ""}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Role in Organization:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{userRoleForDisplay[orgMembership?.user_role] || ""}</Typography>
        </Grid>
      </Grid>
    );
  }

  function InvitedToOrganizationContent() {
    return (
      <Box display="inline-block" sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Card elevation={6} sx={{ p: 2 }}>
          <Stack spacing={2} alignItems="center">
            <Typography>Invited to Organization: {orgMembership?.organization_name}</Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              <Button
                variant="contained"
                onClick={(e) => handleRespondToInvite(e, { response: "approved", email: user.email })}
              >
                Accept
              </Button>
              <Typography color={colorGreyLight} display="inline">
                or
              </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => handleRespondToInvite(e, { response: "rejected", email: user.email })}
              >
                Decline
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Box>
    );
  }

  function RequestedToJoinOrganizationContent() {
    return (
      <Stack spacing={2} alignItems="center">
        <Typography color={colorGreyLight}>
          Requested to join Organization: {orgMembership?.organization_name}
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => handleRespondToInvite(e, { response: "rejected", email: user.email })}
        >
          Rescind Join Request
        </Button>
      </Stack>
    );
  }

  function RequestToJoinDeniedOrganizationContent() {
    return (
      <Stack spacing={2} alignItems="center">
        <Typography color={colorError}>
          Requested to join Organization {orgMembership?.organization_name} was denied.
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => handleAttemptRemoveFromOrganization(e, user?.email)}
        >
          Rescind Join Request
        </Button>
      </Stack>
    );
  }

  function NoOrganizationButtonRow() {
    return (
      <Stack spacing={1} mt={4} mb={2} alignItems="center">
        <Typography color={colorGreyLight}>No Organization</Typography>
        <Grid container direction="row" spacing={2} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12} md={4} textAlign="center">
            <JoinOrganizationDialog />
          </Grid>
          <Grid item xs={12} md={1} textAlign="center">
            <Typography color={colorGreyLight}>or</Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <CreateOrganizationDialog showSnackbar={handleOpenSnackbar} />
          </Grid>
        </Grid>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Snackbar open={snackbarInfo.showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarInfo.severity} variant="filled" sx={{ width: "100%" }}>
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={4} p={4}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card sx={{ p: 2, minHeight: 240 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h6">Organization</Typography>
              <InfoDialogButton
                title="What is an Organization?"
                content={
                  <Fragment>
                    <DialogContentText>
                      Organizations are a method for sharing data with others. Your account may only be associated with
                      one organization at a time. When you are associated with an organization, your role can be that of a
                      regular user (still only viewing and managing your own forms and data), or an organization manager
                      (able to view, edit, and manage all data from all users that are part of that organization).
                    </DialogContentText>
                    <DialogContentText>
                      Any user can be promoted to the role of Organization Manager, or demoted to the role of Regular
                      User. This can only be done by Organization Managers. Any user may leave an organization at any
                      time.
                    </DialogContentText>
                  </Fragment>
                }
              />
            </Stack>

            {organization && orgMembership?.invite_status === "approved" && OrganizationContent()}
            {!organization && orgMembership?.invite_status === "invited" && InvitedToOrganizationContent()}
            {orgMembership?.invite_status === "requestedJoin" && RequestedToJoinOrganizationContent()}
            {orgMembership?.invite_status === "rejected" && RequestToJoinDeniedOrganizationContent()}
            {!organization && (!orgMembership || orgMembership?.invite_status === "none") && NoOrganizationButtonRow()}
            {organization && orgMembership?.invite_status === "approved" && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                <LeaveOrganizationDialog email={user?.email} showSnackbar={handleOpenSnackbar} />
              </Box>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {<OrganizationMembersSection inviteStatus={orgMembership?.invite_status} userRole={orgMembership?.user_role} />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
