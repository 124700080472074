import React from "react";
import { Card, Stack, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectBasemapUrl, setBasemapUrl } from "../../api/services/spatial/spatialSlice";
import DataFormSettings from "./components/DataFormSettings";

export default function Settings() {
  const dispatch = useDispatch();
  const basemapUrl = useSelector(selectBasemapUrl);

  const handleChangeBasemapUrl = (event, newUrl) => {
    dispatch(setBasemapUrl(newUrl));
  };

  return (
    <Stack>
      <Card sx={{ margin: 4 }}>
        <Stack spacing={2} p={2}>
          {/* <Typography component="h2" variant="h4">
            Data Download Settings
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="settingsAppendUuidToPdfFileDownloads"
                  checked={appendUuidToPdfFileDownloads}
                  onChange={handleToggleAppendUuidToPdfFileDownloads}
                />
              }
              label="Append unique wetland ID to each downloaded data form PDF?"
            />
          </FormGroup> */}
          <DataFormSettings />
          <Typography component="h2" variant="h4">
            Map Settings
          </Typography>
          <ToggleButtonGroup
            value={basemapUrl}
            exclusive
            onChange={handleChangeBasemapUrl}
            color="info"
            aria-label="change basemap URL"
          >
            <ToggleButton
              value="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              aria-label="esri world imagery"
            >
              Esri World Imagery
            </ToggleButton>
            <ToggleButton value="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" aria-label="open street map">
              OpenStreetMap
            </ToggleButton>
            <ToggleButton value="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" aria-label="open topo map">
              OpenTopoMap
            </ToggleButton>
          </ToggleButtonGroup>
          {/* Cenrter on: my location, or custom coordinates, or bounding box of forms with a given project name */}
        </Stack>
      </Card>
    </Stack>
  );
}
