import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, MenuItem, Select, InputLabel, Stack, Switch, TextField, Typography } from "@mui/material";
import { colorGreyMidtone } from "../../../../utils/colors";

export default function TopographySectionPicker(props) {
  // By default, use a dropdown component, however let the user switch to a free-form text field
  const [useTextField, setUseTextField] = useState(false);

  const handleToggleUseTextField = (event) => {
    setUseTextField(event.target.checked);
  };

  return (
    <Stack>
      <FormControlLabel sx={{ pb: 2, pl: 2 }} control={<Switch size="small" checked={useTextField} onChange={handleToggleUseTextField} inputProps={{ 'aria-label': 'switch between dropdown and text field' }} />} label={<Typography color={colorGreyMidtone}>Use custom input</Typography>} />
      {useTextField ? (
        <TextField id={props.id} name={props.id} label={props.label} fullWidth inputProps={{ maxLength: 200 }} value={props.value} onChange={props.onTextFieldChange} />
      ) : (
        <FormControl fullWidth>
          <InputLabel id={`${props.id}-select-label`}>{props.label}</InputLabel>
          <Select
            labelId={`${props.id}-select-label`}
            id={props.id}
            name={props.id}
            value={props.value}
            label={props.label}
            onChange={props.onChange}
          >

            {Array.isArray(props.selectChoices) ? props.selectChoices.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )) : Object.keys(props.selectChoices).map((item) => (
              <MenuItem key={item} value={item}>
                {props.selectChoices[item]}
              </MenuItem>
            ))}
            {Array.isArray(props.selectChoices) ? !props.selectChoices.includes(props.value) && (
              <MenuItem key={`landform-${props.value}`} value={props.value}>
                {props.value}
              </MenuItem>
            ) : !Object.keys(props.selectChoices).includes(props.value) && (
              <MenuItem key={`landform-${props.value}`} value={props.value}>
                {props.value}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    </Stack>
  );
}

TopographySectionPicker.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onTextFieldChange: PropTypes.func,
  selectChoices: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};
