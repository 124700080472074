import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  Stack,
  Chip,
} from "@mui/material";
import { listOrganizationMembers } from "../../api/services/user/userSlice";
import { addErrorMessage } from "../../api/services/messages/messagesSlice";
import { userAssignmentPermissionOptions } from './userAssignmentPermissionOptions';
import ProjectAssignmentEditChips from './ProjectAssignmentEditChips';

export default function ProjectUserAssignments({ projectData, editingMode=false, userAssignments, setUserAssignments, ...props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listOrganizationMembers()).then((res) => {
      if (res.payload?.status === 200) {
        // Simplify the complex array of objects down into an array just containing the user email, name, and if they are in the project or not
        let membersMapped = [];
        res.payload.data.forEach((user) => {
          let userContent = {
            email: user.email,
            full_name: user.full_name,
            currently_in_project: false,
            current_permission_level: undefined,
            updated_in_project: false,
            updated_permission_level: undefined,
          };
          // The user's "assignment_users" property is the list of their project assignments
          // For each of their project assignments, check that the project id matches
          if (user.assignment_users.filter(projAssignment => projAssignment.project === projectData.id).map(projAssignment => projAssignment.user.email).includes(user.email)) {
            userContent.currently_in_project = true;
            userContent.updated_in_project = true;
            userContent.current_permission_level = user.assignment_users.find(projAssignment => projAssignment.user.email === user.email && projAssignment.project === projectData.id).permission_level;
            userContent.updated_permission_level = user.assignment_users.find(projAssignment => projAssignment.user.email === user.email && projAssignment.project === projectData.id).permission_level;
          }
          membersMapped.push(userContent);
        });
        setUserAssignments(membersMapped);
      } else {
        dispatch(addErrorMessage(`Error loading organization members: ${res.payload?.status}`));
      }
    });
  }, [dispatch, setUserAssignments]);

  const handleToggleUserInProject = (event, userToChange) => {
    event.preventDefault();
    const modifiedUserAssignments = userAssignments.map(userAssignment => {
      if (userAssignment.email === userToChange.email) {
        userAssignment.updated_in_project = !userAssignment.updated_in_project;
        // If adding a user to the project, set their default permission level (read all, write own)
        if (userAssignment.updated_in_project) {
          userAssignment.updated_permission_level = 'ReadAllWriteOwn';
        }
      }
      // If removing a user from the project, be sure to reset their permission level as well
      if (!userAssignment.updated_in_project) {
        userAssignment.updated_permission_level = userAssignment.current_permission_level;
      }
      return userAssignment;
    });
    setUserAssignments(modifiedUserAssignments);
  };


  const permissionOptionDisplayNameFromValue = (value) => {
    // Given the raw value from the model object, return the string used for display purposes for the permission level
    const matchingOption = userAssignmentPermissionOptions.find(option => option.value === value);
    if (matchingOption) return matchingOption.displayName;
    return 'No permission';
  };

  return (
    <Fragment>
      {!editingMode ? <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: editingMode ? 0 : 1 }}>
          <Typography variant="body2">{editingMode ? `Assigned User(s) (tap to change status or unassign): ` : `Assigned User(s): `}</Typography>
          {projectData.assignment_projects.length === 0 && (
            <Chip
              label="None"
              title="No surveyors"
              size="small"
              variant="outlined"
              style={{ color: "#9E9790" }}
            />
          )}
          {projectData.assignment_projects
            .filter((projectAssignment) => projectAssignment.permission_level === 'ProjectEditor')
            .map((projectAssignment, index) => (
              <Chip
                key={`project-leads-${index}`}
                title="Assigned to project as a Project Editor"
                color="secondary"
                label={<Stack>
                  <Typography variant="body2">{projectAssignment.user.full_name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>{permissionOptionDisplayNameFromValue(projectAssignment.permission_level)}</Typography>
                </Stack>}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          {projectData.assignment_projects
            .filter((projectAssignment) => projectAssignment.permission_level !== 'ProjectEditor')
            .map((projectAssignment, index) => (
              <Chip
                key={`project-standard-users-${index}`}
                title="Assigned to project as a Standard User"
                label={<Stack>
                  <Typography variant="body2">{projectAssignment.user.full_name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>{permissionOptionDisplayNameFromValue(projectAssignment.permission_level)}</Typography>
                </Stack>}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
        </Stack>
      </Grid> : <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: 0 }}>
        <Typography variant="body2">{`Assigned User(s) (tap to change status or unassign): `}</Typography>
          {userAssignments.every((userAssignment) => userAssignment.updated_in_project === false) && (
            <Typography variant="body2" color="text.secondary">
              None
            </Typography>
          )}
          <ProjectAssignmentEditChips userAssignments={userAssignments} setUserAssignments={setUserAssignments} />
        </Stack>
      </Grid>}
      {editingMode && <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: 2 }}>
          <Typography variant="body2">{`Unassigned Organization User(s) (tap to assign): `}</Typography>
          {userAssignments.filter((orgUser) => !orgUser.updated_in_project).length === 0 && (
            <Typography variant="body2" color="text.secondary">
              None
            </Typography>
          )}
          {userAssignments
            .filter((orgUser) => !orgUser.updated_in_project)
            .map((orgUser, index) => (
            <Chip
                key={`unassigned-users-${index}`}
                label={orgUser.full_name}
                title="Not assigned to project"
                size="small"
                onClick={(e) => handleToggleUserInProject(e, orgUser)}
              />
          ))}
        </Stack>
      </Grid>}
    </Fragment>
  )
}
