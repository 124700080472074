import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Grid, TextField, InputAdornment, FormGroup, FormControlLabel, Switch, Box, Stack, Slide, useMediaQuery } from "@mui/material";
import FormSectionHeader from "../../../components/FormSectionHeader";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandForm,
  selectCurrentWetlandFormRegion,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import IndicatorList from "../../../features/indicatorList/IndicatorList";
import ActiveIndicatorCard from "../../../features/indicatorList/ActiveIndicatorCard";
import { hydroIndDescrips } from "../../../resources/hydroIndDescrips";
import { soilIndDescrips } from "../../../resources/soilIndDescrips";

function getIndicatorDescription({ indicatorType = "Hydrology", aceRegion = "NCNE", indicator = "A1" }) {
  if (indicatorType === "Hydrology") {
    return hydroIndDescrips[aceRegion][indicator];
  } else {
    return soilIndDescrips[aceRegion][indicator];
  }
}

export default function HydrologyTab() {
  const theme = useTheme();
  const lessThanMdBreakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const currentForm = useSelector(selectCurrentWetlandForm);
  const dispatch = useDispatch();
  const currentFormRegion = useSelector(selectCurrentWetlandFormRegion);
  const [activeIndicator, setActiveIndicator] = React.useState(null);
  const [showActiveIndicator, setSowActiveIndicator] = React.useState(false);

  const setActiveIndicatorAndShow = (indicator) => {
    // Need to append the indicator description information to the indicator object, which by default only contains the symbol, name, and category
    const indicatorDescription = getIndicatorDescription({
      indicatorType: "Hydrology",
      aceRegion: currentFormRegion,
      indicator: indicator.symbol,
    });
    setActiveIndicator({
      ...indicator,
      ...indicatorDescription,
    });
    setSowActiveIndicator(true);
  };

  const handleChangeSurfaceWater = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
    // TODO: if water table present, check primary indicator? Same with HWT/Saturation
  };

  const handleChangeWaterTable = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  const handleChangeSaturation = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.checked }));
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box>
          <Grid container spacing={2} columnSpacing={4}>
            <Grid container item spacing={4}>
              <FormSectionHeader text={"Field Observations"} />
            </Grid>
            <Grid container item spacing={4}>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  id="surfaceWaterDepth"
                  label="Surface Water Depth"
                  fullWidth
                  value={currentForm?.surfaceWaterDepth ?? ""}
                  onChange={handleChangeSurfaceWater}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  id="waterTableDepth"
                  label="Water Table Depth"
                  fullWidth
                  value={currentForm?.waterTableDepth ?? ""}
                  onChange={handleChangeWaterTable}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  id="saturationDepth"
                  label="Saturation Depth"
                  fullWidth
                  value={currentForm?.saturationDepth ?? ""}
                  onChange={handleChangeSaturation}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <IndicatorList 
            indicatorType="Hydrology" 
            indicatorCategory="Primary" 
            handleShow={setActiveIndicatorAndShow}
            showAsModalDialog={lessThanMdBreakpoint}
          />
          <IndicatorList
            indicatorType="Hydrology"
            indicatorCategory="Secondary"
            handleShow={setActiveIndicatorAndShow}
            showAsModalDialog={lessThanMdBreakpoint}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="wetlandHydro"
                  checked={currentForm?.wetlandHydro}
                  onChange={handleSwitchInputFormValueChange}
                />
              }
              label="Wetland Hydrology"
              sx={{ mt: 4 }}
            />
          </FormGroup>
          <TextField
            id="recordedData"
            label="Reccorded Data"
            fullWidth
            multiline
            rows={4}
            value={currentForm?.recordedData ?? ""}
            onChange={handleStandardFormValueChange}
            sx={{ mt: 4 }}
          />
          <TextField
            id="hydroComment"
            label="Hydrology Comment"
            fullWidth
            multiline
            rows={4}
            value={currentForm?.hydroComment ?? ""}
            onChange={handleStandardFormValueChange}
            sx={{ mt: 4 }}
          />
        </Box>
        <Box ml={4} sx={{ position: "sticky", zIndex: 1 }}>
          <Slide direction="left" in={showActiveIndicator} mountOnEnter unmountOnExit>
            <Box>
              <ActiveIndicatorCard activeIndicator={activeIndicator} onClose={() => setSowActiveIndicator(false)} />
            </Box>
          </Slide>
          <Slide direction="left" in={showActiveIndicator} mountOnEnter unmountOnExit>
            <Box sx={{ minWidth: 400 }} />
          </Slide>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
