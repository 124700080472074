import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import ProjectCard from "./ProjectCard";
import { getProjects, selectProjects, selectProjectsFilterText, selectProjectsLoadingStatus } from "../../api/services/projects/projectsSlice";
import { requestStatuses } from "../../utils/constants";

export default function ProjectsGrid() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectsLoadingStatus = useSelector(selectProjectsLoadingStatus);
  const projects = useSelector(selectProjects);
  const projectsFilterText = useSelector(selectProjectsFilterText);

  useEffect(() => {
    if (projectsLoadingStatus === requestStatuses.IDLE) {
      dispatch(getProjects());
    }
  }, [projectsLoadingStatus, dispatch]);

  // TODO: set up and determine if navigating to a different url is necessary
  // const handleNavigateToProjectPage = (item) => {
  //   dispatch(setWetlandFormActionType(formActionType.EDIT));
  //   // The actual GET request is done in the form page itself, so that when navigating directly to that URL data is properly loaded.
  //   // Because of this, form ID is passed to the form page component using router state and used to query data there.
  //   // MUI DataGrid only contains the id field, so have to get the sample name for the matching form (based on id) before nagivating
  //   const sampleName = wetlandForms.find((form) => form.id === item.id).sampleName;
  //   const sanitizedSampleName = sampleName.replace(/\//g, '_'); // Remove any characters that might cause an issue with the route (most notably forward slash)
  //   navigate(`/wetland/${sanitizedSampleName}`, { state: { actionType: formActionType.EDIT, formId: item.id } });
  // };

  const projectInFilter = (project) => {
    // If the filter is a blank string, always return true
    if (projectsFilterText === '' || !projectsFilterText) return true;
    if (project.name.toLowerCase().includes(projectsFilterText.toLowerCase())) return true;
    if (project.project_number.toLowerCase().includes(projectsFilterText.toLowerCase())) return true;
    const project_user_full_names = project.assigned_users.map(user => user.full_name.toLowerCase());
    const assigned_user_name_match = project_user_full_names.some((full_name) => full_name.toLowerCase().includes(projectsFilterText.toLowerCase()));
    if (assigned_user_name_match === true) return true;
    return false;
  }

  if (projectsLoadingStatus === requestStatuses.LOADING) return (
    <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', width: '100%', height: '60vh' }}>
      <CircularProgress color="secondary" />
    </Box>
  );

  if (projectsLoadingStatus === requestStatuses.ERROR) return (
    <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', width: '100%', height: '60vh' }}>
      <Typography color="error">Error loading projects. Refresh the page or check your internet connection.</Typography>
    </Box>
  );

  if (projects?.length === 0) return (
    <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', width: '100%', height: '60vh' }}>
      <Typography color="text.secondary">No projects found.</Typography>
    </Box>
  );

  if (!projects) return (
    <Grid item md={6} lg={4}>No projects</Grid>
  );

  return (
    // <Paper sx={{ p: 2, backgroundColor: '#e8e6e3' }}>
    <Fragment>
      <Grid container spacing={2}>
      {/* <Grid item md={12} lg={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" component="h2">Active Projects</Typography>
          <IconButton aria-label="toggle projects accordion">
            <ExpandLessIcon />
          </IconButton>
        </Stack>
      </Grid> */}
      {projects ? projects
      .filter((project) => projectInFilter(project) && project.status === "Active")
      .map((project, index) => (
        <Grid key={`project-grid-${index}`} item sm={6} md={6} lg={4}>
          <ProjectCard key={`project-${index}`} projectData={project} />
        </Grid>
      )) : <Grid item md={6} lg={4}>No projects</Grid>}
    </Grid>
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={4}
        sx={{ width: '100%' }}
      >
        <Divider variant="middle" component="div" role="presentation" color="text.secondary" style={{width:'75%'}}>
            <Typography color="text.secondary">
            Non-Active Projects
          </Typography>
          </Divider>
      </Box>
    
    <Grid container spacing={2}>
      {projects && projects
      .filter((project) => projectInFilter(project) && project.status !== "Active")
      .map((project, index) => (
        <Grid key={`project-grid-completed-${index}`} item sm={6} md={6} lg={4}>
          <ProjectCard key={`project-completed-${index}`} projectData={project} />
        </Grid>
      ))}
    </Grid>
    </Fragment>
    
  );
}
