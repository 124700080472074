import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function ActiveIndicatorCard({ activeIndicator, onClose, ...props }) {

  const [positionY, setPositionY] = useState(window.scrollY);

  useEffect(() => {
    const scrollChange = () => {
      let newScroll = window.scrollY;
      if (window.scrollY < 80) {
        newScroll = 80;
      } else if (window.scrollY > (window.document.body.offsetHeight - 320)) {
        newScroll = window.document.body.offsetHeight - 320;
      }
      setPositionY(newScroll);
    };
    window.addEventListener("scroll", scrollChange);
    return () => window.removeEventListener("scroll", scrollChange);
  }, []);

  return (
    <Card elevation={4} sx={{
      willChange: 'transform',
      minWidth: 400, 
      maxHeight: window.innerHeight - 160, 
      overflow: 'scroll',
      position: 'absolute', 
      top: positionY - 160,
       }}>
      <CardHeader
        action={
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        }
        title={`${activeIndicator?.symbol}: ${activeIndicator?.name}`}
        subheader={`${activeIndicator?.category} Indicator`}
      />
      <CardContent>
        <Stack spacing={2}>
          {activeIndicator && activeIndicator?.description && (
            <Box>
              <Typography color="text.primary" fontWeight="bold">Description</Typography>
              <Typography color="text.primary" fontSize={14}>{activeIndicator?.description}</Typography>
            </Box>
          )}
          {activeIndicator && activeIndicator?.cautionsAndNotes && (
            <Box>
              <Typography color="text.primary" fontWeight="bold">Cautions and User Notes</Typography>
              <Typography color="text.primary" fontSize={14}>{activeIndicator?.cautionsAndNotes}</Typography>
            </Box>
          )}
          {activeIndicator && activeIndicator?.applicable && (
            <Box>
              <Typography color="text.primary" fontWeight="bold">Applicable Subregions</Typography>
              <Typography color="text.primary" fontSize={14}>{activeIndicator?.applicable}</Typography>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
