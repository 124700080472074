import React from "react";
import IndicatorList from "../../../features/indicatorList/IndicatorList";
import RestrictiveLayer from "./components/RestrictiveLayer";
import SoilProfile from "./components/SoilProfile";
import SoilsSummary from "./components/SoilsSummary";
import { Box, Slide, Stack, useMediaQuery } from "@mui/material";
import ActiveIndicatorCard from "../../../features/indicatorList/ActiveIndicatorCard";
import { useSelector } from "react-redux";
import { selectCurrentWetlandFormRegion } from "../../../api/services/wetlandforms/wetlandFormsSlice";
import { hydroIndDescrips } from "../../../resources/hydroIndDescrips";
import { soilIndDescrips } from "../../../resources/soilIndDescrips";
import { useTheme } from "@mui/material/styles";

function getIndicatorDescription({ indicatorType = "Hydrology", aceRegion = "NCNE", indicator = "A1" }) {
  if (indicatorType === "Hydrology") {
    return hydroIndDescrips[aceRegion][indicator];
  } else {
    return soilIndDescrips[aceRegion][indicator];
  }
}

export default function SoilsTab() {
  const theme = useTheme();
  const lessThanMdBreakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const currentFormRegion = useSelector(selectCurrentWetlandFormRegion);
  const [activeIndicator, setActiveIndicator] = React.useState(null);
  const [showActiveIndicator, setSowActiveIndicator] = React.useState(false);

  const setActiveIndicatorAndShow = (indicator) => {
    // Need to append the indicator description information to the indicator object, which by default only contains the symbol, name, and category
    const indicatorDescription = getIndicatorDescription({
      indicatorType: "Soil",
      aceRegion: currentFormRegion,
      indicator: indicator.symbol,
    });
    setActiveIndicator({
      ...indicator,
      ...indicatorDescription,
    });
    setSowActiveIndicator(true);
  }

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box>
          <SoilProfile />
          <RestrictiveLayer />
          <IndicatorList 
            indicatorType="Soil" 
            indicatorCategory="Regular" 
            handleShow={setActiveIndicatorAndShow} 
            showAsModalDialog={lessThanMdBreakpoint} 
          />
          <IndicatorList 
            indicatorType="Soil" 
            indicatorCategory="Problem" 
            handleShow={setActiveIndicatorAndShow} 
            showAsModalDialog={lessThanMdBreakpoint}
          />
          <SoilsSummary />
        </Box>
        <Box ml={4} sx={{ position: "sticky", zIndex: 1 }}>
          <Slide direction="left" in={showActiveIndicator} mountOnEnter unmountOnExit>
            <Box>
              <ActiveIndicatorCard
                activeIndicator={activeIndicator}
                onClose={() => setSowActiveIndicator(false)}
              />
            </Box>
          </Slide>
          <Slide direction="left" in={showActiveIndicator} mountOnEnter unmountOnExit>
            <Box sx={{ minWidth: 400 }} />
          </Slide>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
