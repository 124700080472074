import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, ImageList, Typography } from "@mui/material";
import FormSectionHeader from "../../../components/FormSectionHeader";
import {
  getWetlandFormPhotos,
  selectCurrentWetlandFormId,
  selectCurrentWetlandFormPhotosLoading,
  selectCurrentWetlandFormPhotos,
} from "../../../api/services/wetlandforms/wetlandFormsSlice";
import { colorGreyLight } from "../../../utils/colors";
import AddPhotoCard from "./components/AddPhotoCard";
import PhotoCard from "./components/PhotoCard";

export default function PhotosTab() {
  const dispatch = useDispatch();
  const photosLoading = useSelector(selectCurrentWetlandFormPhotosLoading);
  const photos = useSelector(selectCurrentWetlandFormPhotos); // If the length of this object is one, the only thing present is the 'add photos' button
  const formId = useSelector(selectCurrentWetlandFormId);

  useEffect(() => {
    // When switching to this tab, load the form photos if they haven't already been loaded
    if (!photos || photos?.length === 0) {
      if (formId) {
        dispatch(getWetlandFormPhotos(formId));
      }
    }
  }, [formId, dispatch]);

  return (
    <Stack spacing={2}>
      <FormSectionHeader text={"Photos"} mt={0} mx={0} />
      <ImageList gap={32} sx={{ p: 2 }}>
        {photosLoading && <Typography color={colorGreyLight}>Loading photos...</Typography>}
        {!photosLoading &&
          photos &&
          photos
            .filter((item) => item?.shouldRemove !== true)
            .map((item, index) => <PhotoCard key={item?.id ?? 'new-photo'} photo={item} index={index} />)}
        <AddPhotoCard />
      </ImageList>
    </Stack>
  );
}
