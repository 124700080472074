import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, selectProjectsLoadingStatus, selectProjectsWithOnlyEssentialAttributes } from '../../../api/services/projects/projectsSlice';
import { requestStatuses } from '../../../utils/constants';
import { selectProjectFilter, setProjectFilter } from '../../../api/services/spatial/spatialSlice';

export default function MapFilterBar() {
  // In the future, this can be connected to share state with the "All Forms" DataGrid filter, but for now this is unlinked
  const dispatch = useDispatch();
  const projectsLoadingStatus = useSelector(selectProjectsLoadingStatus);
  const projects = useSelector(selectProjectsWithOnlyEssentialAttributes);
  const projectFilter = useSelector(selectProjectFilter);

  const onFilterChange = (newProject) => {
    dispatch(setProjectFilter(newProject));
    localStorage.setItem('map_filter_project', JSON.stringify(newProject));
  }

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {

      if (projectsLoadingStatus === requestStatuses.IDLE) {
        const data = await dispatch(getProjects()).unwrap();
      }

      if (active) {
        setOptions([...projects]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, projects, dispatch]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="filter-map-by-project"
      sx={{ width: 300 }}
      autoHighlight
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      value={projectFilter}
      onChange={(event, newValue) => {
        // newValue is the new selected project object
        onFilterChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Filter by Project"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
