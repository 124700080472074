import {
  Typography,
  Stack,
  Chip,
  MenuItem,
  Menu,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { Fragment } from 'react';
import { userAssignmentPermissionOptions } from "./userAssignmentPermissionOptions";

export default function ProjectAssignmentEditChips({ userAssignments, setUserAssignments, ...props }) {
  
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const popoverOpen = Boolean(popoverAnchorEl);
  const [selectedPermissionItemIndex, setSelectedPermissionItemIndex] = React.useState(0);
  const [selectedUserToChange, setSelectedUserToChange] = React.useState(null);

  const handleUserChipClick = (event, userAssignment) => {
    setSelectedUserToChange({...userAssignment});
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, permissionItemIndex) => {
    if (selectedUserToChange !== null) {
      const modifiedUserAssignments = userAssignments.map(userAssignment => {
        if (userAssignment.email === selectedUserToChange.email) {
          userAssignment.updated_permission_level = userAssignmentPermissionOptions[permissionItemIndex].value;
        }
        return userAssignment;
      });
      setUserAssignments(modifiedUserAssignments);
      setSelectedPermissionItemIndex(permissionItemIndex);
      setSelectedUserToChange({updated_permission_level: userAssignmentPermissionOptions[permissionItemIndex].value, ...selectedUserToChange});
    }
    setPopoverAnchorEl(null);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const permissionOptionDisplayNameFromValue = (value) => {
    // Given the raw value from the model object, return the string used for display purposes for the permission level
    const matchingOption = userAssignmentPermissionOptions.find(option => option.value === value);
    if (matchingOption) return matchingOption.displayName;
    return 'No permission';
  };

  const handleToggleUserInProject = (event, userToChange) => {
    event.preventDefault();
    const modifiedUserAssignments = userAssignments.map(userAssignment => {
      if (userAssignment.email === userToChange.email) {
        userAssignment.updated_in_project = !userAssignment.updated_in_project;
        // If adding a user to the project, set their default permission level (read all, write own)
        if (userAssignment.updated_in_project) {
          userAssignment.updated_permission_level = 'ReadAllWriteOwn';
        }
      }
      // If removing a user from the project, be sure to reset their permission level as well
      if (!userAssignment.updated_in_project) {
        userAssignment.updated_permission_level = userAssignment.current_permission_level;
      }
      return userAssignment;
    });
    setUserAssignments(modifiedUserAssignments);
  };


  return (
    <Fragment>
      {userAssignments
            .filter((userAssignment) => userAssignment.updated_in_project)
            .map((userAssignment, index) => {
              const isProjectLead = userAssignment.updated_permission_level === 'ProjectEditor';
              return (
              <Fragment key={`project-users-${index}`}>
                <Chip
                  id={`project-users-chip-${index}`}
                  title={isProjectLead ? "Assigned to project as a Project Lead" : "Assigned to project as a Standard User"}
                  color={isProjectLead ? "secondary" : undefined}
                  icon={<ArrowDropDownIcon />}
                  onClick={(e) => handleUserChipClick(e, userAssignment)}
                  onDelete={(e) => handleToggleUserInProject(e, userAssignment)}
                  aria-haspopup="listbox"
                  aria-controls="permission-change-menu"
                  aria-label="user view and edit permission in project"
                  aria-expanded={popoverOpen ? 'true' : undefined}
                  label={<Stack>
                    <Typography variant="body2">{userAssignment.full_name}</Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>{permissionOptionDisplayNameFromValue(userAssignment.updated_permission_level)}</Typography>
                  </Stack>}
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                  }}
                />
                <Menu
                  id="permission-change-menu"
                  anchorEl={popoverAnchorEl}
                  open={popoverOpen}
                  onClose={handleClosePopover}
                  MenuListProps={{
                    'aria-labelledby': `project-users-chip-${index}`,
                    role: 'listbox',
                  }}
                >
                  {userAssignmentPermissionOptions.map((permissionOption, permissionIndex) => (
                    <MenuItem
                      id={`users-option-${permissionOption.value}`}
                      key={`users-option-${permissionOption.value}`}
                      selected={permissionIndex === selectedPermissionItemIndex}
                      onClick={(e) => handleMenuItemClick(e, permissionIndex)}
                    >
                      {permissionOption.description}
                    </MenuItem>
                  ))}
                </Menu>
              </Fragment>
            )})}
    </Fragment>
  )
}
