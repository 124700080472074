import React from "react";
import { Card, Stack, Link, Typography } from "@mui/material";

export default function ResourcesAndInfo() {
  return (
    <Stack>
      <Card sx={{ margin: 4 }}>
        <Stack spacing={2} p={2}>
          <Typography component="h2" variant="h4">
            Delineation Resources
          </Typography>
          <Link
            href="https://usace.contentdm.oclc.org/digital/collection/p266001coll1/id/4530"
            target="_blank"
            rel="noopener noreferrer"
          >
            USACE '87 Wetlands Delineation Manual
          </Link>
          <Link
            href="https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            USACE Regional Supplements
          </Link>
          <Link
            href="https://cwbi-app.sec.usace.army.mil/nwpl_static/v34/home/home.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            NWPL
          </Link>
          <Link href="http://www.bonap.org/" target="_blank" rel="noopener noreferrer">
            BONAP (The Biota of North America Program)
          </Link>
          <Link href="http://floranorthamerica.org/Main_Page" target="_blank" rel="noopener noreferrer">
            FNA (Flora of North America)
          </Link>
        </Stack>
      </Card>
      {/* <Card sx={{ margin: 4 }}>
        <Stack spacing={2} p={2}>
          <Typography component="h2" variant="h4">
            BioApp Video Resources
          </Typography>
          <Link href="#" target="_blank" rel="noopener noreferrer">
            Site Basics - upload, view, and download data.
          </Link>
          <Link href="#" target="_blank" rel="noopener noreferrer">
            Wetland Determination Form - how to edit and QAQC a form.
          </Link>
          <Link href="#" target="_blank" rel="noopener noreferrer">
            What is an Organization? How do I view data collected by other people?
          </Link>
        </Stack>
      </Card> */}
    </Stack>
  );
}
