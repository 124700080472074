import React from 'react';
import {
  Button,
  CssBaseline,
  Container,
  Typography,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';

export default function PasswordResetSuccess() {
  const navigate = useNavigate();

  const navigateHome = (event) => {
    event.preventDefault();
    navigate("/login", { replace: true });
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Container maxWidth="xs" sx={{ p: 4 }}>
        <Typography color="#378039" mb={4}>
          Password reset! Return to data.bioapp.us to log in.
        </Typography>
        <Button fullWidth variant="contained" onClick={navigateHome}>
          Return to Login
        </Button>
      </Container>
    </Container>
  )
}
