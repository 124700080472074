import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  changeCurrentWetlandFormPage,
  changeCurrentWetlandFormSoilLayerValue,
  changeCurrentWetlandFormSoilLayerChroma,
  changeCurrentWetlandFormColorItem,
  deleteCurrentWetlandSoilColorItem,
  selectCurrentWetlandSoilLayerRedoxContrasts,
  changeCurrentWetlandFormSoilLayerHue,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import { soilHues } from "../../../../utils/constants/munsellConstants";
import { soilColors } from "../../../../resources/soilColors";
import { colorGreyMidtone } from "../../../../utils/colors";

function rgbToString(rgbArray) {
  return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
}

export default function SoilLayerColorRow({ index, colorItemIndex, colorItem, matrixOrRedox = "matrix" }) {
  // const soilLayers = useSelector(selectCurrentWetlandSoilLayers);
  // const layer = soilLayers[index];
  const colorItemPage = colorItem[`${matrixOrRedox}Page`];
  const colorItemHue = colorItem[`${matrixOrRedox}Hue`];
  const colorItemValue = colorItem[`${matrixOrRedox}Value`];
  const colorItemChroma = colorItem[`${matrixOrRedox}Chroma`];
  const colorItemPercent = colorItem[`${matrixOrRedox}Percent`];
  const redoxContrasts = useSelector((state) => selectCurrentWetlandSoilLayerRedoxContrasts(state, index, colorItemIndex));
  const dispatch = useDispatch();

  const [soilValueOptions, setSoilValueOptions] = useState([]);
  const [soilChromaOptions, setSoilChromaOptions] = useState([]);
  const [soilColor, setSoilColor] = useState([255, 255, 255]); // array of soil colors [r, g, b]

  useEffect(() => {
    // When a form is first loaded, convert all of its loaded string values for chroma and value to float/int
    if (typeof colorItem[colorItemValue] === "string") {
      const valueToNum = parseFloat(colorItem[colorItemValue]);
      // Using the standard soil layer update function because soil calcs don't need to be re-run for this type conversion
      dispatch(changeCurrentWetlandFormColorItem({ index: index, colorItemIndex: colorItemIndex, matrixOrRedox: matrixOrRedox, name: colorItemValue, value: valueToNum ?? "" }));
    }
    if (typeof colorItem[colorItemChroma] === "string") {
      const chromaToNum = parseFloat(colorItem[colorItemChroma]);
      dispatch(changeCurrentWetlandFormColorItem({ index: index, colorItemIndex: colorItemIndex, matrixOrRedox: matrixOrRedox, name: colorItemChroma, value: chromaToNum ?? "" }));
    }
  }, [dispatch, colorItem, colorItemValue, colorItemChroma, index, colorItemIndex, matrixOrRedox]);

  useEffect(() => {
    const dynamicSoilValues = [...new Set(soilColors[colorItemPage]?.map((colorChoice) => colorChoice.value))];
    setSoilValueOptions(dynamicSoilValues);
  }, [colorItemPage]);

  useEffect(() => {
    const filteredColors = soilColors[colorItemPage]?.filter((colorChoice) => colorChoice.value === colorItemValue);
    // If a GLEY/WHITE page, the gleyHue is joined to each chroma option string (for display purposes, and is separated out again later)
    const dynamicSoilChromas = [...new Set(filteredColors?.map((colorChoice) => colorChoice.gleyHue !== null ? `${colorChoice.chroma} ${colorChoice.gleyHue}` : colorChoice.chroma))];
    setSoilChromaOptions(dynamicSoilChromas);
  }, [colorItemPage, colorItemValue]);

  useEffect(() => {
    const matchingColors = soilColors[colorItemPage]?.filter(
      (colorChoice) => colorChoice.value === colorItemValue && colorChoice.chroma === colorItemChroma
    );
    if (matchingColors !== undefined) {
      if (matchingColors.length >= 0) {
        if (matchingColors[0] !== undefined) {
          setSoilColor(matchingColors[0].rgb);
        }
      }
    }
  }, [colorItemPage, colorItemValue, colorItemChroma]);

  const handleSoilLayerStandardItemChange = (e) => {
    dispatch(changeCurrentWetlandFormColorItem({ index: index, colorItemIndex: colorItemIndex, matrixOrRedox: matrixOrRedox, name: e.target.name, value: e.target.value }));
  };

  const handleSoilrowPageChange = (e) => {
    dispatch(changeCurrentWetlandFormPage({ index: index, matrixOrRedox: matrixOrRedox, colorItemIndex: colorItemIndex, value: e.target.value }));
  };

  const handleSoilrowValueChange = (e) => {
    dispatch(
      changeCurrentWetlandFormSoilLayerValue({ index: index, matrixOrRedox: matrixOrRedox, colorItemIndex: colorItemIndex, value: e.target.value })
    );
  };

  const handleSoilrowChromaChange = (e) => {
    // The value of this event might be just the regular chroma (int), or if a GLEY/WHITE page, a string joined with the hue
    if (typeof e.target.value === 'string') {
      dispatch(
        changeCurrentWetlandFormSoilLayerHue({ index: index, matrixOrRedox: matrixOrRedox, colorItemIndex: colorItemIndex, value: e.target.value.split(' ')[1] })
      );
      dispatch(
        changeCurrentWetlandFormSoilLayerChroma({ index: index, matrixOrRedox: matrixOrRedox, colorItemIndex: colorItemIndex, value: parseInt(e.target.value.split(' ')[0]) })
      );
    } else {
      dispatch(
        changeCurrentWetlandFormSoilLayerChroma({ index: index, matrixOrRedox: matrixOrRedox, colorItemIndex: colorItemIndex, value: e.target.value })
      );
    }
  };

  const handleRemoveSoilColorItem = (event) => {
    event.preventDefault();
    dispatch(deleteCurrentWetlandSoilColorItem({ index: index, colorItemIndex: colorItemIndex, matrixOrRedox: matrixOrRedox }));
  };

  return (
    <React.Fragment>
      <Grid item xs={2} md={2} alignSelf="center">
        {colorItemIndex === 0 && <Typography fontWeight="bold">{matrixOrRedox}: </Typography>}
      </Grid>
      <Grid item xs={2} md={2}>
        <FormControl fullWidth>
          <InputLabel id={`soil-layer-page-${index}-label`}>Munsell Page</InputLabel>
          <Select
            labelId={`soil-layer-page-${index}-label`}
            id={`soil-layer-page-${index}`}
            name={`${matrixOrRedox}Page`}
            value={colorItemPage}
            label="Munsell Page"
            onChange={handleSoilrowPageChange}
          >
            {soilHues.map((munsellPage) => (
              <MenuItem key={munsellPage} value={munsellPage}>
                {munsellPage}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} md={2}>
        <FormControl fullWidth>
          <InputLabel id={`soil-layer-value-${index}-label`}>Value</InputLabel>
          <Select
            labelId={`soil-layer-value-${index}-label`}
            id={`soil-layer-value-${index}`}
            name={`${matrixOrRedox}Value`}
            value={soilValueOptions.includes(colorItemValue) ? colorItemValue : ""}
            label="Value"
            onChange={handleSoilrowValueChange}
          >
            {soilValueOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} md={2}>
        <FormControl fullWidth>
          <InputLabel id={`soil-layer-chroma-${index}-label`}>Chroma</InputLabel>
          <Select
            labelId={`soil-layer-chroma-${index}-label`}
            id={`soil-layer-chroma-${index}`}
            name={`${matrixOrRedox}Chroma`}
            value={['GLEY1', 'GLEY2', 'WHITE'].includes(colorItemPage) ? `${colorItemChroma} ${colorItemHue}` : (soilChromaOptions.includes(colorItemChroma) ? colorItemChroma : "")}
            label="Chroma"
            onChange={handleSoilrowChromaChange}
          >
            {soilChromaOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1} md={1} alignSelf="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: colorItemValue !== "" && colorItemChroma !== "" ? rgbToString(soilColor) : null,
              height: 40,
              width: 40,
            }}
          ></Card>
        </Stack>
      </Grid>
      <Grid item xs={2} md={2}>
        <TextField
          id={`soil-layer-${matrixOrRedox}-percent-${index}`}
          name={`${matrixOrRedox}Percent`}
          label="Percent"
          type="percent"
          value={colorItemPercent ?? ""}
          onChange={handleSoilLayerStandardItemChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          sx={{ width: "14ch" }}
        />
      </Grid>
      <Grid item xs={1} md={1} alignSelf="center">
        <Button color="error" onClick={handleRemoveSoilColorItem}>
          <DeleteIcon />
        </Button>
      </Grid>
      {matrixOrRedox === 'redox' && <Grid item xs={2}></Grid>}
      {matrixOrRedox === 'redox' && <Grid item xs={10}>
        <Typography color={colorGreyMidtone} fontSize={"0.9em"}>Contrasts: {redoxContrasts}</Typography>
      </Grid>}
    </React.Fragment>
  );
}
