import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Typography, TextField, Switch } from "@mui/material";
import {
  changeCurrentWetlandFormValue,
  selectCurrentWetlandFormCalculationItems,
  selectCurrentWetlandPrevIndexAsRoundedString,
  selectCurrentWetlandVegComment,
} from "../../../../api/services/wetlandforms/wetlandFormsSlice";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { colorError, colorSuccess } from "../../../../utils/colors";

export default function VegCalculationResults() {
  const dispatch = useDispatch();
  const {
    rapidTest,
    dominanceTest,
    prevalenceIndex,
    prevalenceIndexMet,
    morphAdaptations,
    vegNaturallyProb,
    problematicHydrophyticVeg,
    wetlandVeg,
  } = useSelector(selectCurrentWetlandFormCalculationItems);
  const prevIndexRounded = useSelector(selectCurrentWetlandPrevIndexAsRoundedString);
  const vegComment = useSelector(selectCurrentWetlandVegComment);

  const handleSwitchInputFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.checked }));
  };

  const handleStandardFormValueChange = (e) => {
    dispatch(changeCurrentWetlandFormValue({ name: e.target.id, value: e.target.value }));
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
          Calculations
        </Typography>
      </Grid>
      <Grid item xs={10} md={6}>
        <Typography>Rapid Test for Hydrophytic Vegetation:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        {rapidTest ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
      </Grid>
      <Grid item xs={10} md={6}>
        <Typography>Dominance Test:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        {dominanceTest ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
      </Grid>
      <Grid item xs={10} md={6}>
        <Typography>Prevalence Index:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        <Typography color={prevalenceIndexMet ? colorSuccess : colorError}>{prevIndexRounded ?? "N/A"}</Typography>
      </Grid>
      <Grid item xs={10} md={6}>
        <Typography>Morphological Adaptations:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        <Switch
          id="morphAdaptations"
          checked={morphAdaptations}
          onChange={handleSwitchInputFormValueChange}
          inputProps={{ "aria-label": "Morphological Adaptations Switch" }}
        />
      </Grid>
      <Grid item xs={10} md={6}>
        <Typography>Problematic Hydrophytic Vegetation:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        <Switch
          id="problematicHydrophyticVeg"
          checked={problematicHydrophyticVeg != null ? problematicHydrophyticVeg : vegNaturallyProb}
          onChange={handleSwitchInputFormValueChange}
          inputProps={{ "aria-label": "Problematic Hydrophytic Vegetation Switch" }}
        />
      </Grid>
      <Divider />
      <Grid item xs={10} md={6}>
        <Typography>Hydrophytic Vegetation:</Typography>
      </Grid>
      <Grid item xs={2} md={6}>
        <Switch
          id="wetlandVeg"
          checked={wetlandVeg}
          onChange={handleSwitchInputFormValueChange}
          inputProps={{ "aria-label": "Hydrophytic Vegetation Switch" }}
        />
      </Grid>
      <Grid item xs={12}>
      <TextField
        id="vegComment"
        label="Vegetation Comment"
        fullWidth
        multiline
        rows={4}
        value={vegComment ?? ""}
        onChange={handleStandardFormValueChange}
      />
      </Grid>
    </Grid>
  );
}
