import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import SubscriptionReceipts from "./SubscriptionReceipts";

function firstAndLastName(first='', last='') {
  return `${first} ${last}`;
}

export default function UserProfile({ user }) {

  return (
    <Box mx={4} mt={2} mb={0}>
      <Card sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          User Details
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user?.email || ""}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{firstAndLastName(user?.first_name, user?.last_name)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Subscription Status</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user?.subscription_status || ""}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Subscription Expiration Date</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{user?.subscribed_until_date ? format(new Date(user?.subscribed_until_date), "MM/dd/yyyy hh:mm aaa") : ""}</Typography>
          </Grid>
        </Grid>
        <SubscriptionReceipts />
      </Card>
    </Box>
  );
}
