import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { format, parseISO } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  setProjectsLoadingStatus,
  updateProject,
  updateProjectAssignmentRoles,
} from "../../api/services/projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import ProjectUserAssignments from "./ProjectUserAssignments";
import { addErrorMessage } from "../../api/services/messages/messagesSlice";
import { requestStatuses } from "../../utils/constants";
import { selectUser } from "../../api/services/user/userSlice";
import { projectStatusColor } from "./ProjectCard";

export default function ProjectInfoSheet({ selectedProjectData, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmProjectNameForDelete, setConfirmProjectNameForDelete] = useState("");

  const changeConfirmProjectNameForDelete = (e) => {
    setConfirmProjectNameForDelete(e.target.value);
  };

  useEffect(() => {
    // Whenever the Redux selected project data is updated, reflect that in this component
    setProjectData(selectedProjectData);
  }, [selectedProjectData]);

  const [projectData, setProjectData] = useState({ ...selectedProjectData });

  const [editingMode, setEditingMode] = useState(false);

  const projectEditFieldsValid = () => {
    if (projectData.name === "") {
      return false;
    }
    return true;
  };

  const handleEnableEditFields = (event) => {
    // This sets the state of "editing" for the project to true, and changes the components from non-editable typography/text components to input fields
    event.preventDefault();
    setEditingMode(true);
  };

  const handleSaveProjectEdits = async (event) => {
    // This sets the state of "editing" to false after sending the update request to the server. As part of that process, if successful, updates the selectedProject data as well
    event.preventDefault();
    if (projectEditFieldsValid()) {
      setEditingMode(false);
      // Only attempt to perform the user assignment update if any user assignments have been changed
      if (userAssignments.some((assignment) => assignment.updated_in_project !== assignment.currently_in_project || assignment.updated_permission_level !== assignment.current_permission_level)) {
        // Update the assignment role changes first, so that this data is properly reflected when the project update returns the new data to display
        const userAssignmentsResult = await dispatch(updateProjectAssignmentRoles({project_id: projectData.id, rawUsersData: userAssignments})).unwrap();
      }
      dispatch(updateProject(projectData));
    }
  };

  const handleCancelProjectEdits = (event) => {
    // Cancel editing, reset all local state changes to the selected project data
    // This should include resetting the user assignment update date
    event.preventDefault();
    setProjectData({ ...selectedProjectData });
    const resetUserAssignments = userAssignments.map((userAssignment) => {
      userAssignment.updated_in_project = userAssignment.currently_in_project;
      userAssignment.updated_permission_level = userAssignment.current_permission_level;
      return userAssignment;
    });
    setUserAssignments(resetUserAssignments);
    setEditingMode(false);
  };

  const changeProjectValue = (e) => {
    const newValue = e.target.value;
    setProjectData({ ...projectData, [e.target.name]: newValue });
  };

  const changeProjectCompletionDate = (newDate) => {
    let parsedDate = null;
    try {
      parsedDate = format(newDate, "yyyy-MM-dd");
    } catch (err) {
      parsedDate = null;
    }
    setProjectData({ ...projectData, completion_date: parsedDate });
  };

  const handleOpenDeleteProjectDialog = () => {
    setDeleteDialogOpen(true);
  }

  const handleCloseDeleteProjectDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleAttemptDeleteProject = async () => {
    if (confirmProjectNameForDelete === projectData.name) {
      const result = await dispatch(deleteProject({ slug: projectData.slug, id: projectData.id })).unwrap();
      // After delete, close the dialog and navigate to the projects list page
      setDeleteDialogOpen(false);
      if (result.status === 204) {
        dispatch(setProjectsLoadingStatus(requestStatuses.IDLE)); // Setting this ensures that projects are re-loaded when going back to the grid list
        navigate(`/`);
      }
    } else {
      setDeleteDialogOpen(false);
    }
  };

  const userHasProjectWriteCapabilities = () => {
    // True if user is an org admin or their assignment in the project is "project editor". Also true if the user isn't in an org
    // To avoid having to re-fetch organization/membership data, only disable if user has a project assignment and it isn't a project editor assignment
    if (!selectedProjectData.organization) return true;
    if (!user?.email) return false;
    if (user?.organization && user?.org_user_role === 'orgManager') return true;
    const userAssignment = selectedProjectData.assignment_projects.find(assign => assign.user.email === user.email);
    if (userAssignment === undefined) return true;
    if (selectedProjectData.created_by.email === userAssignment.user.email) return true;
    if (userAssignment.permission_level !== 'ProjectEditor') return false;
    return true;
  }

  const [userAssignments, setUserAssignments] = useState([]); // This is only used in edit mode, and is reset when leaving edit mode

  if (!projectData)
    return (
      <Paper sx={{ p: 1 }}>
        <Box justifyContent="center" sx={{ display: "flex", m: 2 }}>
          <CircularProgress color="secondary" />
        </Box>
      </Paper>
    );

  return (
    <Paper sx={{ p: 2 }}>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            {editingMode ? (
              <TextField
                id="project-name"
                name="name"
                label="Project Name"
                fullWidth
                variant="outlined"
                required
                value={projectData.name}
                error={projectData.name === ""}
                onChange={changeProjectValue}
              />
            ) : (
              <Typography variant="h5" component="h1" sx={{ py: 1 }}>
                {projectData.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} textAlign="end">
            <Stack direction="row" justifyContent="flex-end">
            {/* The edit button/save and cancel buttons are disabled if the user does not have edit access. */}
              {editingMode && (
                <Button 
                color="warning" 
                onClick={handleCancelProjectEdits} 
                sx={{ marginRight: "16px" }}>
                  Cancel
                </Button>
              )}
              {editingMode ? (
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<SaveIcon />}
                  onClick={handleSaveProjectEdits}
                  sx={{ marginRight: "16px" }}
                >
                  Save
                </Button>
              ) : (
                <Tooltip title={userHasProjectWriteCapabilities() ? "Edit project" : "You do not have permission to edit project info"}>
                  <span>
                    <Button
                      variant="text"
                      color="secondary"
                      endIcon={<EditIcon />}
                      onClick={handleEnableEditFields}
                      disabled={!userHasProjectWriteCapabilities()}
                      sx={{ marginRight: "16px" }}
                    >
                      Edit
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div id="project-created-by-text">
            <Typography display="inline" color="text.secondary" sx={{ fontSize: 14 }}>
              {"Created by "}
            </Typography>
            <Chip
                key={"project-creator-chip"}
                title="Project Creator"
                color="secondary"
                label={<Stack>
                  <Typography variant="body2" sx={{ fontSize: 14 }}>{projectData.created_by.full_name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>Project Creator</Typography>
                </Stack>}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              />
            <Typography display="inline" color="text.secondary" sx={{ fontSize: 14 }}>
              {` on ${projectData.created_date}`}
            </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={4}>
            {editingMode ? (
              <FormControl sx={{ minWidth: 100 }}>
                <InputLabel id="project-status-label">Status</InputLabel>
                <Select
                  labelId="project-status-label"
                  id="project-status-select"
                  name="status"
                  value={projectData.status}
                  label="Status"
                  onChange={changeProjectValue}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Inactive'}>Inactive</MenuItem>
                  <MenuItem value={'Complete'}>Complete</MenuItem>
                  <MenuItem value={'Stopped'}>Stopped</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Typography color={projectStatusColor(projectData.status)}>Status: {projectData.status}</Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={4}>
          {editingMode ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="completion-date"
              label="Completion Date"
              value={parseISO(projectData.completion_date)}
              onChange={changeProjectCompletionDate}
              renderInput={(params) => <TextField {...params} error={false} fullWidth />}
            />
          </LocalizationProvider>
            ) : (
              <Stack direction="row">
                <Typography>Completion Date:&nbsp;</Typography>
                <Typography color={!projectData.completion_date && 'text.secondary'}>{projectData.completion_date ?? 'None'}</Typography>
              </Stack>
            )}
          </Grid>
          <ProjectUserAssignments projectData={projectData} editingMode={editingMode} userAssignments={userAssignments} setUserAssignments={setUserAssignments} />
          <Grid item xs={4}>
            <TextField
                id="project-number"
                name="project_number"
                label="Project Number"
                helperText="Use as a unique project identifier"
                fullWidth
                variant="outlined"
                disabled={!editingMode}
                value={projectData.project_number}
                onChange={changeProjectValue}
              />
          </Grid>
          <Grid item xs={4}>
            <TextField
                id="project-or-site-name"
                name="project_or_site_name"
                label="Project/Site Name"
                helperText="Name recorded in PDF outputs"
                fullWidth
                variant="outlined"
                disabled={!editingMode}
                value={projectData.project_or_site_name}
                onChange={changeProjectValue}
              />
          </Grid>
          <Grid item xs={4}>
            <TextField
                id="applicant-or-owner-name"
                name="applicant_or_owner_name"
                label="Applicant/Owner"
                helperText="Applicant/Owner as it will appear in PDF output"
                fullWidth
                variant="outlined"
                disabled={!editingMode}
                value={projectData.applicant_or_owner_name}
                onChange={changeProjectValue}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
                id="project-notes"
                name="notes"
                label="Project Notes"
                helperText="These notes are for internal use only, and do not appear in any output data"
                multiline
                maxRows={6}
                fullWidth
                variant="outlined"
                disabled={!editingMode}
                value={projectData.notes}
                onChange={changeProjectValue}
              />
          </Grid>
          <Grid item xs={12} textAlign="end">
            <Stack direction="row" justifyContent="flex-end">
              {editingMode && (
                <Button variant="contained" color="error" endIcon={<DeleteIcon />} onClick={handleOpenDeleteProjectDialog} sx={{ marginRight: "16px" }}>
                  Delete
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteProjectDialog}>
        <DialogTitle sx={{ color: 'error.main' }}>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will permanently delete this project! Confirm your intent by re-typing the name of the project below.
          </DialogContentText>
          <TextField
            autoFocus
            id="confirmProjectNameForDeletion"
            label="Type project name to confirm deletion"
            fullWidth
            margin="normal"
            value={confirmProjectNameForDelete}
            onChange={changeConfirmProjectNameForDelete}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProjectDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            disabled={confirmProjectNameForDelete !== projectData.name}
            onClick={handleAttemptDeleteProject}
          >
            Delete Project
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </Paper>
  );
}
