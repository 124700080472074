import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser, setUser } from '../../api/services/user/userSlice';

// function delay(time) {
//   return new Promise(resolve => setTimeout(resolve, time));
// }

export function useFetchUser(user) {
  // If the user data is already loaded in redux state, return that. Otherwise, make a GET request to get user info, and return that.
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchUserInfo = async () => {
      try {
        const res = await dispatch(getUser());
        dispatch(setUser(res.payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
    // delay(1000).then(() => {
    //   fetchUserInfo();
    // });
  }, [dispatch]);

  return isLoading;
}