import React from 'react';
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import UserProfile from './components/UserProfile';
import OrganizationInfo from '../../features/organizationInfo/OrganizationInfo';

export default function UserAccountAndOrgPage() {
  const user = useSelector(state => state.user.user);

  // When this tab is loaded, need to query various endpoints to get all of the user data. This is done here to ensure the token refresh is only done once, 
  // then data is read from redux state by the various components
  

  return (
    <Stack sx={{ maxWidth: 1200 }}>
      <UserProfile user={user} />
      <OrganizationInfo />
    </Stack>
  )
}
